import {client} from 'domains/sanity';
import groq from 'groq';
import type {SanityImage} from 'domains/sanity';
import type {SanityCollectionLevel} from './collections';
import type {Brand} from 'domains/brands';
export const isStaffPick = (tag: string) => tag === 'staffpick';
export const specialProductTags = [
  'staffpick',
  'specialoffer',
  'newarrival',
  'sale',
  'pre-order',
  'membersoffer'
];

export interface SanityProductSettings {
  collection_structure: {
    collections: SanityCollectionLevel[];
  };
  product_settings_main: {
    _type: 'custom.productSettingsMain';
    default_size_guide: SanityImage;
    size_guide_icon: SanityImage;
    description_body: any;
    description_title: string;
    payment_methods: SanityImage;
    read_more_label: string;
    shipping_body: any;
    shipping_title: string;
    size_guide_label: string;
  };
  product_settings_translations: {
    add_selected_to_cart_label: string;
    add_to_cart_label: string;
    sold_out_label: string;
    brand_label: string;
    buy_now_label: string;
    colour_label: string;
    delivery_period_messages_label: string;
    price_label: string;
    product_type_label: string;
    quantity_label: string;
    shipping_messages_label: any;
    size_label: string;
    you_may_also_like_label: string;
    frequently_bought_together_label: string;
    total_price_label: string;
  };
}

export interface SanityProductQueryResponse {
  productSettings: SanityProductSettings;
  sanity_product: {
    bundles: string[];
  };
  sizeGuide: Pick<Brand, 'shopify_vendor_node' | 'brand_size_guide'>;
}

export async function useProductQuery({
  languageCode,
  vendor,
  handle,
}: {
  languageCode: string;
  vendor: string;
  handle: string;
}) {
  const query = `{
    "sizeGuide": *[_type == "custom.brand" && shopify_brand_node == "${vendor}"] {
      ...
    },
    "sanity_product": *[_type == "product" && store.slug.current == "${handle}"][0] {
      bundles[]
    },
    "productSettings": *[_type == 'settings'][0].productSettings {
      "product_settings_main": product_settings_main {
          ...,
          "description_title": description_title["${languageCode}"],
          "description_body": description_body["${languageCode}"],
          "shipping_title": shipping_title["${languageCode}"],
          "shipping_body": shipping_body["${languageCode}"],
          "size_guide_label": size_guide_label["${languageCode}"],
          "read_more_label": read_more_label["${languageCode}"],
      },
      "product_settings_translations": product_settings_translations{
          "add_selected_to_cart_label": add_selected_to_cart_label["${languageCode}"],
          "add_to_cart_label": add_to_cart_label["${languageCode}"],
          "sold_out_label": sold_out_label["${languageCode}"],
          "brand_label": brand_label["${languageCode}"],
          "buy_now_label": buy_now_label["${languageCode}"],
          "colour_label": colour_label["${languageCode}"],
          "delivery_period_messages_label": delivery_period_messages_label["${languageCode}"],
          "price_label": price_label["${languageCode}"],
          "product_type_label": product_type_label["${languageCode}"],
          "quantity_label": quantity_label["${languageCode}"],
          "shipping_messages_label": shipping_messages_label["${languageCode}"],
          "size_label": size_label["${languageCode}"],
          "you_may_also_like_label": you_may_also_like_label["${languageCode}"],
          "frequently_bought_together_label": frequently_bought_together_label["${languageCode}"],
          "total_price_label": total_price_label["${languageCode}"],
      },
      "collection_structure": *[_type == "collection_structure"][1]{
        "collections": collections[]{
          ...,
          "associated_collections": associated_collections[]{
            ...,
            "collections": shopify_collections[]{
              ...,
              "associated_collections": associated_collections[]{
            ...,
            "collections": shopify_collections[]{
              ...,
              "this_collection": this_collection->,
            }
          },
              "this_collection": this_collection->,
            }
          },
          "this_collection": this_collection->,
        }
      }
    }
  }`;
  return (await client.fetch(query)) as SanityProductQueryResponse;
}

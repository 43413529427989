import clsx from 'clsx';
import React from 'react';
import {
  flattenConnection,
  Image,
  ShopifyAnalyticsProduct,
} from '@shopify/hydrogen';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import {Text, Link, AddToCartButton} from '~/components';
import {isDiscounted, isNewArrival} from '~/lib/utils';
import {getProductPlaceholder} from '~/lib/placeholders';
import type {
  MoneyV2,
  Product,
  Metafield,
} from '@shopify/hydrogen/storefront-api-types';
import {useMatches} from '@remix-run/react';

//components
import ProductPrices from 'components/product/ProductPrices';
import ProductColourOptions from 'components/product/ProductColourOptions';
import ProductSizeOptions from 'components/product/ProductSizeOptions';
import ProductLabel from 'components/product/ProductLabel';
import ProductWishlistButton from 'components/product/ProductWishlistButton';

//domains
import {
  isStaffPick,
  SanityProductSettings,
  specialProductTags,
} from 'domains/product';
import {I18nLocale} from '~/lib/type';

type ProductQueryParams = {
  Colour?: string;
  Size?: string;
};

function buildQueryParams(
  params: ProductQueryParams,
  colourOptionName: string | undefined,
): string {
  let queryString = '';

  if (params.Colour && params.Colour.trim() !== '') {
    if (colourOptionName === 'Color') {
      queryString += `Color=${encodeURIComponent(params.Colour.trim())}&`;
    } else {
      queryString += `Colour=${encodeURIComponent(params.Colour.trim())}&`;
    }
  }

  if (params.Size && params.Size.trim() !== '') {
    queryString += `Size=${encodeURIComponent(params.Size.trim())}&`;
  }

  // Remove the trailing '&' if present
  if (queryString.endsWith('&')) {
    queryString = queryString.slice(0, -1);
  }

  return queryString;
}

export default function Product({
  product,
  label,
  className,
  loading,
  onClick,
  quickAdd,
  type,
}: {
  product: SerializeFrom<Product>;
  label?: string;
  className?: string;
  loading?: HTMLImageElement['loading'];
  onClick?: () => void;
  quickAdd?: boolean;
  type?: string;
}) {
  let cardLabel;

  const cardProduct: Product = product?.variants
    ? (product as Product)
    : getProductPlaceholder();
  if (!cardProduct?.variants?.nodes?.length) return null;

  const firstVariant = flattenConnection(product.variants)[0];
  const firstAvailableVariant = flattenConnection(product.variants).find(
    (v) => v.availableForSale,
  );
  const soldOut = !firstAvailableVariant;

  const [selectedVariant, setSelectedVariant] = React.useState(
    firstAvailableVariant ?? firstVariant,
  );

  if (!firstVariant) return null;
  const {image, price, compareAtPrice} = selectedVariant;

  React.useEffect(() => {
    const firstVariant = flattenConnection(product.variants)[0];
    const firstAvailableVariant = flattenConnection(product.variants).find(
      (v) => v.availableForSale,
    );
    setSelectedVariant(firstAvailableVariant ?? firstVariant);
  }, [product]);

  //all colour options
  const allVariants = flattenConnection(cardProduct.variants);
  const allColourOptions = cardProduct.options.find(
    (o) => o.name === 'Colour' || o.name === 'Color',
  )?.values;
  const allColourOptionsWithThumbnail = allColourOptions?.map(
    (colourOption) => {
      return {
        colour: colourOption,
        thumbnail: allVariants.find(
          (v) => v.title.includes(colourOption) && v.image && v.image.url,
        )?.image,
      };
    },
  );

  if (label) {
    cardLabel = label;
  } else if (isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2)) {
    cardLabel = 'Sale';
  } else if (isNewArrival(product.publishedAt)) {
    cardLabel = 'New';
  }

  const topLabel =
    isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2) &&
    compareAtPrice &&
    `-${Math.ceil(
      100 -
        (parseFloat(price.amount) / parseFloat(compareAtPrice.amount)) * 100,
    )}%`;
  const tags = [
    ...product?.tags?.filter((t) => specialProductTags.includes(t)),
    selectedVariant.availableForSale ? 'instock' : 'outofstock',
  ];

  const productAnalytics: ShopifyAnalyticsProduct = {
    productGid: product.id,
    variantGid: firstVariant.id,
    name: product.title,
    variantName: firstVariant.title,
    brand: product.vendor,
    price: firstVariant.price.amount,
    quantity: 1,
  };

  const hasSizeOptions = cardProduct.options?.some((o) => o.name === 'Size');
  const selectedVariantColour = selectedVariant.selectedOptions.find(
    (o) => o?.name === 'Colour' || o?.name === 'Color',
  )?.value;
  const selectedVariantSize = selectedVariant.selectedOptions.find(
    (o) => o?.name === 'Size',
  )?.value;

  const productQueryParams: ProductQueryParams = {
    Colour: selectedVariantColour,
    Size: selectedVariantSize,
  };

  const imageOfVariantOfTheSameColour = allVariants.find(
    (v) =>
      v.selectedOptions.find((o) => o?.name === 'Colour' || o?.name === 'Color')
        ?.value === selectedVariantColour,
  )?.image;
  const colourOptionName = allVariants
    .find(
      (v) =>
        v.selectedOptions.find(
          (o) => o?.name === 'Colour' || o?.name === 'Color',
        )?.name,
    )
    ?.selectedOptions.find(
      (o) => o?.name === 'Colour' || o?.name === 'Color',
    )?.name;

  const queryString = buildQueryParams(productQueryParams, colourOptionName);

  const variantImage =
    image && image.url ? image : imageOfVariantOfTheSameColour;

  const [root] = useMatches();
  const isLoggedIn = root?.data?.isLoggedIn as boolean;
  const wishlist = root?.data?.wishlist as Metafield;
  const productSettings = root?.data?.productSettings as SanityProductSettings;
  const selectedLocale = root?.data?.selectedLocale as I18nLocale;

  const is_proorder = product?.tags?.includes('pre-order');

  return (
    <li className="h-full flex flex-col gap-4 border border-solid border-slate-400 border-opacity-50 p-2 relative">
      <div className="absolute top-0 right-0 min-w-[50%]">
        {topLabel && <ProductLabel label={topLabel} tag="discount" />}
        {tags &&
          tags.map(
            (tag) =>
              tag && (
                <ProductLabel key={`product-tag-${tag}`} tag={tag as string} />
              ),
          )}
      </div>
      <div className="product-header flex flex-col justify-between flex-1">
        <Link
          onClick={onClick}
          to={`/products/${product.handle}${queryString && `?${queryString}`}`}
          prefetch="intent"
        >
          <div className={clsx('grid gap-4 relative', className)}>
            <div className="relative group">
              <div className="card-image aspect-w-16 aspect-h-16 bg-primary/5 relative group-hover:opacity-50">
                {variantImage && variantImage.url && (
                  <Image
                    className="w-full !object-contain fadeIn"
                    width={400}
                    height={400}
                    sizes="400px"
                    data={variantImage}
                    alt={variantImage.altText || `Picture of ${product.title}`}
                    loading={loading}
                  />
                )}
              </div>
              {hasSizeOptions && (
                <div className="sizes absolute bottom-0 left-0 w-full px-2 pb-2">
                  <ProductSizeOptions
                    options={cardProduct.options.find((o) => o.name === 'Size')}
                    selectedVariant={selectedVariant}
                    allVariants={allVariants}
                    productGid={product.id}
                    setSelectedVariant={setSelectedVariant}
                  />
                </div>
              )}
            </div>

            <div className="grid gap-1">
              <h3 className="text-md md:text-lg !leading-snug font-normal line-clamp-3 relative mt-4 mb-2 min-h-[69.25px] md:min-h-[74.25px] text-center">
                {product.title}
              </h3>
            </div>
          </div>
        </Link>
        {allColourOptionsWithThumbnail && (
          <ProductColourOptions
            options={allColourOptionsWithThumbnail}
            selectedVariant={selectedVariant}
            allVariants={allVariants}
            productGid={product.id}
            setSelectedVariant={setSelectedVariant}
          />
        )}
      </div>
      {type !== 'cart' && (
        <div className="product-footer">
          <div className="flex justify-center mb-2">
            <ProductPrices
              compareAtPrice={compareAtPrice as MoneyV2}
              price={price}
              type="shopify"
            />
          </div>
          {quickAdd && !soldOut && (
            <div
              className={clsx(
                'flex items-center',
                isLoggedIn && 'border border-hk-orange border-solid rounded-md',
              )}
            >
              <div className="flex-1">
                {is_proorder ? (
                  <Link
                    to={`/products/${product.handle}${
                      queryString && `?${queryString}`
                    }`}
                    prefetch="intent"
                    className={clsx(
                      'rounded-md py-[15px] transition-opacity duration-200 ease-out hover:opacity-75 bg-black text-white w-full block text-center',
                    )}
                  >
                    {selectedLocale.language === 'ZH_TW'
                      ? '預訂產品'
                      : 'Pre-Order'}
                  </Link>
                ) : (
                  <AddToCartButton
                    lines={[
                      {
                        quantity: 1,
                        merchandiseId: selectedVariant.id,
                      },
                    ]}
                    variant="secondary"
                    className={clsx(
                      'rounded-md py-[15px] transition-opacity duration-200 ease-out hover:opacity-75 bg-hk-orange',
                    )}
                    analytics={{
                      products: [productAnalytics],
                      totalValue: parseFloat(productAnalytics.price),
                    }}
                  >
                    <span className="text-sm md:text-md flex items-center justify-center">
                      {productSettings?.product_settings_translations
                        ?.add_to_cart_label ?? 'Add to cart'}
                    </span>
                  </AddToCartButton>
                )}
              </div>
              {isLoggedIn && (
                <ProductWishlistButton
                  productId={product.id}
                  wishlist={wishlist}
                />
              )}
            </div>
          )}
          {soldOut && (
            <SoldOutButton>
              {productSettings?.product_settings_translations?.sold_out_label ??
                'Out of Stock'}
            </SoldOutButton>
          )}
        </div>
      )}
    </li>
  );
}

function SoldOutButton({children}: {children: React.ReactNode}) {
  return (
    <div className="mt-2 rounded-md rounded-tl-md rounded-bl-md py-[15px] transition-opacity duration-200 ease-out hover:opacity-75 bg-hk-deep-gray">
      <span className="flex items-center justify-center">{children}</span>
    </div>
  );
}

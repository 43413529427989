export const currencyDetails = [
  {
    country: 'Afghanistan',
    currency_code: 'AFN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
    currency: {
      symbol: 'Af',
      name: 'Afghan Afghani',
      symbol_native: '؋',
      decimal_digits: 0,
      rounding: 0,
      code: 'AFN',
      name_plural: 'Afghan Afghanis',
    },
  },
  {
    country: 'Albania',
    currency_code: 'ALL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
    currency: {
      symbol: 'ALL',
      name: 'Albanian Lek',
      symbol_native: 'Lek',
      decimal_digits: 0,
      rounding: 0,
      code: 'ALL',
      name_plural: 'Albanian lekë',
    },
  },
  {
    country: 'Algeria',
    currency_code: 'DZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg',
    currency: {
      symbol: 'DA',
      name: 'Algerian Dinar',
      symbol_native: 'د.ج.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'DZD',
      name_plural: 'Algerian dinars',
    },
  },
  {
    country: 'American Samoa',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Andorra',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Angola',
  //   currency_code: 'AOA',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
  // },
  // {
  //   country: 'Anguilla',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg',
  // },
  // {
  //   country: 'Antarctica',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg',
  // },
  // {
  //   country: 'Antigua and Barbuda',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg',
  // },
  {
    country: 'Argentina',
    currency_code: 'ARS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
    currency: {
      symbol: 'AR$',
      name: 'Argentine Peso',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'ARS',
      name_plural: 'Argentine pesos',
    },
  },
  {
    country: 'Armenia',
    currency_code: 'AMD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
    currency: {
      symbol: 'AMD',
      name: 'Armenian Dram',
      symbol_native: 'դր.',
      decimal_digits: 0,
      rounding: 0,
      code: 'AMD',
      name_plural: 'Armenian drams',
    },
  },
  // {
  //   country: 'Aruba',
  //   currency_code: 'AWG',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
  // },
  {
    country: 'Australia',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Austria',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Azerbaijan',
    currency_code: 'AZN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
    currency: {
      symbol: 'man.',
      name: 'Azerbaijani Manat',
      symbol_native: 'ман.',
      decimal_digits: 2,
      rounding: 0,
      code: 'AZN',
      name_plural: 'Azerbaijani manats',
    },
  },
  // {
  //   country: 'Bahamas',
  //   currency_code: 'BSD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg',
  // },
  {
    country: 'Bahrain',
    currency_code: 'BHD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
    currency: {
      symbol: 'BD',
      name: 'Bahraini Dinar',
      symbol_native: 'د.ب.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'BHD',
      name_plural: 'Bahraini dinars',
    },
  },
  {
    country: 'Bangladesh',
    currency_code: 'BDT',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
    currency: {
      symbol: 'Tk',
      name: 'Bangladeshi Taka',
      symbol_native: '৳',
      decimal_digits: 2,
      rounding: 0,
      code: 'BDT',
      name_plural: 'Bangladeshi takas',
    },
  },
  // {
  //   country: 'Barbados',
  //   currency_code: 'BBD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg',
  // },
  // {
  //   country: 'Belarus',
  //   currency_code: 'BYR',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
  // },
  {
    country: 'Belgium',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Belize',
    currency_code: 'BZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
    currency: {
      symbol: 'BZ$',
      name: 'Belize Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'BZD',
      name_plural: 'Belize dollars',
    },
  },
  {
    country: 'Benin',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  // {
  //   country: 'Bermuda',
  //   currency_code: 'BMD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg',
  // },
  // {
  //   country: 'Bhutan',
  //   currency_code: 'BTN',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
  // },
  {
    country: 'Bolivia',
    currency_code: 'BOB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
    currency: {
      symbol: 'Bs',
      name: 'Bolivian Boliviano',
      symbol_native: 'Bs',
      decimal_digits: 2,
      rounding: 0,
      code: 'BOB',
      name_plural: 'Bolivian bolivianos',
    },
  },
  {
    country: 'Bosnia and Herzegovina',
    currency_code: 'BAM',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg',
    currency: {
      symbol: 'KM',
      name: 'Bosnia-Herzegovina Convertible Mark',
      symbol_native: 'KM',
      decimal_digits: 2,
      rounding: 0,
      code: 'BAM',
      name_plural: 'Bosnia-Herzegovina convertible marks',
    },
  },
  {
    country: 'Botswana',
    currency_code: 'BWP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
    currency: {
      symbol: 'BWP',
      name: 'Botswanan Pula',
      symbol_native: 'P',
      decimal_digits: 2,
      rounding: 0,
      code: 'BWP',
      name_plural: 'Botswanan pulas',
    },
  },
  {
    country: 'Bouvet Island',
    currency_code: 'NOK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg',
    currency: {
      symbol: 'Nkr',
      name: 'Norwegian Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'NOK',
      name_plural: 'Norwegian kroner',
    },
  },
  {
    country: 'Brazil',
    currency_code: 'BRL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
    currency: {
      symbol: 'R$',
      name: 'Brazilian Real',
      symbol_native: 'R$',
      decimal_digits: 2,
      rounding: 0,
      code: 'BRL',
      name_plural: 'Brazilian reals',
    },
  },
  {
    country: 'British Indian Ocean Territory',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Brunei',
    currency_code: 'BND',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg',
    currency: {
      symbol: 'BN$',
      name: 'Brunei Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'BND',
      name_plural: 'Brunei dollars',
    },
  },
  {
    country: 'Bulgaria',
    currency_code: 'BGN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
    currency: {
      symbol: 'BGN',
      name: 'Bulgarian Lev',
      symbol_native: 'лв.',
      decimal_digits: 2,
      rounding: 0,
      code: 'BGN',
      name_plural: 'Bulgarian leva',
    },
  },
  {
    country: 'Burkina Faso',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Burundi',
    currency_code: 'BIF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
    currency: {
      symbol: 'FBu',
      name: 'Burundian Franc',
      symbol_native: 'FBu',
      decimal_digits: 0,
      rounding: 0,
      code: 'BIF',
      name_plural: 'Burundian francs',
    },
  },
  {
    country: 'Cambodia',
    currency_code: 'KHR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
    currency: {
      symbol: 'KHR',
      name: 'Cambodian Riel',
      symbol_native: '៛',
      decimal_digits: 2,
      rounding: 0,
      code: 'KHR',
      name_plural: 'Cambodian riels',
    },
  },
  {
    country: 'Cameroon',
    currency_code: 'XAF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  {
    country: 'Canada',
    currency_code: 'CAD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
    currency: {
      symbol: 'CA$',
      name: 'Canadian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'CAD',
      name_plural: 'Canadian dollars',
    },
  },
  {
    country: 'Cape Verde',
    currency_code: 'CVE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
    currency: {
      symbol: 'CV$',
      name: 'Cape Verdean Escudo',
      symbol_native: 'CV$',
      decimal_digits: 2,
      rounding: 0,
      code: 'CVE',
      name_plural: 'Cape Verdean escudos',
    },
  },
  // {
  //   country: 'Cayman Islands',
  //   currency_code: 'KYD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg',
  // },
  {
    country: 'Central African Republic',
    currency_code: 'XAF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  {
    country: 'Chad',
    currency_code: 'XAF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  {
    country: 'Chile',
    currency_code: 'CLP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
    currency: {
      symbol: 'CL$',
      name: 'Chilean Peso',
      symbol_native: '$',
      decimal_digits: 0,
      rounding: 0,
      code: 'CLP',
      name_plural: 'Chilean pesos',
    },
  },
  {
    country: 'China',
    currency_code: 'CNY',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
    currency: {
      symbol: 'CN¥',
      name: 'Chinese Yuan',
      symbol_native: 'CN¥',
      decimal_digits: 2,
      rounding: 0,
      code: 'CNY',
      name_plural: 'Chinese yuan',
    },
  },
  {
    country: 'Christmas Island',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Cocos (Keeling) Islands',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Colombia',
    currency_code: 'COP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
    currency: {
      symbol: 'CO$',
      name: 'Colombian Peso',
      symbol_native: '$',
      decimal_digits: 0,
      rounding: 0,
      code: 'COP',
      name_plural: 'Colombian pesos',
    },
  },
  {
    country: 'Comoros',
    currency_code: 'KMF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
    currency: {
      symbol: 'CF',
      name: 'Comorian Franc',
      symbol_native: 'FC',
      decimal_digits: 0,
      rounding: 0,
      code: 'KMF',
      name_plural: 'Comorian francs',
    },
  },
  {
    country: 'Congo',
    currency_code: 'XAF',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  {
    country: 'Cook Islands',
    currency_code: 'NZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg',
    currency: {
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NZD',
      name_plural: 'New Zealand dollars',
    },
  },
  {
    country: 'Costa Rica',
    currency_code: 'CRC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
    currency: {
      symbol: '₡',
      name: 'Costa Rican Colón',
      symbol_native: '₡',
      decimal_digits: 0,
      rounding: 0,
      code: 'CRC',
      name_plural: 'Costa Rican colóns',
    },
  },
  {
    country: 'Croatia',
    currency_code: 'HRK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
    currency: {
      symbol: 'kn',
      name: 'Croatian Kuna',
      symbol_native: 'kn',
      decimal_digits: 2,
      rounding: 0,
      code: 'HRK',
      name_plural: 'Croatian kunas',
    },
  },
  // {
  //   country: 'Cuba',
  //   currency_code: 'CUP',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
  // },
  {
    country: 'Cyprus',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Czech Republic',
    currency_code: 'CZK',
    currency: {
      symbol: 'Kč',
      name: 'Czech Republic Koruna',
      symbol_native: 'Kč',
      decimal_digits: 2,
      rounding: 0,
      code: 'CZK',
      name_plural: 'Czech Republic korunas',
    },
  },
  {
    country: 'Denmark',
    currency_code: 'DKK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
    currency: {
      symbol: 'Dkr',
      name: 'Danish Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'DKK',
      name_plural: 'Danish kroner',
    },
  },
  {
    country: 'Djibouti',
    currency_code: 'DJF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
    currency: {
      symbol: 'Fdj',
      name: 'Djiboutian Franc',
      symbol_native: 'Fdj',
      decimal_digits: 0,
      rounding: 0,
      code: 'DJF',
      name_plural: 'Djiboutian francs',
    },
  },
  // {
  //   country: 'Dominica',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg',
  // },
  {
    country: 'Dominican Republic',
    currency_code: 'DOP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg',
    currency: {
      symbol: 'RD$',
      name: 'Dominican Peso',
      symbol_native: 'RD$',
      decimal_digits: 2,
      rounding: 0,
      code: 'DOP',
      name_plural: 'Dominican pesos',
    },
  },
  {
    country: 'East Timor',
    currency_code: 'USD',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  // {
  //   country: 'Ecuador',
  //   currency_code: 'ECS',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
  // },
  {
    country: 'Egypt',
    currency_code: 'EGP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
    currency: {
      symbol: 'EGP',
      name: 'Egyptian Pound',
      symbol_native: 'ج.م.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'EGP',
      name_plural: 'Egyptian pounds',
    },
  },
  // {
  //   country: 'El Salvador',
  //   currency_code: 'SVC',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
  // },
  {
    country: 'England',
    currency_code: 'GBP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  {
    country: 'Equatorial Guinea',
    currency_code: 'XAF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  {
    country: 'Eritrea',
    currency_code: 'ERN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
    currency: {
      symbol: 'Nfk',
      name: 'Eritrean Nakfa',
      symbol_native: 'Nfk',
      decimal_digits: 2,
      rounding: 0,
      code: 'ERN',
      name_plural: 'Eritrean nakfas',
    },
  },
  {
    country: 'Estonia',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Ethiopia',
    currency_code: 'ETB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
    currency: {
      symbol: 'Br',
      name: 'Ethiopian Birr',
      symbol_native: 'Br',
      decimal_digits: 2,
      rounding: 0,
      code: 'ETB',
      name_plural: 'Ethiopian birrs',
    },
  },
  // {
  //   country: 'Falkland Islands',
  //   currency_code: 'FKP',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
  // },
  {
    country: 'Faroe Islands',
    currency_code: 'DKK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
    currency: {
      symbol: 'Dkr',
      name: 'Danish Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'DKK',
      name_plural: 'Danish kroner',
    },
  },
  // {
  //   country: 'Fiji Islands',
  //   currency_code: 'FJD',
  // },
  {
    country: 'Finland',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'France',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'French Guiana',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'French Polynesia',
  //   currency_code: 'XPF',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
  // },
  {
    country: 'French Southern territories',
    currency_code: 'EUR',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Gabon',
    currency_code: 'XAF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
    currency: {
      symbol: 'FCFA',
      name: 'CFA Franc BEAC',
      symbol_native: 'FCFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XAF',
      name_plural: 'CFA francs BEAC',
    },
  },
  // {
  //   country: 'Gambia',
  //   currency_code: 'GMD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
  // },
  {
    country: 'Georgia',
    currency_code: 'GEL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
    currency: {
      symbol: 'GEL',
      name: 'Georgian Lari',
      symbol_native: 'GEL',
      decimal_digits: 2,
      rounding: 0,
      code: 'GEL',
      name_plural: 'Georgian laris',
    },
  },
  {
    country: 'Germany',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Ghana',
    currency_code: 'GHS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
    currency: {
      symbol: 'GH₵',
      name: 'Ghanaian Cedi',
      symbol_native: 'GH₵',
      decimal_digits: 2,
      rounding: 0,
      code: 'GHS',
      name_plural: 'Ghanaian cedis',
    },
  },
  // {
  //   country: 'Gibraltar',
  //   currency_code: 'GIP',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
  // },
  {
    country: 'Greece',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Greenland',
    currency_code: 'DKK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
    currency: {
      symbol: 'Dkr',
      name: 'Danish Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'DKK',
      name_plural: 'Danish kroner',
    },
  },
  // {
  //   country: 'Grenada',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg',
  // },
  {
    country: 'Guadeloupe',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Guam',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  // {
  //   country: 'Guatemala',
  //   currency_code: 'QTQ',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
  // },
  {
    country: 'Guinea',
    currency_code: 'GNF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
    currency: {
      symbol: 'FG',
      name: 'Guinean Franc',
      symbol_native: 'FG',
      decimal_digits: 0,
      rounding: 0,
      code: 'GNF',
      name_plural: 'Guinean francs',
    },
  },
  // {
  //   country: 'Guinea-Bissau',
  //   currency_code: 'CFA',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg',
  // },
  // {
  //   country: 'Guyana',
  //   currency_code: 'GYD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
  // },
  // {
  //   country: 'Haiti',
  //   currency_code: 'HTG',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
  // },
  {
    country: 'Heard Island and McDonald Islands',
    currency_code: 'AUD',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Vatican City',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Honduras',
    currency_code: 'HNL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
    currency: {
      symbol: 'HNL',
      name: 'Honduran Lempira',
      symbol_native: 'L',
      decimal_digits: 2,
      rounding: 0,
      code: 'HNL',
      name_plural: 'Honduran lempiras',
    },
  },
  {
    country: 'Hong Kong',
    currency_code: 'HKD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg',
    currency: {
      symbol: 'HK$',
      name: 'Hong Kong Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'HKD',
      name_plural: 'Hong Kong dollars',
    },
  },
  {
    country: 'Hungary',
    currency_code: 'HUF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
    currency: {
      symbol: 'Ft',
      name: 'Hungarian Forint',
      symbol_native: 'Ft',
      decimal_digits: 0,
      rounding: 0,
      code: 'HUF',
      name_plural: 'Hungarian forints',
    },
  },
  {
    country: 'Iceland',
    currency_code: 'ISK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
    currency: {
      symbol: 'Ikr',
      name: 'Icelandic Króna',
      symbol_native: 'kr',
      decimal_digits: 0,
      rounding: 0,
      code: 'ISK',
      name_plural: 'Icelandic krónur',
    },
  },
  {
    country: 'India',
    currency_code: 'INR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
    currency: {
      symbol: 'Rs',
      name: 'Indian Rupee',
      symbol_native: 'টকা',
      decimal_digits: 2,
      rounding: 0,
      code: 'INR',
      name_plural: 'Indian rupees',
    },
  },
  {
    country: 'Indonesia',
    currency_code: 'IDR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
    currency: {
      symbol: 'Rp',
      name: 'Indonesian Rupiah',
      symbol_native: 'Rp',
      decimal_digits: 0,
      rounding: 0,
      code: 'IDR',
      name_plural: 'Indonesian rupiahs',
    },
  },
  {
    country: 'Iran',
    currency_code: 'IRR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
    currency: {
      symbol: 'IRR',
      name: 'Iranian Rial',
      symbol_native: '﷼',
      decimal_digits: 0,
      rounding: 0,
      code: 'IRR',
      name_plural: 'Iranian rials',
    },
  },
  {
    country: 'Iraq',
    currency_code: 'IQD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
    currency: {
      symbol: 'IQD',
      name: 'Iraqi Dinar',
      symbol_native: 'د.ع.‏',
      decimal_digits: 0,
      rounding: 0,
      code: 'IQD',
      name_plural: 'Iraqi dinars',
    },
  },
  {
    country: 'Ireland',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Israel',
    currency_code: 'ILS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg',
    currency: {
      symbol: '₪',
      name: 'Israeli New Sheqel',
      symbol_native: '₪',
      decimal_digits: 2,
      rounding: 0,
      code: 'ILS',
      name_plural: 'Israeli new sheqels',
    },
  },
  {
    country: 'Italy',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Ivory Coast',
    currency_code: 'XOF',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Jamaica',
    currency_code: 'JMD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg',
    currency: {
      symbol: 'J$',
      name: 'Jamaican Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'JMD',
      name_plural: 'Jamaican dollars',
    },
  },
  {
    country: 'Japan',
    currency_code: 'JPY',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
    currency: {
      symbol: '¥',
      name: 'Japanese Yen',
      symbol_native: '￥',
      decimal_digits: 0,
      rounding: 0,
      code: 'JPY',
      name_plural: 'Japanese yen',
    },
  },
  {
    country: 'Jordan',
    currency_code: 'JOD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
    currency: {
      symbol: 'JD',
      name: 'Jordanian Dinar',
      symbol_native: 'د.أ.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'JOD',
      name_plural: 'Jordanian dinars',
    },
  },
  {
    country: 'Kazakhstan',
    currency_code: 'KZT',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
    currency: {
      symbol: 'KZT',
      name: 'Kazakhstani Tenge',
      symbol_native: 'тңг.',
      decimal_digits: 2,
      rounding: 0,
      code: 'KZT',
      name_plural: 'Kazakhstani tenges',
    },
  },
  {
    country: 'Kenya',
    currency_code: 'KES',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
    currency: {
      symbol: 'Ksh',
      name: 'Kenyan Shilling',
      symbol_native: 'Ksh',
      decimal_digits: 2,
      rounding: 0,
      code: 'KES',
      name_plural: 'Kenyan shillings',
    },
  },
  {
    country: 'Kiribati',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Kuwait',
    currency_code: 'KWD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
    currency: {
      symbol: 'KD',
      name: 'Kuwaiti Dinar',
      symbol_native: 'د.ك.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'KWD',
      name_plural: 'Kuwaiti dinars',
    },
  },
  // {
  //   country: 'Kyrgyzstan',
  //   currency_code: 'KGS',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
  // },
  // {
  //   country: 'Laos',
  //   currency_code: 'LAK',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
  // },
  {
    country: 'Latvia',
    currency_code: 'LVL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
    currency: {
      symbol: 'Ls',
      name: 'Latvian Lats',
      symbol_native: 'Ls',
      decimal_digits: 2,
      rounding: 0,
      code: 'LVL',
      name_plural: 'Latvian lati',
    },
  },
  {
    country: 'Lebanon',
    currency_code: 'LBP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
    currency: {
      symbol: 'L.L.',
      name: 'Lebanese Pound',
      symbol_native: 'ل.ل.‏',
      decimal_digits: 0,
      rounding: 0,
      code: 'LBP',
      name_plural: 'Lebanese pounds',
    },
  },
  // {
  //   country: 'Lesotho',
  //   currency_code: 'LSL',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
  // },
  // {
  //   country: 'Liberia',
  //   currency_code: 'LRD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
  // },
  {
    country: 'Libyan Arab Jamahiriya',
    currency_code: 'LYD',
    currency: {
      symbol: 'LD',
      name: 'Libyan Dinar',
      symbol_native: 'د.ل.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'LYD',
      name_plural: 'Libyan dinars',
    },
  },
  {
    country: 'Liechtenstein',
    currency_code: 'CHF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
    currency: {
      symbol: 'CHF',
      name: 'Swiss Franc',
      symbol_native: 'CHF',
      decimal_digits: 2,
      rounding: 0.05,
      code: 'CHF',
      name_plural: 'Swiss francs',
    },
  },
  {
    country: 'Lithuania',
    currency_code: 'LTL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
    currency: {
      symbol: 'Lt',
      name: 'Lithuanian Litas',
      symbol_native: 'Lt',
      decimal_digits: 2,
      rounding: 0,
      code: 'LTL',
      name_plural: 'Lithuanian litai',
    },
  },
  {
    country: 'Luxembourg',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Macau',
    currency_code: 'MOP',
    currency: {
      symbol: 'MOP$',
      name: 'Macanese Pataca',
      symbol_native: 'MOP$',
      decimal_digits: 2,
      rounding: 0,
      code: 'MOP',
      name_plural: 'Macanese patacas',
    },
  },
  {
    country: 'North Macedonia',
    currency_code: 'MKD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg',
    currency: {
      symbol: 'MKD',
      name: 'Macedonian Denar',
      symbol_native: 'MKD',
      decimal_digits: 2,
      rounding: 0,
      code: 'MKD',
      name_plural: 'Macedonian denari',
    },
  },
  // {
  //   country: 'Madagascar',
  //   currency_code: 'MGF',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
  // },
  // {
  //   country: 'Malawi',
  //   currency_code: 'MWK',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
  // },
  {
    country: 'Malaysia',
    currency_code: 'MYR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
    currency: {
      symbol: 'RM',
      name: 'Malaysian Ringgit',
      symbol_native: 'RM',
      decimal_digits: 2,
      rounding: 0,
      code: 'MYR',
      name_plural: 'Malaysian ringgits',
    },
  },
  // {
  //   country: 'Maldives',
  //   currency_code: 'MVR',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
  // },
  {
    country: 'Mali',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Malta',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Marshall Islands',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Martinique',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Mauritania',
  //   currency_code: 'MRO',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
  // },
  {
    country: 'Mauritius',
    currency_code: 'MUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
    currency: {
      symbol: 'MURs',
      name: 'Mauritian Rupee',
      symbol_native: 'MURs',
      decimal_digits: 0,
      rounding: 0,
      code: 'MUR',
      name_plural: 'Mauritian rupees',
    },
  },
  {
    country: 'Mayotte',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Mexico',
    currency_code: 'MXN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
    currency: {
      symbol: 'MX$',
      name: 'Mexican Peso',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'MXN',
      name_plural: 'Mexican pesos',
    },
  },
  {
    country: 'Micronesia, Federated States of',
    currency_code: 'USD',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Moldova',
    currency_code: 'MDL',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
    currency: {
      symbol: 'MDL',
      name: 'Moldovan Leu',
      symbol_native: 'MDL',
      decimal_digits: 2,
      rounding: 0,
      code: 'MDL',
      name_plural: 'Moldovan lei',
    },
  },
  {
    country: 'Monaco',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Mongolia',
  //   currency_code: 'MNT',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
  // },
  // {
  //   country: 'Montserrat',
  //   currency_code: 'XCD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg',
  // },
  {
    country: 'Morocco',
    currency_code: 'MAD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
    currency: {
      symbol: 'MAD',
      name: 'Moroccan Dirham',
      symbol_native: 'د.م.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'MAD',
      name_plural: 'Moroccan dirhams',
    },
  },
  {
    country: 'Mozambique',
    currency_code: 'MZN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
    currency: {
      symbol: 'MTn',
      name: 'Mozambican Metical',
      symbol_native: 'MTn',
      decimal_digits: 2,
      rounding: 0,
      code: 'MZN',
      name_plural: 'Mozambican meticals',
    },
  },
  // {
  //   country: 'Myanmar',
  //   currency_code: 'MMR',
  // },
  {
    country: 'Namibia',
    currency_code: 'NAD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
    currency: {
      symbol: 'N$',
      name: 'Namibian Dollar',
      symbol_native: 'N$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NAD',
      name_plural: 'Namibian dollars',
    },
  },
  {
    country: 'Nauru',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Nepal',
    currency_code: 'NPR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
    currency: {
      symbol: 'NPRs',
      name: 'Nepalese Rupee',
      symbol_native: 'नेरू',
      decimal_digits: 2,
      rounding: 0,
      code: 'NPR',
      name_plural: 'Nepalese rupees',
    },
  },
  {
    country: 'Netherlands',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Netherlands Antilles',
  //   currency_code: 'ANG',
  // },
  // {
  //   country: 'New Caledonia',
  //   currency_code: 'XPF',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg',
  // },
  {
    country: 'New Zealand',
    currency_code: 'NZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
    currency: {
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NZD',
      name_plural: 'New Zealand dollars',
    },
  },
  {
    country: 'Nicaragua',
    currency_code: 'NIO',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
    currency: {
      symbol: 'C$',
      name: 'Nicaraguan Córdoba',
      symbol_native: 'C$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NIO',
      name_plural: 'Nicaraguan córdobas',
    },
  },
  {
    country: 'Niger',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Nigeria',
    currency_code: 'NGN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
    currency: {
      symbol: '₦',
      name: 'Nigerian Naira',
      symbol_native: '₦',
      decimal_digits: 2,
      rounding: 0,
      code: 'NGN',
      name_plural: 'Nigerian nairas',
    },
  },
  {
    country: 'Niue',
    currency_code: 'NZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
    currency: {
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NZD',
      name_plural: 'New Zealand dollars',
    },
  },
  {
    country: 'Norfolk Island',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  // {
  //   country: 'North Korea',
  //   currency_code: 'KPW',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg',
  // },
  {
    country: 'Northern Ireland',
    currency_code: 'GBP',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  {
    country: 'Northern Mariana Islands',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Norway',
    currency_code: 'NOK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
    currency: {
      symbol: 'Nkr',
      name: 'Norwegian Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'NOK',
      name_plural: 'Norwegian kroner',
    },
  },
  {
    country: 'Oman',
    currency_code: 'OMR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
    currency: {
      symbol: 'OMR',
      name: 'Omani Rial',
      symbol_native: 'ر.ع.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'OMR',
      name_plural: 'Omani rials',
    },
  },
  {
    country: 'Pakistan',
    currency_code: 'PKR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
    currency: {
      symbol: 'PKRs',
      name: 'Pakistani Rupee',
      symbol_native: '₨',
      decimal_digits: 0,
      rounding: 0,
      code: 'PKR',
      name_plural: 'Pakistani rupees',
    },
  },
  {
    country: 'Palau',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  // {
  //   country: 'Palestine',
  //   currency_code: null,
  // },
  {
    country: 'Panama',
    currency_code: 'PAB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
    currency: {
      symbol: 'B/.',
      name: 'Panamanian Balboa',
      symbol_native: 'B/.',
      decimal_digits: 2,
      rounding: 0,
      code: 'PAB',
      name_plural: 'Panamanian balboas',
    },
  },
  // {
  //   country: 'Papua New Guinea',
  //   currency_code: 'PGK',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
  // },
  {
    country: 'Paraguay',
    currency_code: 'PYG',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
    currency: {
      symbol: '₲',
      name: 'Paraguayan Guarani',
      symbol_native: '₲',
      decimal_digits: 0,
      rounding: 0,
      code: 'PYG',
      name_plural: 'Paraguayan guaranis',
    },
  },
  {
    country: 'Peru',
    currency_code: 'PEN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
    currency: {
      symbol: 'S/.',
      name: 'Peruvian Nuevo Sol',
      symbol_native: 'S/.',
      decimal_digits: 2,
      rounding: 0,
      code: 'PEN',
      name_plural: 'Peruvian nuevos soles',
    },
  },
  {
    country: 'Philippines',
    currency_code: 'PHP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
    currency: {
      symbol: '₱',
      name: 'Philippine Peso',
      symbol_native: '₱',
      decimal_digits: 2,
      rounding: 0,
      code: 'PHP',
      name_plural: 'Philippine pesos',
    },
  },
  {
    country: 'Pitcairn Islands',
    currency_code: 'NZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg',
    currency: {
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NZD',
      name_plural: 'New Zealand dollars',
    },
  },
  {
    country: 'Poland',
    currency_code: 'PLN',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
    currency: {
      symbol: 'zł',
      name: 'Polish Zloty',
      symbol_native: 'zł',
      decimal_digits: 2,
      rounding: 0,
      code: 'PLN',
      name_plural: 'Polish zlotys',
    },
  },
  {
    country: 'Portugal',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Puerto Rico',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Qatar',
    currency_code: 'QAR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
    currency: {
      symbol: 'QR',
      name: 'Qatari Rial',
      symbol_native: 'ر.ق.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'QAR',
      name_plural: 'Qatari rials',
    },
  },
  {
    country: 'Reunion',
    currency_code: 'EUR',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Romania',
    currency_code: 'RON',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
    currency: {
      symbol: 'RON',
      name: 'Romanian Leu',
      symbol_native: 'RON',
      decimal_digits: 2,
      rounding: 0,
      code: 'RON',
      name_plural: 'Romanian lei',
    },
  },
  {
    country: 'Russian Federation',
    currency_code: 'RUB',
    currency: {
      symbol: 'RUB',
      name: 'Russian Ruble',
      symbol_native: '₽.',
      decimal_digits: 2,
      rounding: 0,
      code: 'RUB',
      name_plural: 'Russian rubles',
    },
  },
  {
    country: 'Rwanda',
    currency_code: 'RWF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
    currency: {
      symbol: 'RWF',
      name: 'Rwandan Franc',
      symbol_native: 'FR',
      decimal_digits: 0,
      rounding: 0,
      code: 'RWF',
      name_plural: 'Rwandan francs',
    },
  },
  // {
  //   country: 'Saint Helena',
  //   currency_code: 'SHP',
  // },
  // {
  //   country: 'Saint Kitts and Nevis',
  //   currency_code: 'XCD',
  // },
  // {
  //   country: 'Saint Lucia',
  //   currency_code: 'XCD',
  // },
  {
    country: 'Saint Pierre and Miquelon',
    currency_code: 'EUR',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Saint Vincent and the Grenadines',
  //   currency_code: 'XCD',
  // },
  // {
  //   country: 'Samoa',
  //   currency_code: 'WST',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
  // },
  {
    country: 'San Marino',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Sao Tome and Principe',
  //   currency_code: 'STD',
  // },
  {
    country: 'Saudi Arabia',
    currency_code: 'SAR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
    currency: {
      symbol: 'SR',
      name: 'Saudi Riyal',
      symbol_native: 'ر.س.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'SAR',
      name_plural: 'Saudi riyals',
    },
  },
  {
    country: 'Scotland',
    currency_code: 'GBP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  {
    country: 'Senegal',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Serbia',
    currency_code: 'RSD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
    currency: {
      symbol: 'din.',
      name: 'Serbian Dinar',
      symbol_native: 'дин.',
      decimal_digits: 0,
      rounding: 0,
      code: 'RSD',
      name_plural: 'Serbian dinars',
    },
  },
  // {
  //   country: 'Seychelles',
  //   currency_code: 'SCR',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
  // },
  // {
  //   country: 'Sierra Leone',
  //   currency_code: 'SLL',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
  // },
  {
    country: 'Singapore',
    currency_code: 'SGD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
    currency: {
      symbol: 'S$',
      name: 'Singapore Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'SGD',
      name_plural: 'Singapore dollars',
    },
  },
  {
    country: 'Slovakia',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Slovenia',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  // {
  //   country: 'Solomon Islands',
  //   currency_code: 'SBD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
  // },
  {
    country: 'Somalia',
    currency_code: 'SOS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
    currency: {
      symbol: 'Ssh',
      name: 'Somali Shilling',
      symbol_native: 'Ssh',
      decimal_digits: 0,
      rounding: 0,
      code: 'SOS',
      name_plural: 'Somali shillings',
    },
  },
  {
    country: 'South Africa',
    currency_code: 'ZAR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
    currency: {
      symbol: 'R',
      name: 'South African Rand',
      symbol_native: 'R',
      decimal_digits: 2,
      rounding: 0,
      code: 'ZAR',
      name_plural: 'South African rand',
    },
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    currency_code: 'GBP',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  {
    country: 'South Korea',
    currency_code: 'KRW',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
    currency: {
      symbol: '₩',
      name: 'South Korean Won',
      symbol_native: '₩',
      decimal_digits: 0,
      rounding: 0,
      code: 'KRW',
      name_plural: 'South Korean won',
    },
  },
  // {
  //   country: 'South Sudan',
  //   currency_code: 'SSP',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
  // },
  {
    country: 'Spain',
    currency_code: 'EUR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
    currency: {
      symbol: '€',
      name: 'Euro',
      symbol_native: '€',
      decimal_digits: 2,
      rounding: 0,
      code: 'EUR',
      name_plural: 'euros',
    },
  },
  {
    country: 'Sri Lanka',
    currency_code: 'LKR',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
    currency: {
      symbol: 'SLRs',
      name: 'Sri Lankan Rupee',
      symbol_native: 'SL Re',
      decimal_digits: 2,
      rounding: 0,
      code: 'LKR',
      name_plural: 'Sri Lankan rupees',
    },
  },
  {
    country: 'Sudan',
    currency_code: 'SDG',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
    currency: {
      symbol: 'SDG',
      name: 'Sudanese Pound',
      symbol_native: 'SDG',
      decimal_digits: 2,
      rounding: 0,
      code: 'SDG',
      name_plural: 'Sudanese pounds',
    },
  },
  // {
  //   country: 'Suriname',
  //   currency_code: 'SRD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
  // },
  {
    country: 'Svalbard and Jan Mayen',
    currency_code: 'NOK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg',
    currency: {
      symbol: 'Nkr',
      name: 'Norwegian Krone',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'NOK',
      name_plural: 'Norwegian kroner',
    },
  },
  // {
  //   country: 'Swaziland',
  //   currency_code: 'SZL',
  // },
  {
    country: 'Sweden',
    currency_code: 'SEK',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
    currency: {
      symbol: 'Skr',
      name: 'Swedish Krona',
      symbol_native: 'kr',
      decimal_digits: 2,
      rounding: 0,
      code: 'SEK',
      name_plural: 'Swedish kronor',
    },
  },
  {
    country: 'Switzerland',
    currency_code: 'CHF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
    currency: {
      symbol: 'CHF',
      name: 'Swiss Franc',
      symbol_native: 'CHF',
      decimal_digits: 2,
      rounding: 0.05,
      code: 'CHF',
      name_plural: 'Swiss francs',
    },
  },
  {
    country: 'Syria',
    currency_code: 'SYP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg',
    currency: {
      symbol: 'SY£',
      name: 'Syrian Pound',
      symbol_native: 'ل.س.‏',
      decimal_digits: 0,
      rounding: 0,
      code: 'SYP',
      name_plural: 'Syrian pounds',
    },
  },
  // {
  //   country: 'Tajikistan',
  //   currency_code: 'TJS',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
  // },
  {
    country: 'Tanzania',
    currency_code: 'TZS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg',
    currency: {
      symbol: 'TSh',
      name: 'Tanzanian Shilling',
      symbol_native: 'TSh',
      decimal_digits: 0,
      rounding: 0,
      code: 'TZS',
      name_plural: 'Tanzanian shillings',
    },
  },
  {
    country: 'Taiwan',
    currency_code: 'TWD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
    currency: {
      symbol: 'NT$',
      name: 'New Taiwan Dollar',
      symbol_native: 'NT$',
      decimal_digits: 2,
      rounding: 0,
      code: 'TWD',
      name_plural: 'New Taiwan dollars',
    },
  },
  {
    country: 'Thailand',
    currency_code: 'THB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
    currency: {
      symbol: '฿',
      name: 'Thai Baht',
      symbol_native: '฿',
      decimal_digits: 2,
      rounding: 0,
      code: 'THB',
      name_plural: 'Thai baht',
    },
  },
  {
    country: 'The Democratic Republic of Congo',
    currency_code: 'CDF',
    currency: {
      symbol: 'CDF',
      name: 'Congolese Franc',
      symbol_native: 'FrCD',
      decimal_digits: 2,
      rounding: 0,
      code: 'CDF',
      name_plural: 'Congolese francs',
    },
  },
  {
    country: 'Togo',
    currency_code: 'XOF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
    currency: {
      symbol: 'CFA',
      name: 'CFA Franc BCEAO',
      symbol_native: 'CFA',
      decimal_digits: 0,
      rounding: 0,
      code: 'XOF',
      name_plural: 'CFA francs BCEAO',
    },
  },
  {
    country: 'Tokelau',
    currency_code: 'NZD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
    currency: {
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'NZD',
      name_plural: 'New Zealand dollars',
    },
  },
  {
    country: 'Tonga',
    currency_code: 'TOP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
    currency: {
      symbol: 'T$',
      name: 'Tongan Paʻanga',
      symbol_native: 'T$',
      decimal_digits: 2,
      rounding: 0,
      code: 'TOP',
      name_plural: 'Tongan paʻanga',
    },
  },
  {
    country: 'Trinidad and Tobago',
    currency_code: 'TTD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg',
    currency: {
      symbol: 'TT$',
      name: 'Trinidad and Tobago Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'TTD',
      name_plural: 'Trinidad and Tobago dollars',
    },
  },
  {
    country: 'Tunisia',
    currency_code: 'TND',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
    currency: {
      symbol: 'DT',
      name: 'Tunisian Dinar',
      symbol_native: 'د.ت.‏',
      decimal_digits: 3,
      rounding: 0,
      code: 'TND',
      name_plural: 'Tunisian dinars',
    },
  },
  {
    country: 'Turkey',
    currency_code: 'TRY',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
    currency: {
      symbol: 'TL',
      name: 'Turkish Lira',
      symbol_native: 'TL',
      decimal_digits: 2,
      rounding: 0,
      code: 'TRY',
      name_plural: 'Turkish Lira',
    },
  },
  // {
  //   country: 'Turkmenistan',
  //   currency_code: 'TMT',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
  // },
  {
    country: 'Turks and Caicos Islands',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Tuvalu',
    currency_code: 'AUD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
    currency: {
      symbol: 'AU$',
      name: 'Australian Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'AUD',
      name_plural: 'Australian dollars',
    },
  },
  {
    country: 'Uganda',
    currency_code: 'UGX',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
    currency: {
      symbol: 'USh',
      name: 'Ugandan Shilling',
      symbol_native: 'USh',
      decimal_digits: 0,
      rounding: 0,
      code: 'UGX',
      name_plural: 'Ugandan shillings',
    },
  },
  {
    country: 'Ukraine',
    currency_code: 'UAH',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
    currency: {
      symbol: '₴',
      name: 'Ukrainian Hryvnia',
      symbol_native: '₴',
      decimal_digits: 2,
      rounding: 0,
      code: 'UAH',
      name_plural: 'Ukrainian hryvnias',
    },
  },
  {
    country: 'United Arab Emirates',
    currency_code: 'AED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    currency: {
      symbol: 'AED',
      name: 'United Arab Emirates Dirham',
      symbol_native: 'د.إ.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'AED',
      name_plural: 'UAE dirhams',
    },
  },
  {
    country: 'United Kingdom',
    currency_code: 'GBP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  {
    country: 'United States',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'United States Minor Outlying Islands',
    currency_code: 'USD',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Uruguay',
    currency_code: 'UYU',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
    currency: {
      symbol: '$U',
      name: 'Uruguayan Peso',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'UYU',
      name_plural: 'Uruguayan pesos',
    },
  },
  {
    country: 'Uzbekistan',
    currency_code: 'UZS',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
    currency: {
      symbol: 'UZS',
      name: 'Uzbekistan Som',
      symbol_native: 'UZS',
      decimal_digits: 0,
      rounding: 0,
      code: 'UZS',
      name_plural: 'Uzbekistan som',
    },
  },
  // {
  //   country: 'Vanuatu',
  //   currency_code: 'VUV',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
  // },
  {
    country: 'Venezuela',
    currency_code: 'VEF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
    currency: {
      symbol: 'Bs.F.',
      name: 'Venezuelan Bolívar',
      symbol_native: 'Bs.F.',
      decimal_digits: 2,
      rounding: 0,
      code: 'VEF',
      name_plural: 'Venezuelan bolívars',
    },
  },
  {
    country: 'Vietnam',
    currency_code: 'VND',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
    currency: {
      symbol: '₫',
      name: 'Vietnamese Dong',
      symbol_native: '₫',
      decimal_digits: 0,
      rounding: 0,
      code: 'VND',
      name_plural: 'Vietnamese dong',
    },
  },
  {
    country: 'Virgin Islands, British',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Virgin Islands, U.S.',
    currency_code: 'USD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg',
    currency: {
      symbol: '$',
      name: 'US Dollar',
      symbol_native: '$',
      decimal_digits: 2,
      rounding: 0,
      code: 'USD',
      name_plural: 'US dollars',
    },
  },
  {
    country: 'Wales',
    currency_code: 'GBP',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg',
    currency: {
      symbol: '£',
      name: 'British Pound Sterling',
      symbol_native: '£',
      decimal_digits: 2,
      rounding: 0,
      code: 'GBP',
      name_plural: 'British pounds sterling',
    },
  },
  // {
  //   country: 'Wallis and Futuna',
  //   currency_code: 'XPF',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg',
  // },
  {
    country: 'Western Sahara',
    currency_code: 'MAD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg',
    currency: {
      symbol: 'MAD',
      name: 'Moroccan Dirham',
      symbol_native: 'د.م.‏',
      decimal_digits: 2,
      rounding: 0,
      code: 'MAD',
      name_plural: 'Moroccan dirhams',
    },
  },
  {
    country: 'Yemen',
    currency_code: 'YER',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg',
    currency: {
      symbol: 'YR',
      name: 'Yemeni Rial',
      symbol_native: 'ر.ي.‏',
      decimal_digits: 0,
      rounding: 0,
      code: 'YER',
      name_plural: 'Yemeni rials',
    },
  },
  // {
  //   country: 'Zambia',
  //   currency_code: 'ZMW',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg',
  // },
  // {
  //   country: 'Zimbabwe',
  //   currency_code: 'ZWD',
  //   image:
  //     'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg',
  // },
];
import React from 'react';
import clsx from 'clsx';
import * as R from 'ramda';
import {Link, useLocation, useNavigate} from '@remix-run/react';
import {useMatches} from '@remix-run/react';
import {Image} from '@shopify/hydrogen';

//domains
import {
  type SearchaniseProduct,
  type SearchaniseSearchResult,
} from '~/domains/search';
import {CurrencySettingsGlobal, formatCurrency} from 'domains/currency';
import {BFSProductType} from 'domains/collections';

export default function HeaderSearch() {
  const [results, setResults] = React.useState<null | BoostAISearchResult>(
    null,
  );

  const [query, setQuery] = React.useState<string>('');
  const [root] = useMatches();
  const currency = root.data.currency as CurrencySettingsGlobal;
  const selectedCurrency = currency.baseCurrency;
  const translation = root?.data?.translation;

  const selectedCurrencyDetails = currency.all_currencies.find(
    (c) => c.currency === selectedCurrency,
  );

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const {pathname} = useLocation();

  async function search(searchQuery: string) {
    // &restrictBy[quantity]=1|
    const searchURL = `https://services.mybcapps.com/bc-sf-filter/search/suggest?shop=helmetking-0001.myshopify.com&build_filter_tree=true&product_limit=5&collection_limit=5&event_type=suggest&product_available=true&q=${encodeURIComponent(
      searchQuery,
    )}`;
    const res = await fetch(searchURL);
    const data: BoostAISearchResult = await res.json();
    setResults(data);
  }

  return (
    <div
      className={clsx(
        'relative w-full overflow-y-scroll md:h-auto md:overflow-visible',
        isOpen && query ? 'h-screen' : 'h-auto',
      )}
      onFocus={() => setIsOpen(true)}
    >
      <button
        className={clsx([
          'absolute top-0 left-0 h-full w-screen block bg-black bg-opacity-20 md:fixed md:left-1/2 md:h-screen md:-translate-x-1/2 md:bg-transparent',
          isOpen ? 'md:visible' : 'md:invisible',
          isOpen && query ? 'visible' : 'invisible',
        ])}
        onClick={() => setIsOpen(false)}
      ></button>
      <div
        className={clsx(
          'fixed z-[100] flex w-full items-center space-x-2 bg-white px-5 py-2 md:relative md:py-0 md:px-0',
          !isOpen && 'lg:shadow-none border-b lg:border-none',
          !pathname.includes('collections') && 'shadow-md',
        )}
      >
        <div className="relative z-10 flex w-full items-center border-[1px] border-solid border-black md:w-full">
          <SearchInput
            setQuery={setQuery}
            query={query}
            search={search}
            setIsOpen={setIsOpen}
          />
          <Link
            to={`/search?q=${encodeURIComponent(query)}`}
            className="absolute right-0 top-0 hidden h-full w-[60px] items-center justify-center bg-hk-orange md:flex"
          >
            <SearchIcon />
          </Link>
        </div>
        {isOpen && query && (
          <button className="md:hidden" onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </button>
        )}
      </div>
      <div
        className={clsx([
          'absolute top-0 max-h-[75svh] translate-y-[50px] z-10 h-auto w-full bg-white pt-4 text-black md:top-full md:left-1/2 md:max-h-[400px] md:w-[200%] md:-translate-x-1/2 md:translate-y-4 overflow-y-scroll md:border md:shadow-md lg:w-auto lg:min-w-[500px]',
          isOpen && query ? 'visible opacity-100' : 'invisible opacity-0',
        ])}
      >
        {results?.collections && !R.isEmpty(results.collections) ? (
          <div className="mb-4">
            <header className="mb-2 ml-2 border-b border-solid pb-1">
              <h4 className="uppercaase text-sm text-left">
                {translation?.search?.categories_label ?? 'Categories'}
              </h4>
            </header>
            <ul className="">
              {results.collections.map((collection) => {
                return (
                  <li
                    key={collection.id}
                    className="px-4 py-2 hover:bg-slate-300 hover:bg-opacity-20 text-left"
                  >
                    <Link to={`/collections/${collection.handle}`}>
                      <button onClick={() => setIsOpen(false)}>
                        {collection.title}
                      </button>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {results?.products && !R.isEmpty(results.products) ? (
          <div className="">
            <header className="mb-2 ml-2 border-b border-solid pb-1">
              <h4 className="uppercaase text-sm text-left">
                {translation?.search?.product_label ?? 'Products'}
              </h4>
            </header>
            <ul className="">
              {results.products.map((product) => {
                return (
                  <li
                    key={product.id}
                    className="px-4 py-2 hover:bg-slate-300 hover:bg-opacity-20"
                  >
                    <Link to={`/products/${product.handle}`}>
                      <button onClick={() => setIsOpen(false)}>
                        <div className="flex space-x-4">
                          <div className="">
                            {product.images && (
                              <Image
                                data={{
                                  url: product.images[1],
                                  altText: product.title,
                                }}
                                width={100}
                                height={100}
                              />
                            )}
                          </div>
                          <div className="flex-1 text-left">
                            <h5 className="line-clamp-1text-sm font-normal">
                              {product.title}
                            </h5>
                            {!product.available && (
                              <span className="text-xs">{`[Out Of Stock]`}</span>
                            )}
                            <p className="line-clamp-3 mt-2 text-xs font-light">
                              {product.body_html}
                            </p>
                            <div className="mt-2 text-sm">
                              {
                                selectedCurrencyDetails?.details?.currency
                                  ?.symbol
                              }
                              {formatCurrency({
                                price: {
                                  amount: product.price_min,
                                  currencyCode: 'HKD',
                                },
                                baseCurrency: currency.baseCurrency,
                                exchangeRates: currency.exchangeRates,
                                selectedCurrency: currency.selectedCurrency,
                              })}
                            </div>
                          </div>
                        </div>
                      </button>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="p-2 text-sm font-normal">
            {translation?.searach?.no_product_message ?? 'No product found'}
          </div>
        )}
        {results?.total_product !== 0 && (
          <div className="">
            <Link
              to={`/search?q=${encodeURIComponent(query)}`}
              className="py-2 block w-full text-center bg-hk-orange"
            >
              <button onClick={() => setIsOpen(false)}>
                View all {results?.total_product} products
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

function SearchInput({
  setQuery,
  search,
  query,
  setIsOpen,
}: {
  setQuery: (query: string) => void;
  search: (query: string) => void;
  query: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [root] = useMatches();
  const translation = root?.data?.translation;
  const navigate = useNavigate();
  return (
    <input
      type="text"
      className="text-[16px] md:text-sm w-full flex-1 px-2 py-1 md:py-2 md:px-4 outline-none placeholder:font-normal text-black placeholder:text-black md:w-auto border-none focus:outline-none"
      placeholder={translation?.search?.search_label ?? 'Search'}
      onChange={async (e) => {
        setQuery(e.target.value);
        if (e.target.value) {
          await search(e.target.value);
        }
      }}
      onKeyDown={(event) => {
        if (query && event.key === 'Enter') {
          navigate(`/search?q=${encodeURIComponent(query)}`);
          setIsOpen(false);
        }
      }}
      value={query}
    />
  );
}

function CrossIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
        fill="black"
      />
    </svg>
  );
}

function SearchIcon() {
  return (
    <svg
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z" />
    </svg>
  );
}

type BoostProduct = {
  id: number;
  handle: string;
  title: string;
  price: string;
  compare_at_price: string | null;
  available: boolean;
  inventory_quantity: number;
  inventory_management: string;
  inventory_policy: string;
  inventory_quantities: Record<string, number>;
  locations: string[];
  available_locations: number[];
  merged_options: string[];
  original_merged_options: string[];
  sku: string;
  barcode: string;
  weight: number;
  weight_unit: string;
  image: string;
  collections: {
    template_suffix: string;
    handle: string;
    id: number;
    sort_value: string;
    title: string;
  }[];
  images: Record<string, string>;
  images_info: {
    src: string;
    width: number;
    alt: string | null;
    id: number;
    position: number;
    height: number;
  }[];
  price_min: number;
  price_max: number;
  compare_at_price_min: number | null;
  compare_at_price_max: number | null;
  price_aud: number;
  price_bob: number;
  price_bgn: number;
  price_cad: number;
  price_cny: number;
  price_czk: number;
  price_dkk: number;
  price_eur: number;
  price_gbp: number;
  price_hkd: number;
  price_huf: number;
  price_idr: number;
  price_inr: number;
  price_jpy: number;
  price_khr: number;
  price_krw: number;
  price_mmk: number;
  price_mnt: number;
  price_mop: number;
  price_myr: number;
  price_ngn: number;
  price_nzd: number;
  price_pen: number;
  price_php: number;
  price_pln: number;
  price_ron: number;
  price_sar: number;
  price_sek: number;
  price_sgd: number;
  price_thb: number;
  price_twd: number;
  price_tzs: number;
  price_ugx: number;
  price_usd: number;
  price_uyu: number;
  price_vnd: number;
  price_xaf: number;
  price_xof: number;
  body_html: string;
};

type BoostAISearchResult = {
  total_product: number;
  all_empty: boolean;
  products: BoostProduct[];
  suggestions: any;
  collections: BoostAISearchCollection[];
  pages: any;
  did_you_mean: string[];
  query: string;
};

type BoostAISearchCollection = {
  image: {
    src: string;
    alt: string;
    width: number;
    created_at: string;
    height: number;
  };
  body_html: string;
  template_suffix: string;
  handle: string;
  id: number;
  title: string;
};

import React from 'react';
import clsx from 'clsx';
import * as R from 'ramda';

//types
import type {
  ProductVariant,
  ProductOption,
} from '@shopify/hydrogen/storefront-api-types';

interface Props {
  options: ProductOption | undefined;
  selectedVariant: ProductVariant;
  allVariants: ProductVariant[];
  productGid: string;
  setSelectedVariant: React.Dispatch<React.SetStateAction<ProductVariant>>;
}

export default function ProductSizeOptions({
  options,
  selectedVariant,
  allVariants,
  productGid,
  setSelectedVariant
}: Props) {
  const selectedVariantColour = selectedVariant.selectedOptions.find(
    (o) => o?.name === 'Colour' || o?.name === 'Color',
  )?.value;
  const selectedVariantSize = selectedVariant.selectedOptions.find(
    (o) => o?.name === 'Size',
  )?.value;
  const availableVariantsOfTheSelectedColour = allVariants
    .filter((v) => v.availableForSale)
    .filter(
      (v) =>
        v.selectedOptions?.find((o) => o?.name === 'Colour' || o?.name === 'Color')?.value ===
        selectedVariantColour,
    );
  const availableSizes = availableVariantsOfTheSelectedColour.map(
    (v) => v.selectedOptions.find((o) => o?.name === 'Size')?.value,
  );
  return (
    <ul className="group-hover:opacity-100 opacity-0 flex -mx-1 flex-wrap justify-center">
      {options &&
        options.values.map((option, i) => {
          const sizeIsAvailable = availableSizes.includes(option);
          const variantOfThisSize = availableVariantsOfTheSelectedColour.find(
            (v) =>
              v.selectedOptions.find((o) => o?.name === 'Size')?.value ===
              option,
          );
          return (
            <li
              title={option}
              key={`${productGid}-size-${option}`}
              className={clsx([
                'product-variant--size p-1',
                sizeIsAvailable ? '' : 'pointer-events-none opacity-25',
              ])}
            >
              {/* <button
                onClick={() =>
                  variantOfThisSize && setSelectedVariant(variantOfThisSize)
                }
              > */}
                <div
                  className={clsx([
                    'flex min-h-[18px] min-w-[18px] cursor-pointer justify-center items-center rounded-[6px] bg-black p-1 text-center text-sm leading-none',
                    selectedVariantSize === option && sizeIsAvailable
                      ? 'text-hk-orange'
                      : 'text-white',
                  ])}
                >
                  <span className="">{option}</span>
                </div>
              {/* </button> */}
            </li>
          );
        })}
    </ul>
  );
}

import React from 'react';
import clsx from 'clsx';
import {useRef} from 'react';
import {useScroll} from 'react-use';
import {Button, Text, FeaturedProducts} from '~/components';
import {useMatches} from '@remix-run/react';
import {I18nLocale} from '~/lib/type';

type Layouts = 'page' | 'drawer';

export default function CartEmpty({
  hidden = false,
  layout = 'drawer',
  onClose,
}: {
  hidden: boolean;
  layout?: Layouts;
  onClose?: () => void;
}) {
  const [root] = useMatches();
  const selectedLocale = root?.data?.selectedLocale as I18nLocale;
  return (
    <div className="flex flex-col px-8 pt-6">
      <p className="mb-4 text-md font-bold md:text-lg">
        {selectedLocale.language === 'EN'
          ? `There's nothing in here...yet.`
          : ' 你的購物車沒有產品 '}
      </p>
      <button
        onClick={onClose}
        type="button"
        className="disabled:opacty-20 w-full rounded-sm bg-black py-2 text-md text-white transition-all duration-200 ease-out hover:bg-hk-orange hover:text-black"
      >
        {selectedLocale.language === 'EN'
          ? `Continue Shopping`
          : ' 繼續瀏覽產品'}
      </button>
    </div>
  );
}

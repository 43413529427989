import React from 'react';
import clsx from 'clsx';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Image} from '@shopify/hydrogen';
import {useMatches} from '@remix-run/react';
import {Product as ProductType} from '@shopify/hydrogen/storefront-api-types';
import {Swiper as SwiperClass} from 'swiper';

//data
import type {SanityProductSettings} from '~/domains/product';
//components
import Container from 'components/Container';
import Product from 'components/product/Product';

export default function ProductDetailsRecommendations({
  products,
}: {
  products: ProductType[];
}) {
  const [root] = useMatches();
  const productSettings = root?.data.productSettings as SanityProductSettings;
  const carouselMainRef = React.useRef<SwiperClass>();
  return (
    <section className="w-full recommended-products my-10 px-0 md:px-8">
      <h3
        className={clsx(
          'mb-6 text-center font-bold uppercase', //
          'text-[24px]',
        )}
      >
        {productSettings?.product_settings_translations
          ?.you_may_also_like_label ? (
          <>
            {
              productSettings?.product_settings_translations
                ?.you_may_also_like_label
            }
          </>
        ) : (
          'you may also like'
        )}
      </h3>
      <div className="relative max-w-[calc(100vw-5rem)] lg:max-w-[calc(100vw-12%)] mx-auto px-5 w-full">
        <Swiper
          onSwiper={(swiper) => {
            carouselMainRef.current = swiper;
          }}
          breakpoints={{
            320: {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            768: {
              spaceBetween: 20,
              slidesPerView: 2,
            },
            1024: {
              spaceBetween: 20,
              slidesPerView: 4,
            },
          }}
          loop={true}
        >
          {products &&
            products.map((product) => {
              if (product) {
                return (
                  <SwiperSlide key={product.id}>
                    <Product product={product} quickAdd />
                  </SwiperSlide>
                );
              }
              return;
            })}
        </Swiper>
        <button
          onClick={() => carouselMainRef.current?.slidePrev()}
          className={clsx(
            'absolute left-0 top-1/2 z-10  -translate-y-1/2 -translate-x-2 lg:-translate-x-10',
            products && products?.length < 4 && 'lg:hidden',
            products && products?.length < 3 && 'hidden',
          )}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate-90"
          >
            <path
              d="M9.75 4.5L6 8.25L2.25 4.5"
              stroke="#3A3E3E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => carouselMainRef.current?.slideNext()}
          className={clsx(
            'absolute right-0 top-1/2 z-10 -translate-y-1/2 translate-x-2 lg:translate-x-10',
            products && products?.length < 4 && 'lg:hidden',
            products && products?.length < 3 && 'hidden',
          )}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="-rotate-90"
          >
            <path
              d="M9.75 4.5L6 8.25L2.25 4.5"
              stroke="#3A3E3E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}

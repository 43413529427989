import React from 'react';
import {PortableText} from '@portabletext/react';
import clsx from 'clsx';
import {Link} from '../Link';

interface Props {
  richtext: any;
  className?: string;
}

const SanityRichText: React.FC<Props> = ({richtext, className}) => {
  return (
    <PortableText
      value={richtext}
      components={{
        marks: {
          annotationLinkInternal: ({children, value}) => {
            return <Link to={value.slug}>{children}</Link>;
          },
          annotationLinkExternal: ({children, value}) => {
            return (
              <a
                href={value.url}
                target={value.newWindow ? '_blank' : '_self'}
                rel="noopener noreferrer"
                className='underline'
              >
                {children}
              </a>
            );
          },
        },
        block: {
          // image: ({children, value}) => {
          //   console.log(value);
          //   return <></>;
          // },
          h1: ({children}) => {
            return <h1 className="text-center text-xl">{children}</h1>;
          },
          h2: ({children}) => {
            return <h2 className="text-xl">{children}</h2>;
          },
          h3: ({children}) => {
            return <h3 className="text-center text-lg">{children}</h3>;
          },
          h4: ({children}) => {
            return <h4 className="text-lg">{children}</h4>;
          },
          h5: ({children}) => {
            return <h5 className="text-md">{children}</h5>;
          },
          h6: ({children}) => {
            return <h6 className="text-md">{children}</h6>;
          },
          normal: ({children}) => {
            return (
              <p className="min-h-[25px] text-md font-normal">{children}</p>
            );
          },
        },
        list: {
          // Ex. 1: customizing common list types
          bullet: ({children}) => <ul className="list-disc my-2">{children}</ul>,
          number: ({children}) => <ol className="list-decimal my-2">{children}</ol>,
        },
        listItem: {
          // Ex. 1: customizing common list types
          bullet: ({children}) => <li className="list-inside">{children}</li>,
          number: ({children}) => <li className="list-inside">{children}</li>,
        },
      }}
    />
  );
};

export default SanityRichText;

import {
  type EnhancedMenu,
  // type EnhancedMenuItem,
  // useIsHomePath,
} from '~/lib/utils';
import {
  // Drawer,
  // useDrawer,
  // Text,
  // Input,
  // IconLogin,
  // IconAccount,
  // IconBag,
  // IconSearch,
  // Heading,
  // IconMenu,
  // IconCaret,
  // Section,
  // CountrySelector,
  // Cart,
  // CartLoading,
  Link,
} from '~/components';
import {
  // useParams,
  // Form,
  // Await,
  useMatches,
} from '@remix-run/react';
// import {useWindowScroll} from 'react-use';
// import {Disclosure} from '@headlessui/react';
// import {Suspense, useEffect, useMemo} from 'react';
import {useIsHydrated} from '~/hooks/useIsHydrated';
// import {useCartFetchers} from '~/hooks/useCartFetchers';
import {loader} from '../../root';
import clsx from 'clsx';

//components
import {AccountLink, CartCount} from './Header';
import Container from '../Container';
import SanityImage from '../SanityImage';
// import HeaderSearch from './HeaderSearch';
import HeaderSearch from './HeaderSearchBoost';
import AnnouncementBar from 'components/global/Announcement';
import MenuBarMobile from 'components/global/MenuBarMobile';
import MenuBarDesktop from 'components/global/MenuBarDesktop';
import LanguageSelector from 'components/global/LanguageSelector';
import CountrySelector from 'components/global/CountrySelector';
import CurrencySelector from 'components/global/CurrencySelector';
import HeaderMenuMobile from 'components/global/HeaderMenuMobile';
import type {Logo} from '~/domains/root';

export default function HeaderMain({
  isHome,
  // menu,
  openCart,
}: // title,
{
  isHome: boolean;
  openCart: () => void;
  menu?: EnhancedMenu;
  title: string;
}) {
  // const params = useParams();
  // const {y} = useWindowScroll();
  const [root] = useMatches();
  const logo = root?.data?.logo as Logo;
  const isHydrated = useIsHydrated();

  return (
    <header
      className={clsx(
        'align-center fixed top-0 z-40 w-full',
        '',
        // 'lg:h-header-lg',
      )}
      role="banner"
    >
      <AnnouncementBar />
      <div className="relative z-10 w-full py-1.5 md:py-5 bg-white">
        <Container className="relative flex h-full w-full items-center justify-between lg:px-5 text-black">
          <div className="z-10 flex items-center">
            {isHydrated && <HeaderMenuMobile />}
            <Link to="/">
              <div className="w-[150px] md:w-[184px]">
                <SanityImage
                  src={logo.headerLogo.asset._ref}
                  layout="responsive"
                  sizes={['50vw, 100vw']}
                  width="184"
                  height="44"
                />
              </div>
            </Link>
          </div>
          <div className="z-10 hidden flex-shrink-0 items-center md:flex lg:w-[35%] xl:w-1/2">
            <HeaderSearch />
          </div>
          <div className="flex items-center space-x-1">
            <div
              className={clsx(
                'hidden', //
                'lg:flex',
              )}
            >
              {isHydrated && <LanguageSelector />}
              {isHydrated && <CountrySelector />}
              {isHydrated && <CurrencySelector />}
            </div>
            <AccountLink className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5" />
            <CartCount isHome={isHome} openCart={openCart} />
          </div>
        </Container>
      </div>
      <MenuBarDesktop />
      {isHydrated && <MenuBarMobile />}
      <div className="md:hidden">
        <HeaderSearch />
      </div>
    </header>
  );
}

import React from 'react';
import type {
  Cart as CartType,
  CartCost,
} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';
import {useMatches} from '@remix-run/react';
import {flattenConnection} from '@shopify/hydrogen';

//domains
import type {SanityProductSettings} from 'domains/product';
import {type CurrencySettingsGlobal, formatCurrency} from 'domains/currency';
import {I18nLocale} from '~/lib/type';

export default function CartSummary({
  cost,
  children = null,
  cart,
}: {
  children?: React.ReactNode;
  cost: CartCost;
  cart: CartType | null;
}) {
  const [root] = useMatches();
  const productSettings = root?.data.productSettings as SanityProductSettings;
  const translation = root?.data?.translation;
  const selectedLocale = root?.data?.selectedLocale as I18nLocale;
  const currency = root.data.currency as CurrencySettingsGlobal;
  const selectedCurrency = currency.selectedCurrency;
  const selectedCurrencyDetails = currency.all_currencies.find(
    (c) => c.currency === selectedCurrency,
  );
  const totalQuantity = cart?.lines ? flattenConnection(cart.lines)?.filter(l => l.quantity !==0)?.length : 0;
  const subtotalFormatted =
    cart &&
    formatCurrency({
      price: cart?.cost.subtotalAmount,
      selectedCurrency: currency.selectedCurrency,
      exchangeRates: currency.exchangeRates,
      baseCurrency: currency.baseCurrency,
    });
  const totalFormatted =
    cart &&
    formatCurrency({
      price: cart?.cost.totalAmount,
      selectedCurrency: currency.selectedCurrency,
      exchangeRates: currency.exchangeRates,
      baseCurrency: currency.baseCurrency,
    });

  return (
    <section aria-labelledby="summary-heading" className={clsx('')}>
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>
      <div className="sticky bottom-0">
        <div className="relative flex flex-col">
          <div className="text-sm md:text-[20px] font-normal text-hk-orange">
            {totalQuantity} {translation?.cart?.item_label ?? 'item'}
            {totalQuantity > 1 && selectedLocale?.language === 'EN' && 's'}{' '}
            {translation?.cart?.item_in_cart_description ??
              'in your shopping cart'}
          </div>
          <div className="my-[16px] border-t-4 border-black"></div>
        </div>
      </div>
      <div className="mb-8">
        <div
          role="table"
          aria-label="Cost summary"
          className="mb-[30px] text-sm"
        >
          <div className="grid gap-2">
            <div className="flex justify-between space-x-2 text-md" role="row">
              <span className="font-normal text-sm" role="rowheader">
                {translation?.cart?.subtotal_label ?? 'Subtotal'}:
              </span>
              <span className="text-right font-normal text-sm">
                {/* {discountInfo?.subtotal} */}
                {/* {selectedCurrencyDetails?.details?.currency?.code}
                  {discountInfo?.subtotal_value?.toFixed(2)} */}
                {selectedCurrencyDetails?.details?.currency?.code}
                {subtotalFormatted?.toFixed(2)}
              </span>
            </div>
            {/* can map over discounts_value to show individual discount price, [code]: [discount_value] */}
            {/* discountInfo */}
            <div
              className="flex justify-between space-x-2 text-md md:text-[22px]"
              role="row"
            >
              <span className="font-semibold" role="rowheader">
                {translation?.cart?.total_label ?? 'Total'}:
              </span>
              <span className="text-right font-semibold">
                {/* {discountInfo?.total} */}
                {/* {selectedCurrencyDetails?.details?.currency?.code}
                  {discountInfo?.total_value?.toFixed(2)} */}
                {selectedCurrencyDetails?.details?.currency?.code}
                {totalFormatted?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <dl className="grid">
        <div className="flex items-center justify-between font-medium">
          <Text as="dt">Subtotal</Text>
          <Text as="dd" data-test="subtotal">
            {cost?.subtotalAmount?.amount ? (
              <Money data={cost?.subtotalAmount} />
            ) : (
              '-'
            )}
          </Text>
        </div>
      </dl> */}
      {children}
    </section>
  );
}

import {Listbox} from '@headlessui/react';
import type {
  Country,
  CountryCode,
} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';
import {useCallback, useMemo, useState} from 'react';
import {useMatches} from '@remix-run/react';
import {I18nLocale} from '~/lib/type';
/**
 * A client component that selects the appropriate country to display for products on a website
 */

type Props = {
  align?: 'center' | 'left' | 'right';
};

export default function CountrySelector({align = 'center'}: Props) {
  const [listboxOpen, setListboxOpen] = useState(false);

  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale as I18nLocale;

  const currentCountry = useMemo<{name: string; isoCode: CountryCode}>(() => {
    const regionNamesInEnglish = new Intl.DisplayNames(['en'], {
      type: 'region',
    });
    return {
      name:
        selectedLocale.country === 'TW'
          ? regionNamesInEnglish.of('TW') ?? 'Taiwan'
          : regionNamesInEnglish.of('HK') ?? 'Hong Kong',
      isoCode: selectedLocale.country === 'TW' ? 'TW' : 'HK',
    };
  }, [selectedLocale.country]);

  const setCountry = useCallback<(country: Country) => void>(
    ({isoCode: newIsoCode}) => {
      // if TW => redirect to tw.subdomain
      // if HK => redirect to primary domain
      //   window.location.href = redirectPath;
    },
    [currentCountry],
  );

  if (!currentCountry) {
    return null;
  }

  return (
    <div className="relative inline-flex">
      <div
        className={clsx(
          'flex h-[2.4rem] items-center rounded-sm px-3 py-2 text-sm font-normal duration-150',
        )}
      >
        <span className="mr-2">
          {currentCountry.isoCode === 'HK' ? (
            <span className="flex items-center space-x-1">
              <img
                alt="🇭🇰"
                className="inline-block w-4"
                src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1ed-1f1f0.svg"
              />
              <span className="">Hong Kong</span>
            </span>
          ) : currentCountry.isoCode === 'TW' ? (
            <span className="flex items-center space-x-1">
              <img
                alt="🇹🇼"
                className="inline-block w-4"
                src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1f9-1f1fc.svg"
              />
              <span className="">{currentCountry.name}</span>
            </span>
          ) : (
            currentCountry.name
          )}
        </span>
      </div>
    </div>
  );

  // return (
  //   <Listbox onChange={setCountry} value={currentCountry}>
  //     {({open}: {open: boolean}) => {
  //       setTimeout(() => setListboxOpen(open));
  //       return (
  //         <div className="relative inline-flex">
  //           <Listbox.Button
  //             className={clsx(
  //               'flex h-[2.4rem] items-center rounded-sm bg-darkGray bg-opacity-0 px-3 py-2 text-sm font-normal duration-150',
  //               'hover:bg-opacity-10',
  //             )}
  //           >
  //             <span className="mr-2">
  //               {currentCountry.isoCode === 'HK' ? (
  //                 <span className="flex items-center space-x-1">
  //                   <img
  //                     alt="🇭🇰"
  //                     className="inline-block w-4"
  //                     src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1ed-1f1f0.svg"
  //                   />
  //                   <span className="">Hong Kong</span>
  //                 </span>
  //               ) : currentCountry.isoCode === 'TW' ? (
  //                 <span className="flex items-center space-x-1">
  //                   <img
  //                     alt="🇹🇼"
  //                     className="inline-block w-4"
  //                     src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1f9-1f1fc.svg"
  //                   />
  //                   <span className="">{currentCountry.name}</span>
  //                 </span>
  //               ) : (
  //                 currentCountry.name
  //               )}
  //             </span>
  //             <ChevronDownIcon className={clsx(open && 'rotate-180')} />
  //           </Listbox.Button>

  //           <Listbox.Options
  //             className={clsx(
  //               'absolute top-full z-10 mt-3 min-w-[150px] overflow-hidden rounded shadow',
  //               align === 'center' && 'left-1/2 -translate-x-1/2',
  //               align === 'left' && 'left-0',
  //               align === 'right' && 'right-0',
  //             )}
  //           >
  //             <div className="max-h-64 overflow-y-auto bg-white">
  //               {listboxOpen && (
  //                 <Countries
  //                   selectedCountry={currentCountry}
  //                   getClassName={(active: boolean) => {
  //                     return clsx([
  //                       'p-3 flex justify-between items-center text-left font-bold text-sm cursor-pointer whitespace-nowrap',
  //                       active ? 'bg-darkGray bg-opacity-5' : null,
  //                     ]);
  //                   }}
  //                 />
  //               )}
  //               {/* {listboxOpen && (
  //                 <Suspense
  //                   fallback={
  //                     <div className="flex justify-center overflow-hidden">
  //                       <SpinnerIcon />
  //                     </div>
  //                   }
  //                 >
  //                   <Countries
  //                     selectedCountry={currentCountry}
  //                     getClassName={(active: boolean) => {
  //                       return clsx([
  //                         'p-3 flex justify-between items-center text-left font-bold text-sm cursor-pointer whitespace-nowrap',
  //                         active ? 'bg-darkGray bg-opacity-5' : null,
  //                       ]);
  //                     }}
  //                   />
  //                 </Suspense>
  //               )} */}
  //             </div>
  //           </Listbox.Options>
  //         </div>
  //       );
  //     }}
  //   </Listbox>
  // );
}

export function Countries({
  getClassName,
  selectedCountry,
}: {
  getClassName: (active: boolean) => string;
  selectedCountry: Pick<Country, 'isoCode' | 'name'>;
}) {
  return (
    <>
      <Listbox.Option value="HK">
        {({active}: {active: boolean}) => (
          <a
            href="https://helmetking.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className={getClassName(active)}>
              <span className="mr-8 font-normal">
                <span className="flex items-center space-x-1">
                  <img
                    alt="🇭🇰"
                    className="inline-block w-4"
                    src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1ed-1f1f0.svg"
                  />
                  <span className="">Hong Kong</span>
                </span>
              </span>
              <div className="text-hk-orange">
                <RadioIcon
                  checked={selectedCountry.isoCode === 'HK'}
                  hovered={active}
                />
              </div>
            </div>
          </a>
        )}
      </Listbox.Option>
      {/* <Listbox.Option value="TW">
        {({active}: {active: boolean}) => (
          <a
            href="https://hmksite2-0-hmk-team.vercel.app"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className={getClassName(active)}>
              <span className="mr-8 font-normal">
                <span className="flex items-center space-x-1">
                  <img
                    alt="🇹🇼"
                    className="inline-block w-4"
                    src="https://twemoji.maxcdn.com/v/14.0.2/svg/1f1f9-1f1fc.svg"
                  />
                  <span className="">Taiwan</span>
                </span>
              </span>
              <div className="text-hk-orange">
                <RadioIcon
                  checked={selectedCountry.isoCode === 'TW'}
                  hovered={active}
                />
              </div>
            </div>
          </a>
        )}
      </Listbox.Option> */}
    </>
  );
}

export function ChevronDownIcon(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.5L6 8.25L2.25 4.5"
        stroke="#3A3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RadioIcon({
  checked,
  hovered,
}: {
  checked?: boolean;
  hovered?: boolean;
}) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {checked ? (
        <>
          <path
            d="M0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5Z"
            fill="currentColor"
          />
          <path
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            fill="white"
          />
          <path
            className={hovered ? 'opacity-100' : 'opacity-0'}
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="#E7E7E7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            stroke="#E7E7E7"
          />
        </>
      )}
    </svg>
  );
}

import React from 'react';
import clsx from 'clsx';
import BlockContent from '@sanity/block-content-to-react';
import type {
  PortableTextBlock,
  PortableTextMarkDefinition,
} from '@portabletext/types';
import {Link} from 'components/Link';
// import type {Block as SanityBlock} from '@sanity/types';
// import Block from './blocks/Block';
// import ListBlock from './blocks/List';

export interface SanityBlock<TChild = Span> {
  _type: string;
  _key: string;
  style: string;
  children: TChild[];
  markDefs: MarkDefinition[];
}

export interface Span {
  _type: 'span';
  _key: string;
  marks: string[];
  text: string;
}

export interface MarkDefinition {
  [key: string]: unknown;
  _type: string;
  _key: string;
}

type Props = {
  blocks: SanityBlock[];
  className?: string;
};

export default function PortableText({blocks, className}: Props) {
  return (
    <BlockContent
      blocks={blocks}
      className={clsx('portableText', className)}
      renderContainerOnSingleChild
      serializers={{
        // Lists
        list: ListBlock,
        // Marks
        marks: {
          annotationLinkEmail: LinkEmailAnnotation,
          annotationLinkExternal: LinkExternalAnnotation,
          annotationLinkInternal: LinkInternalAnnotation,
        },
        // Block types
        types: {
          block: Block,
        },
      }}
    />
  );
}

type LinkEmailAnnotationProps = PortableTextBlock & {
  mark: PortableTextMarkDefinition & {
    email: string;
  };
};

const LinkEmailAnnotation = (props: LinkEmailAnnotationProps) => {
  const {children, mark} = props;
  return (
    <a
      className={clsx(
        'underline transition-opacity duration-200', //
        'hover:opacity-60',
      )}
      href={`mailto:${mark?.email}`}
    >
      <>{children}</>
    </a>
  );
};

type LinkExternalAnnotationProps = PortableTextBlock & {
  mark: PortableTextMarkDefinition & {
    newWindow?: boolean;
    url: string;
  };
};

const LinkExternalAnnotation = ({
  children,
  mark,
}: LinkExternalAnnotationProps) => {
  return (
    <a
      className={clsx(
        'inline-flex items-center underline transition-opacity duration-200',
        'hover:opacity-60',
      )}
      href={mark?.url}
      rel="noopener noreferrer"
      target={mark?.newWindow ? '_blank' : '_self'}
    >
      <>{children}</>
    </a>
  );
};

type LinkInternalAnnotationProps = PortableTextBlock & {
  mark: PortableTextMarkDefinition & {
    slug?: string;
  };
};

function LinkInternalAnnotation({children, mark}: LinkInternalAnnotationProps) {
  if (!mark?.slug) {
    return null;
  }

  return (
    <Link
      className={clsx(
        'inline-flex items-center underline transition-opacity duration-200',
        'hover:opacity-60',
      )}
      to={mark?.slug}
    >
      <>{children}</>
    </Link>
  );
}

const SHARED_LIST_CLASSES = clsx(
  'first:mt-0 last:mb-0', //
  'my-8 space-y-0.5 leading-paragraph list-outside ml-8',
);

function ListBlock(props: any) {
  const {children, type} = props;

  if (type === 'bullet') {
    return <ul className={SHARED_LIST_CLASSES}>{children}</ul>;
  }

  if (type === 'number') {
    return <ol className={SHARED_LIST_CLASSES}>{children}</ol>;
  }

  return null;
}

type BlockProps = {
  children?: React.ReactNode;
  node: PortableTextBlock;
};

function Block({children, node}: BlockProps) {
  if (node.style === 'h2') {
    return (
      <h2
        className={clsx(
          'first:mt-0 last:mb-0', //
          'mt-16 mb-4 text-xl font-bold',
        )}
      >
        {children}
      </h2>
    );
  }

  // Pragraphs
  return (
    <p
      className={clsx(
        'first:mt-0 last:mb-0', //
        'relative my-4 leading-paragraph',
      )}
    >
      {children}
    </p>
  );
}

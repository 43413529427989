import React from 'react';
import clsx from 'clsx';
import {Disclosure, Popover, Transition} from '@headlessui/react';
import {useMatches} from '@remix-run/react';
import * as Portal from '@radix-ui/react-portal';

//components
import SanityImage from 'components/SanityImage';
import {Link} from 'components/Link';
import PortableText from 'components/PortableText';
import LanguageSelector from 'components/global/LanguageSelector';
import CurrencySelector from 'components/global/CurrencySelector';
// import CountrySelector from 'components/global/CountrySelector';

//domains
import type {Brand} from 'domains/brands';
import {useIsHydrated} from '~/hooks/useIsHydrated';

export default function HeaderMenuMobile() {
  const [isOpened, setIsOpened] = React.useState(false);
  const closeMenu = () => setIsOpened(() => false);

  return (
    <div className="mr-2 md:hidden">
      <button onClick={() => setIsOpened((prevState) => !prevState)}>
        {isOpened ? <CrossIcon /> : <HamburgerIcon />}
      </button>

      <Transition appear show={isOpened} as={React.Fragment}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 left-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Portal.Root className="fixed left-0 top-0 z-[100] h-[calc(100vh-157px)] w-full translate-y-[157px]">
            <div
              onClick={() => setIsOpened(() => false)}
              className="absolute left-0 top-0 h-full w-full bg-black bg-opacity-20"
            ></div>
            <div className="z-1 absolute top-0 left-0 h-full w-10/12 bg-white shadow-md">
              <MenuMobile closeMenu={closeMenu} />
            </div>
          </Portal.Root>
        </Transition.Child>
      </Transition>
    </div>
  );
}

function MenuMobile({closeMenu}: {closeMenu: () => void}) {
  const [root] = useMatches();
  const menu = root.data.menu as any;
  const allBrands = root.data.allBrands as Brand[];
  const business = root.data.business as any;

  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabets = alpha.map((x) => String.fromCharCode(x));
  const availableAlphabets = alphabets.filter((a) =>
    allBrands
      ?.map((b) => b.title.toUpperCase()?.replace(/\s/g, '').charAt(0))
      .includes(a),
  );
  const brandsByInitials = [
    {
      initials: '#',
      brands: allBrands?.filter(
        (b) =>
          !availableAlphabets.includes(
            b?.title?.replace(/\s/g, '').charAt(0)?.toUpperCase(),
          ),
      ),
    },
  ].concat(
    availableAlphabets.map((a) => {
      return {
        initials: a,
        brands: allBrands?.filter(
          (b) =>
            b?.title?.replace(/\s/g, '')?.charAt(0)?.toLocaleLowerCase() ===
            a.toLocaleLowerCase(),
        ),
      };
    }),
  );
  const isHydrated = useIsHydrated();
  return (
    <div className="mobile-menu flex h-full flex-col justify-between overflow-y-scroll pt-5 text-black">
      <div className="px-5">
        <div className="flex items-center space-x-2">
          {isHydrated && <LanguageSelector />}
          {isHydrated && <CurrencySelector />}
        </div>
        <ul className="grid">
          {menu &&
            menu.map((item: any) => {
              const internal_link_slug =
                item.slug === '/blogs/all' ? '/blogs' : item.slug;
              return (
                <li
                  key={item._key}
                  className={clsx([
                    'relative py-4 transition-all duration-200 ease-out hover:text-hk-orange',
                    item.title === 'Sale' && 'text-hk-red',
                  ])}
                >
                  {item._type === 'linkMegaMenu' ? (
                    <>
                      <Disclosure>
                        {({open}) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-between">
                              <span className="block w-full whitespace-nowrap text-left text-sm tracking-wide">
                                {item.title}
                              </span>
                              <div className="">
                                {open ? (
                                  <svg
                                    width="13"
                                    height="3"
                                    viewBox="0 0 13 3"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.0851 0.659999V2.816H0.913063V0.659999H12.0851Z"
                                      fill="black"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.31536 0.611999V6.688H0.37936V8.844H6.31536V14.892H8.69536V8.844H14.6594V6.688H8.69536V0.611999H6.31536Z"
                                      fill="black"
                                    />
                                  </svg>
                                )}
                              </div>
                            </Disclosure.Button>

                            <Transition
                              show={open}
                              enter="transition-all duration-300"
                              enterFrom="opacity-0 translate-x-4"
                              enterTo="opacity-100 translate-x-0"
                              leave="transition-all duration-300"
                              leaveFrom="opacity-100 translate-x-0"
                              leaveTo="opacity-0 translate-x-4"
                            >
                              <div className="bg-white py-4 text-black">
                                <div
                                  className={clsx('relative grid gap-8 pl-5')}
                                >
                                  {item?.col_1_links && (
                                    <LinkGroup
                                      links={item.col_1_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                  {item?.col_2_links && (
                                    <LinkGroup
                                      links={item.col_2_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                  {item?.col_3_links && (
                                    <LinkGroup
                                      links={item.col_3_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                  {item?.col_4_links && (
                                    <LinkGroup
                                      links={item.col_4_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                  {item?.col_5_links && (
                                    <LinkGroup
                                      links={item.col_5_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                </div>
                              </div>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </>
                  ) : item._key === '4e4877ba5a8b' ? (
                    <Disclosure>
                      {({open}) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between">
                            <span className=" text-sm">{item.title}</span>
                            <div className="">
                              {open ? (
                                <svg
                                  width="13"
                                  height="3"
                                  viewBox="0 0 13 3"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0851 0.659999V2.816H0.913063V0.659999H12.0851Z"
                                    fill="black"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.31536 0.611999V6.688H0.37936V8.844H6.31536V14.892H8.69536V8.844H14.6594V6.688H8.69536V0.611999H6.31536Z"
                                    fill="black"
                                  />
                                </svg>
                              )}
                            </div>
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition-all duration-300"
                            enterFrom="opacity-0 translate-x-4"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition-all duration-300"
                            leaveFrom="opacity-100 translate-x-0"
                            leaveTo="opacity-0 translate-x-4"
                          >
                            <Disclosure.Panel>
                              <div className=" bg-white py-4 text-black">
                                <div className="relative grid gap-8 pl-5">
                                  {brandsByInitials &&
                                    brandsByInitials.map((b) => {
                                      return (
                                        <div
                                          key={`mobile-menu-brands-${b}`}
                                          className=""
                                        >
                                          <span className="">{b.initials}</span>
                                          <ul className="">
                                            {b.brands &&
                                              b.brands.map((brand) => {
                                                return (
                                                  <li
                                                    key={`mobile-menu-brand-${brand.shopify_brand_node}`}
                                                    className=""
                                                  >
                                                    <Link
                                                      to={`/collections/vendor?pf_v_brand=${brand.shopify_brand_node}`}
                                                      onClick={closeMenu}
                                                    >
                                                      {/* <Popover.Button> */}
                                                      <span className="font-light">
                                                        {brand.title}
                                                      </span>
                                                      {/* </Popover.Button> */}
                                                    </Link>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  ) : item._type === 'linkInternal' ? (
                    <Link
                      to={`${internal_link_slug ?? item.slug}`}
                      className="whitespace-nowrap text-sm"
                      onClick={closeMenu}
                    >
                      {item.title}
                    </Link>
                  ) : item._type === 'linkMenuMultiCols' ? (
                    <>
                      <Disclosure>
                        {({open}) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-between">
                              <span className="block w-full whitespace-nowrap text-left text-sm tracking-wide">
                                {item.title}
                              </span>
                              <div className="">
                                {open ? (
                                  <svg
                                    width="13"
                                    height="3"
                                    viewBox="0 0 13 3"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.0851 0.659999V2.816H0.913063V0.659999H12.0851Z"
                                      fill="black"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.31536 0.611999V6.688H0.37936V8.844H6.31536V14.892H8.69536V8.844H14.6594V6.688H8.69536V0.611999H6.31536Z"
                                      fill="black"
                                    />
                                  </svg>
                                )}
                              </div>
                            </Disclosure.Button>

                            <Transition
                              show={open}
                              enter="transition-all duration-300"
                              enterFrom="opacity-0 translate-x-4"
                              enterTo="opacity-100 translate-x-0"
                              leave="transition-all duration-300"
                              leaveFrom="opacity-100 translate-x-0"
                              leaveTo="opacity-0 translate-x-4"
                            >
                              <div className="bg-white py-4 text-black">
                                <div
                                  className={clsx('relative grid gap-8 pl-5')}
                                >
                                  {item?.col_1_links && (
                                    <LinkGroup
                                      links={item.col_1_links.links}
                                      closeMenu={closeMenu}
                                    />
                                  )}
                                  {item?.columns.map((col: any) => {
                                    if (col.column) {
                                      return (
                                        <div key={col._key} className="">
                                          <LinkGroup
                                            links={col.column.link}
                                            closeMenu={closeMenu}
                                          />
                                        </div>
                                      );
                                    }
                                    return;
                                  })}
                                </div>
                              </div>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </>
                  ) : (
                    <Link
                      to={`${item.url}`}
                      className="whitespace-nowrap text-sm"
                      onClick={closeMenu}
                      target="_blank"
                    >
                      {item.title}
                    </Link>
                  )}
                  <div className="absolute bottom-0 left-0 w-full scale-x-105 border-t border-hk-deep-gray"></div>
                </li>
              );
            })}
        </ul>
      </div>
      <MobileMenuFooter business={business} closeMenu={closeMenu} />
    </div>
  );
}

function MobileMenuFooter({
  business,
  closeMenu,
}: {
  business: any;
  closeMenu: () => void;
}) {
  return (
    <div className="mt-10 bg-hk-deep-blue p-5 text-white gap-8 grid">
      {business.footer_links &&
        business?.footer_links?.map((l: any) => {
          if (l._type === 'custom.footerLinkGroup')
            return (
              <div key={l._key} className="lg:col-span-1">
                <h3 className="mb-8 font-bold tracking-wide">{l.title}</h3>
                <ul className="grid gap-4">
                  {l.links &&
                    l.links?.map((link: any) => {
                      if (link._type === 'custom.linkWithIcon') {
                        return (
                          <li key={link._key} className="font-normal">
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="flex items-center space-x-2"
                              onClick={closeMenu}
                            >
                              <div className="w-[22px]">
                                <SanityImage
                                  layout="responsive"
                                  sizes={['50vw, 100vw']}
                                  src={link.icon?.asset._ref}
                                />
                              </div>
                              <span className="">{link?.icon_title}</span>
                            </a>
                          </li>
                        );
                      }
                      if (link._type === 'block') {
                        return (
                          <li key={link._key} className="font-normal">
                            <PortableText blocks={link} />
                          </li>
                        );
                      }
                      if (link._type === 'linkInternal') {
                        // my account
                        if (
                          link._key === 'ceb49658be2e' ||
                          link.title === 'My Account' ||
                          link.title === '我的帳戶'
                        ) {
                          return (
                            <li key={link._key} className="font-normal">
                              <Link to={`/account`} onClick={closeMenu}>
                                {link.title}
                              </Link>
                            </li>
                          );
                        }
                        return (
                          <li key={link._key} className="font-normal">
                            <Link
                              to={link.slug}
                              onClick={closeMenu}
                              // to={`${generateSanityInternalPath({
                              //   type: link?.reference?._type,
                              //   slug: link?.reference?.slug?.current,
                              // })}`}
                            >
                              {link.title}
                            </Link>
                          </li>
                        );
                      }
                      return (
                        <li key={link._key} className="font-normal">
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.title}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          return;
        })}
    </div>
  );
}

interface LinkGroupProps {
  links: any;
  closeMenu: () => void;
}

const LinkGroup: React.FC<LinkGroupProps> = ({links, closeMenu}) => {
  const indexOfAllGroupsFirstItem =
    links &&
    links.reduce(function (a: any, e: any, i: number) {
      if (e.is_group) a.push(i);
      return a;
    }, []);

  return (
    <div className="">
      <div className="grid gap-4">
        {links.map((l: any, index: number) => {
          return (
            <div
              key={l._key}
              className={clsx([
                `${l.is_group ? 'font-semibold underline' : 'font-light'}`,
                `${
                  indexOfAllGroupsFirstItem.includes(index) &&
                  index !== 0 &&
                  'mt-4'
                }`,
                'text-sm tracking-wide',
              ])}
            >
              {l._type === 'linkInternal' && (
                <Link to={l.slug ? l.slug : ''} onClick={closeMenu}>
                  {/* <Popover.Button> */}
                  {l.title}
                  {/* </Popover.Button> */}
                </Link>
              )}
              {l._type === 'linkExternal' && (
                <a
                  href={l.url}
                  onClick={closeMenu}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {l.title}
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function HamburgerIcon() {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="w-[32px]"
    >
      <path
        d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-4 11.25c0 .414-.336.75-.75.75h-8.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h8.5c.414 0 .75.336.75.75zm0-3.248c0 .414-.336.75-.75.75h-8.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h8.5c.414 0 .75.336.75.75zm0-3.252c0 .414-.336.75-.75.75h-8.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h8.5c.414 0 .75.336.75.75z"
        fillRule="nonzero"
      />
    </svg>
  );
}

function CrossIcon() {
  return (
    <div className="flex w-[32px] items-center justify-center">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

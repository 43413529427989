import React from 'react';
import clsx from 'clsx';
import {useLoaderData, useMatches} from '@remix-run/react';
import type {Product as ProductType} from '@shopify/hydrogen/storefront-api-types';
import {flattenConnection} from '@shopify/hydrogen';

//data
import {loader} from '~/routes/($lang)/products/$productHandle';

//components
import {Link} from 'components/Link';
import Product from 'components/product/Product';
import ProductPrices from 'components/product/ProductPrices';
import {AddToCartButton} from 'components/AddToCartButton';

//domains
import type {SanityProductSettings} from 'domains/product';
import {type CurrencySettingsGlobal, formatCurrency} from 'domains/currency';

interface Props {
  product: ProductType;
}

export default function CartFBT({product}: Props) {
  const [root] = useMatches();
  const translation = root?.data?.translation;
  const productSettings = root?.data.productSettings as SanityProductSettings;
  const currency = root.data.currency as CurrencySettingsGlobal;
  const selectedCurrency = currency.selectedCurrency;

  const firstVariant =
    flattenConnection(product.variants).find((v) => v.availableForSale) ??
    flattenConnection(product.variants)[0];

  const selectedCurrencyDetails = currency.all_currencies.find(
    (c) => c.currency === selectedCurrency,
  );

  const priceFormatted = formatCurrency({
    price: firstVariant.price,
    selectedCurrency: currency.selectedCurrency,
    exchangeRates: currency.exchangeRates,
    baseCurrency: currency.baseCurrency,
  });

  const compareAtPriceFormatted =
    firstVariant.compareAtPrice &&
    formatCurrency({
      price: firstVariant.compareAtPrice,
      selectedCurrency: currency.selectedCurrency,
      exchangeRates: currency.exchangeRates,
      baseCurrency: currency.baseCurrency,
    });
  return (
    <div className="frequently-bought-together mt-10">
      <h4 className="mb-[20px] text-[22px] font-medium">
        {productSettings?.product_settings_translations
          ?.frequently_bought_together_label ?? 'Freguently Bought Together'}
      </h4>
      <div className="grid gap-8 lg:grid-cols-2">
        <Product product={product} quickAdd type="cart" />
        <div className="">
          <div className="mb-5 flex items-center space-x-1 text-[20px]">
            <div className="">
              {productSettings.product_settings_translations
                .total_price_label ?? 'Total Price'}
              :{' '}
            </div>
            <div className="font-semibold text-hk-orange">
              <div className="flex justify-center flex-col items-center font-bold">
                {compareAtPriceFormatted ? (
                  <span className="font-normal text-darkGray line-through decoration-red">
                    {selectedCurrencyDetails?.details?.currency?.code}
                    {compareAtPriceFormatted?.toFixed(2)}
                  </span>
                ) : null}
                <span className={clsx('')}>
                  {selectedCurrencyDetails?.details?.currency?.code}
                  {priceFormatted && priceFormatted?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="">
            <button
              onClick={handleAddItem}
              className={clsx([
                'w-full bg-black py-3 text-[20px] font-normal uppercase text-hk-orange transition-all duration-200 ease-out hover:bg-hk-orange hover:text-black',
              ])}
            >
              {button_label ?? 'ADD SELECTED TO CART'}
            </button>
          </div> */}
          <AddToCartButton
            lines={[
              {
                quantity: 1,
                merchandiseId: firstVariant.id,
              },
            ]}
            variant="secondary"
            className="w-full bg-black py-3 text-[20px] font-normal uppercase text-hk-orange transition-all duration-200 ease-out hover:bg-hk-orange hover:text-black"
          >
            <span className="flex items-center justify-center">
              {translation?.cart?.add_to_cart_label ?? 'Add to cart'}
            </span>
          </AddToCartButton>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import {Popover, Tab, Disclosure} from '@headlessui/react';
import clsx from 'clsx';
import * as R from 'ramda';
import * as Portal from '@radix-ui/react-portal';
import {useMatches} from '@remix-run/react';
import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from 'components/Link';
import {Image} from '@shopify/hydrogen';
import {useFetcher, useRevalidator, useLocation} from '@remix-run/react';
import {LoadingSpinner} from '../global/LoadingSpinner';
import {type MarselloCustomerData} from '~/routes/($lang)/api/marsello';
import {isBefore} from 'date-fns';
import {
  type RedeemCouponResponse,
  type RedeemCouponResponseError,
} from '~/routes/($lang)/api/redeem-coupon';
import {Await} from '@remix-run/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as Accordion from '@radix-ui/react-accordion';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useQuery} from 'react-query';
import {match} from 'ts-pattern';

interface FeaturesTab {
  Title: string;
  Status: string;
  DataTab: string;
}

interface EarnListItem {
  Title: string;
  Description: string;
  Action: {
    Type: number;
    Target: number;
    TargetStr: string;
    Url: any;
  };
}

interface RewardsItem {
  Id: string;
  Points: number;
  PointsStr: string;
  Terms: string;
  Title: string;
  Type: string;
  Alert: any;
}

interface TierListItem {
  TierId: string;
  Name: string;
  TierDescription: string;
  ThresholdStr: string;
  ThresholdValue: number;
  PointsPerDollar: number;
  Claimable: boolean;
  Rewards: RewardsItem[];
}

export interface MarselloData {
  AccountDetail: {
    Domain: string;
    LoginLink: string;
    RegisterLink: string;
  };
  CustomerDetail: {
    Id: string;
    ProviderId: string;
    SourceProvId: string;
    Token: string;
    Tier: string;
    AccessibleTiers: any;
  };
  Content: {
    RewardsTab: {
      PageTitle: string;
      HeaderText: string;
      RewardsTabName: string;
      EarnTabName: string;
      TiersTabName: string;
      RewardsCouponCodeText: string;
      RewardsRedeemButtonText: string;
      RewardsViewCouponButtonText: string;
      RewardsTermsLinkText: string;
      PointsToClaimText: string;
      PointsToUnlockText: string;
      PointsLabel: string;
    };
    EarnTab: {
      accountProvider: any;
      CreateAccountActionText: string;
      CompleteProfileActionText: string;
      BirthdayActionText: string;
      LikeOnFacebookActionText: string;
      ReferFriendActionText: string;
      FollowOnTwitterActionText: string;
      FollowOnInstagramActionText: string;
      ReviewProductActionText: string;
      ReviewProductSubtitleText: string;
      MakeAnOrderActionText: string;
      MakeAnOrderSubtitleText: string;
      TwitterShareActionText: string;
      FacebookShareActionText: string;
    };
    ViewCouponPage: {
      InstructionsText: string;
      CouponCodeLabelText: string;
      EmailText: string;
      BackButtonLinkText: string;
      CouponTermsText: string;
      ApplyCouponToCartText: string;
      ApplyCouponSuccessText: string;
      ApplyCouponFailureText: string;
    };
    BirthdayPage: {
      HeaderText: string;
      InstructionsText: string;
      BirthdateLabel: string;
      BackButtonLinkText: string;
      SaveButtonText: string;
    };
    CompleteProfilePage: {
      HeaderText: string;
      InstructionsText: string;
      BirthdateLabel: string;
      GenderLabel: string;
      MaleGenderLabel: string;
      FemaleGenderLabel: string;
      NotSpecifiedGenderLabel: string;
      MobileLabel: string;
      BackButtonLinkText: string;
      SaveButtonText: string;
    };
    GetCouponPage: {
      HeaderText: string;
      InstructionsText: string;
      NamePlaceholderText: string;
      EmailPlaceholderText: string;
      SendCouponButtonText: string;
    };
    WelcomePage: {
      HeaderText: string;
      InstructionsText: string;
      CreateAccountButtonText: string;
      LoginText: string;
      TermsAndPrivacyText: string;
      LoginLinkText: string;
      JoinLinkText: string;
      SeeRewardsLinkText: string;
      AuthenticationPromptText: string;
      OrText: string;
    };
    ReferralPage: {
      TitleText: string;
      HeaderText: string;
      TermsText: string;
      CopyLinkText: string;
    };
  };
  Design: {
    PrimaryImage: string;
    PrimaryColor: 'rgb(237, 108, 0)';
    PrimaryFont: 'Open Sans';
    PrimaryFontUrl: '';
    LayoutTypeStr: 'Slide';
    TabTypeStr: 'RoundedFloat';
    TabPositionStr: 'RightSide';
    TabText: 'Check Points';
    TabColor: 'rgb(254, 147, 0)';
    TabFont: null;
    TabFontUrl: null;
    TabImage: null;
    TabFontUsePrimary: true;
    ShowTabImage: false;
    TabVisibility: 'DesktopAndMobile';
    FrameCssUrl: 'https://app.marsello.com/Scripts/dist/Home/react/bundle/marselloStyle.css';
    PrimaryCssUrl: 'https://app.marsello.com/Scripts/dist/Home/react/bundle/widgetStyle.css';
    TabCssUrl: 'https://app.marsello.com/Scripts/dist/Home/react/bundle/widgetStyle.css';
  };
  Features: {
    Tabs: FeaturesTab[];
    ShowPoweredByLink: false;
  };
  Coupons: any;
  Rewards: RewardsItem[];
  EarnList: EarnListItem[];
  TierList: TierListItem[];
}

async function fetchMarselloCustomerData({
  setIsLoading,
  setMarselloCustomerData,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMarselloCustomerData: React.Dispatch<
    React.SetStateAction<MarselloCustomerData | null>
  >;
}) {
  try {
    const res = await fetch('/api/marsello');
    const data = (await res.json()) as {
      marsello_customer_data: MarselloCustomerData;
    };
    setMarselloCustomerData(data.marsello_customer_data);
    setIsLoading(false);
    return data;
  } catch (error) {
    console.log(error);
    setIsLoading(false);
    return null;
  }
}

const fetchMarselloData = (): Promise<{
  marsello_customer_data: MarselloCustomerData;
}> => fetch('/api/marsello').then((res) => res.json());

export default function MarselloModal() {
  const [root] = useMatches();
  const marsello = root?.data?.marsello as MarselloData | undefined;
  const is_logged_in = root?.data?.isLoggedIn as boolean;
  const fetcher = useFetcher<{marsello_customer_data: MarselloCustomerData}>();
  const revalidator = useRevalidator();
  // const [marselloCustomerData, setMarselloCustomerData] =
  //   React.useState<MarselloCustomerData | null>(null);
  // const [isLoading, setIsLoading] = React.useState(true);

  const {error, data, isFetching, isLoading, refetch} = useQuery({
    queryKey: ['marselloData', fetcher, is_logged_in],
    queryFn: fetchMarselloData,
  });

  // React.useEffect(() => {
  //   fetchMarselloCustomerData({setIsLoading, setMarselloCustomerData});
  // }, [is_logged_in, fetcher.data]);

  function refetchMarselloData() {
    refetch();
    // revalidator.revalidate();
  }

  if (!marsello) {
    return <></>;
  }

  return (
    <Popover className="">
      <Popover.Button className="h-[45px] w-[180px] translate-x-[-41%] md:translate-x-[-35%] translate-y-[144%] -rotate-90 rounded-full bg-hk-orange text-center text-white outline-none">
        {marsello.Design.TabText}
      </Popover.Button>
      <Portal.Root>
        <Popover.Panel className="fixed right-0 top-0 z-[100] h-screen w-full bg-white shadow-md md:w-[500px] text-black overflow-y-scroll">
          {isLoading ? (
            <div className="w-full h-full relative items-center justify-center flex">
              <div className="w-[40px]">
                <LoadingSpinner className="text-hk-orange" />
              </div>
            </div>
          ) : (
            <div className="h-full w-full relative">
              <Popover.Button className="absolute top-0 right-0 -translate-x-4 translate-y-3">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
                    fill="black"
                  />
                </svg>
              </Popover.Button>
              <div className="py-10 md:px-4">
                <header className="mb-16 text-center">
                  <div className="">
                    {marsello && (
                      <Image
                        alt="Logo"
                        data={{url: marsello?.Design.PrimaryImage}}
                      />
                    )}
                  </div>
                  <h3 className="mb-4 text-xl text-hk-orange">
                    {marsello?.Content.WelcomePage.HeaderText}
                  </h3>
                  <div className="text-sm font-normal">
                    {is_logged_in ? (
                      <div className="">
                        {/* <div
                          className=""
                          dangerouslySetInnerHTML={{
                            __html:
                              marsello?.Content.RewardsTab.HeaderText ?? '',
                          }}
                        ></div> */}
                        <div className="">
                          歡迎您 {data?.marsello_customer_data.firstName}！
                          已累積 {data?.marsello_customer_data.balance} 會員積分
                        </div>
                        <div className="text-center mt-2">
                          {marsello?.CustomerDetail.Tier}
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="">
                          {marsello?.Content.WelcomePage.InstructionsText}
                        </span>
                        <div className="mt-8 mb-2">
                          <Link
                            to="/account/register"
                            className="inline-block bg-hk-orange px-8 py-2 text-white"
                          >
                            {
                              marsello?.Content.WelcomePage
                                .CreateAccountButtonText
                            }
                          </Link>
                        </div>
                        <div
                          className="mb-2 marsello-html"
                          dangerouslySetInnerHTML={{
                            __html: marsello?.Content.WelcomePage.LoginText,
                          }}
                        >
                          {/* 已經有帳戶了嗎？{' '}
                    <Link
                      to="/account/login"
                      className="font-normal text-hk-orange underline"
                    >
                      {marsello?.Content.WelcomePage.LoginText ?? 'Login'}
                    </Link> */}
                        </div>
                        <div
                          className="font-normal marsello-html"
                          dangerouslySetInnerHTML={{
                            __html:
                              marsello?.Content.WelcomePage.TermsAndPrivacyText,
                          }}
                        >
                          {/* <Link
                      to="/pages/terms"
                      className="font-normal text-hk-orange underline"
                    >
                      {'Terms'}
                    </Link>
                    {' and '}
                    <Link
                      to="/pages/privacy"
                      className="font-normal text-hk-orange underline"
                    >
                      私穩相關條款
                    </Link> */}
                        </div>
                      </>
                    )}
                  </div>
                </header>
                <div className="">
                  {marsello && data?.marsello_customer_data && (
                    <FeatureTabs
                      features={marsello?.Features}
                      earnList={marsello?.EarnList}
                      tierList={marsello?.TierList}
                      rewardsList={marsello.Rewards}
                      rewards={data?.marsello_customer_data.rewards}
                      marselloCustomerData={data?.marsello_customer_data}
                      refetchMarselloData={refetchMarselloData}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Portal.Root>
    </Popover>
  );
}

function FeatureTabs({
  features,
  earnList,
  tierList,
  rewardsList,
  rewards,
  marselloCustomerData,
  refetchMarselloData,
}: {
  features: MarselloData['Features'];
  earnList: MarselloData['EarnList'];
  tierList: MarselloData['TierList'];
  rewardsList: MarselloData['Rewards'];
  rewards: MarselloCustomerData['rewards'] | undefined;
  marselloCustomerData: MarselloCustomerData | null;
  refetchMarselloData: () => void;
}) {
  const realRewards = rewards?.filter((r) => {
    return (
      r.expires !== null &&
      r.redeemCode !== null &&
      isBefore(new Date(), new Date(r.expires))
    );
  });

  const availableEarnActivities = marselloCustomerData?.earnActivities
    ?.filter((ea) => ea.available)
    ?.filter((ea) => ea.id !== '金會員-tier' && ea.id !== '白金會員-tier');

  return (
    <Tab.Group>
      <div className="overflow-y-scroll h-full px-5 lgd:px-0 md:overflow-hidden">
        <Tab.List className="grid grid-cols-3 border-b border-slate-300 border-opacity-50 text-center">
          {features?.Tabs &&
            features.Tabs.map((t) => (
              <Tab key={t.DataTab} className="relative py-6 outline-none">
                {({selected}) => (
                  <>
                    <span className="font-normal">{t.Title}</span>
                    {selected && (
                      <div className="absolute bottom-0 left-0 w-full border-b-2 border-hk-orange"></div>
                    )}
                  </>
                )}
              </Tab>
            ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <ul className="">
              {realRewards &&
                !R.isEmpty(realRewards) &&
                realRewards.map((el) => {
                  return <RedeemedReward key={el.name} item={el} />;
                })}
              {rewardsList &&
                !R.isEmpty(rewardsList) &&
                marselloCustomerData &&
                rewardsList.map((el) => {
                  return (
                    <RewardItem
                      key={el.Title}
                      item={el}
                      balance={marselloCustomerData.balance}
                      refetchMarselloData={refetchMarselloData}
                    />
                  );
                })}
            </ul>
          </Tab.Panel>
          <Tab.Panel>
            <ul className="">
              {availableEarnActivities &&
                !R.isEmpty(availableEarnActivities) &&
                availableEarnActivities.map((el) => {
                  if (el.id === 'profile-completed') {
                    return (
                      <Accordion.Root
                        key={el.id}
                        className="border-b border-slate-300 border-opacity-50 py-4"
                        type="single"
                        defaultValue=""
                        collapsible
                      >
                        <Accordion.AccordionItem value="item-1">
                          <Accordion.AccordionTrigger className="w-full">
                            <li
                              key={el.id}
                              className="flex items-center justify-between w-full"
                            >
                              <span className="font-normal shrink-0">
                                {el.title}
                              </span>

                              <div className="text-xs text-slate-600 opacity-50 min-w-[60px] text-right w-full flex-1 flex items-center justify-end">
                                <div className="">{el.description}</div>
                                <div className="w-5 ml-1">
                                  <ChevronDownIcon />
                                </div>
                              </div>
                            </li>
                          </Accordion.AccordionTrigger>
                          <Accordion.AccordionContent>
                            <ProfileForm
                              refetchMarselloData={refetchMarselloData}
                            />
                          </Accordion.AccordionContent>
                        </Accordion.AccordionItem>
                      </Accordion.Root>
                    );
                  }
                  if (el.id === 'referral') {
                    return (
                      <li
                        key={el.id}
                        className="flex items-center justify-between border-b border-slate-300 border-opacity-50 py-4"
                      >
                        <span className="font-normal shrink-0">{el.title}</span>

                        <div className="text-xs text-slate-600 opacity-50 min-w-[60px] text-right">
                          <div className="">
                            {`新會員透過推薦人的$50優惠券初次消費後，推薦人將獲得額外1500積分獎勵！複製並傳送此推薦連結給你的朋友吧`}
                          </div>
                          {marselloCustomerData?.referralLink && (
                            <ReferralLink
                              link={marselloCustomerData?.referralLink}
                            />
                          )}
                        </div>
                      </li>
                    );
                  }
                  if (
                    el.id === 'facebook-like' ||
                    el.id === 'instagram-follow'
                  ) {
                    return (
                      <FollowSocial
                        key={el.id}
                        refetchMarselloData={refetchMarselloData}
                        el={el}
                      />
                    );
                  }

                  return (
                    <li
                      key={el.id}
                      className="flex items-center justify-between border-b border-slate-300 border-opacity-50 py-4"
                    >
                      <span className="font-normal">{el.title}</span>

                      <div className="text-xs text-slate-600 opacity-50 min-w-[60px] text-right">
                        <div className="">{el.description}</div>
                        <a
                          href={el.url}
                          target="_blank"
                          rel="noreferrer"
                          className=""
                        >
                          {el.url}
                        </a>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </Tab.Panel>

          <Tab.Panel>
            <ul className="">
              {tierList &&
                !R.isEmpty(tierList) &&
                tierList.map((tl) => {
                  if (tl.Rewards && !R.isEmpty(tl.Rewards)) {
                    console.log(tl);
                    return (
                      <Disclosure key={tl.TierId} defaultOpen={false}>
                        {({open}) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-between border-b border-slate-300 border-opacity-50 py-4">
                              <span className="font-normal">{tl.Name}</span>

                              <div className="items-cener flex space-x-2 text-xs text-slate-400 opacity-50">
                                <span className="">{tl.ThresholdStr}</span>
                                <div className={clsx(!open && '-rotate-90')}>
                                  <ChevronDownIcon />
                                </div>
                              </div>
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <ul className="pl-5">
                                {tl.Rewards.map((r) => (
                                  <TierRewardItem
                                    key={r.Id}
                                    item={r}
                                    refetchMarselloData={refetchMarselloData}
                                    currentTier={marselloCustomerData?.tierName}
                                    tierName={tl.Name}
                                  />
                                  // <li
                                  //   key={r.Id}
                                  //   className="flex items-center justify-between border-b border-slate-300 border-opacity-50 py-4 px-2"
                                  // >
                                  //   <div className="flex items-center space-x-2">
                                  //     <svg
                                  //       xmlns="http://www.w3.org/2000/svg"
                                  //       width="24"
                                  //       height="24"
                                  //       viewBox="0 0 24 24"
                                  //       className="fill-current text-hk-orange"
                                  //     >
                                  //       <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 3c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm1 13.947v1.053h-1v-.998c-1.035-.018-2.106-.265-3-.727l.455-1.644c.956.371 2.229.765 3.225.54 1.149-.26 1.385-1.442.114-2.011-.931-.434-3.778-.805-3.778-3.243 0-1.363 1.039-2.583 2.984-2.85v-1.067h1v1.018c.725.019 1.535.145 2.442.42l-.362 1.648c-.768-.27-1.616-.515-2.442-.465-1.489.087-1.62 1.376-.581 1.916 1.711.804 3.943 1.401 3.943 3.546.002 1.718-1.344 2.632-3 2.864z" />
                                  //     </svg>
                                  //     <span className="text-sm font-normal text-slate-500 opacity-50">
                                  //       {r.Title}
                                  //     </span>
                                  //   </div>

                                  //   <div className="text-xs text-slate-500 opacity-50 min-w-[60px] text-righ">
                                  //     {r.PointsStr}
                                  //   </div>
                                  // </li>
                                ))}
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    );
                  }
                  return (
                    <li
                      key={tl.TierId}
                      className="flex items-center justify-between border-b border-slate-300 border-opacity-50 py-4"
                    >
                      <span className="font-normal">{tl.Name}</span>

                      <div className="text-xs text-slate-400 opacity-50 min-w-[60px] text-right">
                        {tl.ThresholdStr}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </Tab.Panel>
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}

function RedeemedReward({item}: {item: MarselloCustomerData['rewards'][0]}) {
  const [isOpened, setIsOpened] = React.useState(false);
  return (
    <li className="border-b border-slate-300 border-opacity-50 relative">
      {isOpened && (
        <div className="fixed w-full h-full  top-0 left-0 z-[1000] items-center flex justify-center">
          <button
            onClick={() => setIsOpened(false)}
            className="absolute top-0 left-0 w-full h-full bg-black/30"
          ></button>
          <div className="max-w-lg px-4 py-8 bg-white text-black text-center relative z-[1]">
            <button
              onClick={() => setIsOpened(false)}
              className="absolute top-0 right-0 -translate-x-3 translate-y-3"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
                  fill="black"
                />
              </svg>
            </button>
            <h3 className="text-lg font-bold mb-2">{item.name}</h3>
            <div className="my-8">使用此代碼在結賬時兌換</div>
            <div className="border p-2 text-center w-full mb-4">
              優惠卷號碼: {item.redeemCode}
            </div>
            <div className="mb-8">{item.terms}</div>
            <div className="">這張優惠券正等著你</div>
          </div>
        </div>
      )}
      <button
        onClick={() => setIsOpened(true)}
        className="flex items-center justify-between w-full py-4 cursor-pointer"
      >
        <div className="flex items-center space-x-2">
          {item.discountType === 'dollar-off' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 3c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm1 13.947v1.053h-1v-.998c-1.035-.018-2.106-.265-3-.727l.455-1.644c.956.371 2.229.765 3.225.54 1.149-.26 1.385-1.442.114-2.011-.931-.434-3.778-.805-3.778-3.243 0-1.363 1.039-2.583 2.984-2.85v-1.067h1v1.018c.725.019 1.535.145 2.442.42l-.362 1.648c-.768-.27-1.616-.515-2.442-.465-1.489.087-1.62 1.376-.581 1.916 1.711.804 3.943 1.401 3.943 3.546.002 1.718-1.344 2.632-3 2.864z"
                />
              </svg>
            </div>
          )}
          {item.discountType === 'free-product' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"
                />
              </svg>
            </div>
          )}

          <span className="font-normal text-left">{item.name}</span>
        </div>

        <div className="text-xs text-slate-400 opacity-50 min-w-[60px] text-right">
          查看
        </div>
      </button>
    </li>
  );
}

function RewardItem({
  item,
  balance,
  refetchMarselloData,
}: {
  item: RewardsItem;
  balance: number;
  refetchMarselloData: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [redeemModalIsOpened, setRedeemModalIsOpened] = React.useState(true);
  const fetcher = useFetcher<{redeemed_reward: RedeemCouponResponse}>();

  console.log(item);

  return (
    <li className="border-b border-slate-300 border-opacity-50 relative">
      <Await resolve={fetcher.data}>
        {(resolved) => {
          if (resolved) {
            refetchMarselloData();
          }
          return redeemModalIsOpened && resolved ? (
            <div className="fixed w-full h-full  top-0 left-0 z-[1000] items-center flex justify-center">
              <button
                onClick={() => setRedeemModalIsOpened(false)}
                className="absolute top-0 left-0 w-full h-full bg-black/30"
              ></button>
              <div className="max-w-lg px-4 py-8 bg-white text-black text-center relative z-[1]">
                <button
                  onClick={() => setRedeemModalIsOpened(false)}
                  className="absolute top-0 right-0 -translate-x-3 translate-y-3"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <h3 className="text-lg font-bold mb-2">
                  {resolved?.redeemed_reward?.name}
                </h3>
                <div className="my-8">結帳時輸入此代碼套用優惠折扣</div>
                <div className="border p-2 text-center w-full mb-4">
                  優惠券號碼: {resolved?.redeemed_reward?.redeemCode}
                </div>
                <div className="mb-8">{resolved?.redeemed_reward?.terms}</div>
                <div className="">
                  這張優惠券已同時發送到您的註冊電郵郵箱，請查閱。留意條款與細則。
                </div>
              </div>
            </div>
          ) : (
            <></>
          );
        }}
      </Await>
      <button
        onClick={() => setOpen(!open)}
        className={clsx(
          'flex items-center justify-between w-full py-4',
          balance < item.Points ? 'cursor-not-allowed' : '',
        )}
      >
        <div className="flex items-center space-x-2">
          {item.Type === 'DollarOff' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 3c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm1 13.947v1.053h-1v-.998c-1.035-.018-2.106-.265-3-.727l.455-1.644c.956.371 2.229.765 3.225.54 1.149-.26 1.385-1.442.114-2.011-.931-.434-3.778-.805-3.778-3.243 0-1.363 1.039-2.583 2.984-2.85v-1.067h1v1.018c.725.019 1.535.145 2.442.42l-.362 1.648c-.768-.27-1.616-.515-2.442-.465-1.489.087-1.62 1.376-.581 1.916 1.711.804 3.943 1.401 3.943 3.546.002 1.718-1.344 2.632-3 2.864z"
                />
              </svg>
            </div>
          )}
          {item.Type === 'FreeProduct' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"
                />
              </svg>
            </div>
          )}

          <span className="font-normal text-left">{item.Title}</span>
        </div>

        <div className="text-xs text-slate-400 opacity-50 min-w-[60px] text-right">
          {item.PointsStr}
        </div>
      </button>
      {open && balance >= item.Points ? (
        <fetcher.Form method="post" action="/api/redeem-coupon">
          <input type="hidden" value={item.Id} name="coupon_id" />
          <button
            type="submit"
            className="text-white bg-hk-orange px-4 h-full absolute right-0 top-1/2 -translate-y-1/2 flex justify-center items-center"
          >
            {fetcher.state === 'submitting' || fetcher.state === 'loading' ? (
              <div className="w-5">
                <LoadingSpinner className="text-white" />
              </div>
            ) : (
              <span className="">領取</span>
            )}
          </button>
        </fetcher.Form>
      ) : null}
    </li>
  );
}
function TierRewardItem({
  item,
  refetchMarselloData,
  currentTier,
  tierName,
}: {
  item: RewardsItem;
  refetchMarselloData: () => void;
  currentTier?: string;
  tierName: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [redeemModalIsOpened, setRedeemModalIsOpened] = React.useState(true);
  const fetcher = useFetcher<{
    redeemed_reward: RedeemCouponResponse | RedeemCouponResponseError;
  }>();

  const canRedeemed = currentTier === tierName;

  return (
    <li
      className={clsx(
        'border-b border-slate-300 border-opacity-50 relative',
        !canRedeemed && 'cursor-not-allowed text-slate-400 opacity-50',
      )}
    >
      <Await resolve={fetcher.data}>
        {(resolved) => {
          if (resolved) {
            refetchMarselloData();
          }
          if (redeemModalIsOpened && resolved && resolved.redeemed_reward) {
            return (
              <div className="fixed w-full h-full  top-0 left-0 z-[1000] items-center flex justify-center">
                <button
                  onClick={() => setRedeemModalIsOpened(false)}
                  className="absolute top-0 left-0 w-full h-full bg-black/30"
                ></button>
                <div className="max-w-lg px-4 py-8 bg-white text-black text-center relative z-[1]">
                  <button
                    onClick={() => setRedeemModalIsOpened(false)}
                    className="absolute top-0 right-0 -translate-x-3 translate-y-3"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 9.35849L1.85849 15L0 13.1415L5.64151 7.5L0 1.85849L1.85849 0L7.5 5.64151L13.1415 0L15 1.85849L9.35849 7.5L15 13.1415L13.1415 15L7.5 9.35849Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                  <>
                    {match(resolved.redeemed_reward)
                      .with({status: 404}, () => (
                        <div className="">
                          Insufficent points to claim reward.
                        </div>
                      ))
                      .otherwise(() => (
                        <div className="">
                          <h3 className="text-lg font-bold mb-2">
                            {/* @ts-ignore */}
                            {resolved?.redeemed_reward?.name}
                          </h3>
                          <div className="my-8">
                            結帳時輸入此代碼套用優惠折扣
                          </div>
                          <div className="border p-2 text-center w-full mb-4">
                            {/* @ts-ignore */}
                            優惠券號碼: {resolved?.redeemed_reward?.redeemCode}
                          </div>
                          <div className="mb-8">
                            {/* @ts-ignore */}
                            {resolved?.redeemed_reward?.terms}
                          </div>
                          <div className="">
                            這張優惠券已同時發送到您的註冊電郵郵箱，請查閱。留意條款與細則。
                          </div>
                        </div>
                      ))}
                  </>
                </div>
              </div>
            );
          }
          return <></>;
        }}
      </Await>
      <button
        onClick={() => setOpen(!open)}
        className={clsx(
          'flex items-center justify-between w-full py-4',
          !canRedeemed && 'cursor-not-allowed',
        )}
      >
        <div className="flex items-center space-x-2">
          {item.Type === 'DollarOff' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 3c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm1 13.947v1.053h-1v-.998c-1.035-.018-2.106-.265-3-.727l.455-1.644c.956.371 2.229.765 3.225.54 1.149-.26 1.385-1.442.114-2.011-.931-.434-3.778-.805-3.778-3.243 0-1.363 1.039-2.583 2.984-2.85v-1.067h1v1.018c.725.019 1.535.145 2.442.42l-.362 1.648c-.768-.27-1.616-.515-2.442-.465-1.489.087-1.62 1.376-.581 1.916 1.711.804 3.943 1.401 3.943 3.546.002 1.718-1.344 2.632-3 2.864z"
                />
              </svg>
            </div>
          )}
          {item.Type === 'FreeProduct' && (
            <div className="w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  className="fill-hk-orange"
                  d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"
                />
              </svg>
            </div>
          )}

          <span className="font-normal text-left">{item.Title}</span>
        </div>

        <div className="text-xs text-slate-400 opacity-50 min-w-[60px] text-right">
          {item.PointsStr}
        </div>
      </button>
      {open ? (
        <fetcher.Form method="post" action="/api/redeem-coupon">
          <input type="hidden" value={item.Id} name="coupon_id" />
          <button
            type="submit"
            className={clsx(
              'text-white bg-hk-orange px-4 h-full absolute right-0 top-1/2 -translate-y-1/2 flex justify-center items-center',
              !canRedeemed && 'hidden',
            )}
          >
            {fetcher.state === 'submitting' || fetcher.state === 'loading' ? (
              <div className="w-5">
                <LoadingSpinner className="text-white" />
              </div>
            ) : (
              <span className="">領取</span>
            )}
          </button>
        </fetcher.Form>
      ) : null}
    </li>
  );
}

function ChevronDownIcon(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.5L6 8.25L2.25 4.5"
        stroke="#3A3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ReferralLink({link}: {link: string}) {
  const [copied, setCopied] = React.useState(false);

  return (
    <CopyToClipboard
      text={link}
      onCopy={() => {
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      <span className="inline-block p-2 text-md border border-black mt-2 text-black">
        {copied ? 'Copied' : 'Copy'}
      </span>
    </CopyToClipboard>
  );
}

function ProfileForm({refetchMarselloData}: {refetchMarselloData: () => void}) {
  const schema = yup.object({
    birthday: yup.date().required(),
    gender: yup.string().required(),
    phone: yup.string().required(),
  });
  const {
    handleSubmit,
    formState: {errors, isDirty, isValid},
    register,
  } = useForm<{
    birthday: string | null;
    phone: string | null;
    gender: string | null;
  }>({
    defaultValues: {
      birthday: null,
      gender: null,
      phone: null,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const fetcher = useFetcher();
  const formRef = React.useRef<HTMLFormElement>(null);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formRef?.current) {
      const formData = new FormData(formRef.current);
      console.log(formData);
      fetcher.submit(formData, {
        method: 'post',
        action: '/api/update-marsello-profile',
      });
    }
  };

  console.log(fetcher);

  React.useEffect(() => {
    if (fetcher?.data) {
      refetchMarselloData();
    }
  }, [fetcher?.data]);

  return (
    <div className="mt-8">
      <fetcher.Form
        className="grid gap-2"
        action="/api/update-marsello-profile"
        method="post"
        onSubmit={onSubmit}
        ref={formRef}
      >
        <fieldset className="flex items-center">
          <label htmlFor="birthday" className="min-w-[70px] shrink-0">
            生日
          </label>
          <input
            id="birthday"
            {...register('birthday')}
            type="date"
            className="w-full flex-1"
          />
        </fieldset>
        <fieldset className="flex items-center">
          <label htmlFor="gender" className="min-w-[70px] shrink-0">
            性別
          </label>
          <select {...register('gender')} className="w-full flex-1">
            <option value="Male">男</option>
            <option value="Female">女</option>
            <option value="Not specified">other</option>
          </select>
        </fieldset>
        <fieldset className="flex items-center">
          <label htmlFor="phone" className="min-w-[70px] shrink-0">
            手機號碼
          </label>
          <input {...register('phone')} type="text" className="w-full flex-1" />
        </fieldset>
        <div className="flex justify-end">
          <button
            className="py-2 px-6 text-md text-white bg-hk-orange"
            type="submit"
            disabled={!isValid || !isDirty}
          >
            {fetcher.state === 'submitting' ? '儲存中' : '儲存'}
          </button>
        </div>
      </fetcher.Form>
    </div>
  );
}

function FollowSocial({
  el,
  refetchMarselloData,
}: {
  el: MarselloCustomerData['earnActivities'][0];
  refetchMarselloData: () => void;
}) {
  async function goToSocialMedia() {
    const res = await fetch(el.url);
    const data = (await res.json()) as {destinationUrl: string};

    if (data.destinationUrl) {
      window.open(data.destinationUrl, '_blank');
    }

    await refetchMarselloData();
  }
  return (
    <li
      key={el.id}
      className="flex items-center justify-between border-b border-slate-300 border-opacity-50 py-4"
    >
      <span className="font-normal">{el.title}</span>

      <div className="text-xs text-slate-600 opacity-50 min-w-[60px] text-right flex items-center justify-end">
        <div className="">{el.description}</div>
        <button className="w-5 ml-1 -rotate-90" onClick={goToSocialMedia}>
          <ChevronDownIcon />
        </button>
      </div>
    </li>
  );
}

import React from 'react';
import Container from 'components/Container';
import clsx from 'clsx';
import type {Brand} from 'domains/brands';
import {Disclosure} from '@headlessui/react';
import SanityImage from 'components/SanityImage';
import {Link} from 'components/Link';
import {useLocation} from '@remix-run/react';
import {useMatches} from '@remix-run/react';

interface Props {}

const MenubarDesktop: React.FC<Props> = () => {
  const [root] = useMatches();
  const menu = root?.data?.menu as any;
  const allBrands = root?.data?.allBrands as Brand[];
  const featuredBrands = root?.data?.featuredBrands as {
    featured_brands: Brand[];
  };
  return (
    <nav
      className={clsx(
        'left-0 top-0 w-full bg-hk-deep-blue text-[18px] text-white',
        // !isHome && 'hidden md:block'
        'hidden md:block',
      )}
    >
      <Container>
        <ul className="flex justify-between space-x-4 overflow-y-scroll md:overflow-x-scroll md:overflow-y-hidden">
          {menu &&
            menu.map((item: any) => {
              const internal_link_slug =
                item.slug === '/blogs/all' ? '/blogs' : item.slug;
              return (
                <li key={item._key}>
                  <MegaMenu
                    className={clsx([
                      'group relative py-3 transition-all duration-200 ease-out hover:text-hk-orange',
                      item.title === 'Sale' && 'text-hk-red',
                      item.title === '特價貨品' && 'text-hk-red',
                    ])}
                  >
                    {(isOpened) => (
                      <>
                        {/* {item.title === 'All Brands' && allBrands && (
                          <div
                            className={clsx(
                              isOpened
                                ? 'visible opacity-100'
                                : 'opacity-0 invisible',
                            )}
                          >
                            <AllBrands
                              allBrands={allBrands}
                              featuredBrands={featuredBrands}
                            />
                          </div>
                        )} */}
                        {item._type === 'linkMegaMenu' ? (
                          <>
                            {item.link ? (
                              <Link
                                to={item.link[0].slug}
                                className="whitespace-nowrap text-sm"
                              >
                                {item.link[0].title}
                              </Link>
                            ) : (
                              <span className="whitespace-nowrap text-sm">
                                {item.title}
                              </span>
                            )}

                            <div
                              className={clsx(
                                'fixed md:translate-y-headerDesktop desktop-menubar-hidden top-0 left-0 w-full bg-white py-7 text-black shadow-md transition-all duration-200 ease-out',
                                // 'group-hover:visible group-hover:opacity-100',
                                isOpened
                                  ? 'visible opacity-100'
                                  : 'opacity-0 invisible',
                              )}
                            >
                              <Container className="relative grid grid-cols-5">
                                {item?.col_1_links && (
                                  <LinkGroup links={item.col_1_links.links} />
                                )}
                                {item?.col_2_links && (
                                  <LinkGroup links={item.col_2_links.links} />
                                )}
                                {item?.col_3_links && (
                                  <LinkGroup links={item.col_3_links.links} />
                                )}
                                {item?.col_4_links && (
                                  <LinkGroup links={item.col_4_links.links} />
                                )}
                                {item?.col_5_links && (
                                  <LinkGroup links={item.col_5_links.links} />
                                )}
                              </Container>
                            </div>
                          </>
                        ) : item._type === 'linkInternal' ? (
                          <Link
                            to={`${internal_link_slug ?? item.slug}`}
                            className="whitespace-nowrap text-sm"
                          >
                            {item.title}
                          </Link>
                        ) : item._type === 'linkMenuMultiCols' ? (
                          <>
                            {item.link ? (
                              <Link
                                to={item.link[0].slug}
                                className="whitespace-nowrap text-sm"
                              >
                                {item.link[0].title}
                              </Link>
                            ) : (
                              <span className="whitespace-nowrap text-sm">
                                {item.title}
                              </span>
                            )}

                            <div
                              className={clsx(
                                'fixed md:translate-y-headerDesktop desktop-menubar-hidden top-0 left-0 w-full bg-white py-7 text-black shadow-md transition-all duration-200 ease-out',
                                // 'group-hover:visible group-hover:opacity-100',
                                isOpened
                                  ? 'visible opacity-100'
                                  : 'opacity-0 invisible',
                              )}
                            >
                              <Container className="relative">
                                <div className="grid grid-cols-11 gap-2">
                                  {item?.columns.map((col: any) => {
                                    if (col.column) {
                                      return (
                                        <div key={col._key} className="">
                                          <LinkGroup links={col.column.link} />
                                        </div>
                                      );
                                    }
                                    return;
                                  })}
                                </div>
                              </Container>
                            </div>
                          </>
                        ) : item._type === 'linkExternal' ? (
                          <a
                            href={`${item.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whitespace-nowrap text-sm"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <a
                            href={`${item.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whitespace-nowrap text-sm"
                          >
                            {item.title}
                          </a>
                        )}
                      </>
                    )}
                  </MegaMenu>
                </li>
              );
            })}
        </ul>
      </Container>
    </nav>
  );
};

interface LinkGroupProps {
  links: any;
}

function AllBrands({
  allBrands,
  featuredBrands,
}: {
  allBrands: Brand[];
  featuredBrands: {featured_brands: Brand[]} | undefined;
}) {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabets = alpha.map((x) => String.fromCharCode(x));
  const availableAlphabets = alphabets.filter((a) =>
    allBrands
      .map((b) => b.title.replace(/\s/g, '').toUpperCase().charAt(0))
      .includes(a),
  );
  const brandsByInitials = [
    {
      initials: '#',
      brands: allBrands.filter(
        (b) =>
          !availableAlphabets.includes(
            b?.title?.replace(/\s/g, '')?.charAt(0)?.toUpperCase(),
          ),
      ),
    },
  ].concat(
    availableAlphabets.map((a) => {
      return {
        initials: a,
        brands: allBrands.filter(
          (b) =>
            b?.title?.replace(/\s/g, '')?.charAt(0)?.toLocaleLowerCase() ===
            a.toLocaleLowerCase(),
        ),
      };
    }),
  );

  return (
    //
    <div className="fixed md:translate-y-headerDesktop top-0 left-0 max-h-[50vh] w-full overflow-y-scroll bg-white py-7 text-black shadow-md transition-all duration-200 ease-out">
      <Container className="grid grid-cols-8 gap-16">
        <div className="col-span-6 grid border-collapse grid-cols-4">
          {brandsByInitials.map((b, i) => {
            return (
              <div
                key={`header-brand-${b.initials}-${i}`}
                className={clsx(
                  'min-h-[200px] border border-t-0 border-l-0 border-solid border-hk-orange py-4 px-6',
                  i === 0 && 'border-l-0',
                  i % 4 === 0 && 'border-l',
                  (i + 1) % 4 === 0 && 'border-r-0',
                  i === 3 && 'border-r-0',
                )}
              >
                <div className="mb-4 flex h-9 w-9 items-center justify-center rounded-full bg-hk-orange bg-opacity-20">
                  <div className="font-bold text-hk-orange">{b.initials}</div>
                </div>
                <div className="grid gap-3 tracking-[0.015em]">
                  {b.brands
                    .filter((_, i) => i < 3)
                    .map((brand) => (
                      <Link
                        key={`header-brand-${brand.title}`}
                        to={`/collections/vendor?q=${brand.title}`}
                        className="font-light uppercase"
                      >
                        {brand.title}
                      </Link>
                    ))}
                  {b.brands && b.brands.length > 3 && (
                    <div className="">
                      <Disclosure defaultOpen={false}>
                        {({open}) => (
                          <>
                            <Disclosure.Panel className="grid gap-3">
                              {b.brands
                                .filter((_, i) => i > 2)
                                .map((brand) => (
                                  <Link
                                    key={`header-brand-${brand.title}-2`}
                                    to={`/collections/vendor?q=${brand.title}`}
                                    className="font-light uppercase"
                                  >
                                    {brand.title}
                                  </Link>
                                ))}
                            </Disclosure.Panel>
                            <Disclosure.Button
                              className={clsx('font-light text-hk-orange')}
                            >
                              {open ? 'See less' : 'See more'}
                            </Disclosure.Button>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-span-2 flex flex-col space-y-8">
          <div className="mb-16 text-center font-bold uppercase italic text-hk-orange">
            Top Brands
          </div>
          {featuredBrands?.featured_brands &&
            featuredBrands?.featured_brands.map((b, i) => {
              if (b.brand_logo) {
                return (
                  <div key={`fb-${b.title}-${i}`} className="flex items-center">
                    <Link to={`/collections/vendor?q=${b.title}`}>
                      <SanityImage
                        layout="responsive"
                        sizes={['50vw, 100vw']}
                        src={b.brand_logo?.asset._ref}
                        className="h-full object-contain"
                      />
                    </Link>
                  </div>
                );
              }
              // if (b?.shopify_brand?.shopify_collection?.store?.imageUrl) {
              //   return (
              //     <div key={`fb-${b.title}`} className="flex items-center">
              //       <Link to={`/collections/vendor?q=${b.title}`}>
              //         {b.shopify_brand.shopify_collection.store.imageUrl && (
              //           <Image
              //             src={
              //               b.shopify_brand.shopify_collection.store.imageUrl
              //             }
              //             width={400}
              //             height={160}
              //             alt={b.title}
              //           />
              //         )}
              //       </Link>
              //     </div>
              //   );
              // }
              return null;
            })}
        </div>
      </Container>
    </div>
  );
}

const LinkGroup: React.FC<LinkGroupProps> = ({links}) => {
  const indexOfAllGroupsFirstItem =
    links &&
    links.reduce(function (a: any, e: any, i: number) {
      if (e.is_group) a.push(i);
      return a;
    }, []);

  function groupItems(arr: any) {
    const result = arr.reduce(
      (acc: any, item: any) => {
        if (item.is_group) {
          if (acc.currentGroup.length > 0) {
            acc.grouped.push(acc.currentGroup);
            acc.currentGroup = [];
          }
        }
        acc.currentGroup.push(item);
        return acc;
      },
      {grouped: [], currentGroup: []},
    );

    if (result.currentGroup.length > 0) {
      result.grouped.push(result.currentGroup);
    }

    return result.grouped;
  }

  const groupedNavItems = groupItems(links);

  return (
    <div className="flex flex-col justify-between space-y-[60px]">
      {groupedNavItems.map((groupedLinks: any) => {
        return (
          <div
            className="flex flex-col space-y-5 w-full flex-1"
            key={`${groupedLinks[0]._key}-group`}
          >
            {groupedLinks.map((l: any, index: number) => {
              if (l._type === 'linkExternal') {
                return (
                  <div
                    key={l._key}
                    className={clsx([
                      `${
                        l?.is_group ? 'font-semibold uppercase' : 'font-light'
                      }`,
                      'tracking-wide hover:underline',
                    ])}
                  >
                    <a
                      href={l.url}
                      target="_blank"
                      rel={'noopener noreferrer'}
                      className=""
                    >
                      {l.title}
                    </a>
                  </div>
                );
              }
              return (
                <div
                  key={l._key}
                  className={clsx([
                    `${l?.is_group ? 'font-semibold uppercase' : 'font-light'}`,
                    'tracking-wide hover:underline',
                  ])}
                >
                  <Link to={l.slug ? l.slug : ''}>{l.title}</Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

function MegaMenu({
  children,
  className,
}: {
  children: (isOpened: boolean) => JSX.Element;
  className: string;
}) {
  let location = useLocation();

  const [megaMenuIsOpened, setMegaMenuIsOpened] = React.useState(false);

  React.useEffect(() => {
    setMegaMenuIsOpened(false);
  }, [location.pathname]);
  return (
    <div
      className={className}
      onMouseEnter={() => setMegaMenuIsOpened(true)}
      onMouseLeave={() => setMegaMenuIsOpened(false)}
    >
      {children(megaMenuIsOpened)}
    </div>
  );
}

export default MenubarDesktop;

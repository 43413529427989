import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from '~/components';

export function AccountDetails({
  customer,
  translation,
}: {
  customer: Customer;
  translation: any;
}) {
  const {firstName, lastName, email, phone} = customer;

  return (
    <>
      <div className="grid w-full gap-4 p-4 py-6 md:gap-8 md:p-8 lg:p-12">
        <h3 className="font-bold text-lead">
          {translation.main.account_details_title ?? 'Account Details'}
        </h3>
        <div className="lg:p-8 p-6 border border-gray-200 rounded">
          <div className="flex">
            {/* <h3 className="font-bold text-base flex-1">Profile & Security</h3> */}
            <Link
              prefetch="intent"
              className="underline text-sm font-normal"
              to="/account/edit"
            >
              {translation.main.edit_label ?? 'Edit'}
            </Link>
          </div>
          <div className="mt-4 text-sm text-slate-500">
            {translation.main.name_label ?? 'Name'}
          </div>
          <p className="mt-1">
            {firstName || lastName
              ? (firstName ? firstName + ' ' : '') + lastName
              : 'Add name'}{' '}
          </p>

          <div className="mt-4 text-sm text-slate-500">
            {translation.main.contact_label ?? 'Contact'}
          </div>
          <p className="mt-1">{phone ?? 'Add mobile'}</p>

          <div className="mt-4 text-sm text-slate-500">
            {translation.edit.email_label ?? 'Email address'}
          </div>
          <p className="mt-1">{email}</p>

          <div className="mt-4 text-sm text-slate-500">
            {translation.edit.password_label ?? 'Password'}
          </div>
          <p className="mt-1">**************</p>
        </div>
      </div>
    </>
  );
}

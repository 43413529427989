import React from 'react';
import {useMatches} from '@remix-run/react';

//components
import Container from 'components/Container';
import SanityImage from 'components/SanityImage';
import PortableText from 'components/PortableText';
import {Link} from 'components/Link';

//domains
import {LINK_INTERNAL} from 'domains/global';

export default function Footer() {
  const [root] = useMatches();
  const footer = root?.data?.footer as any;

  return (
    <footer
      className="overflow-hidden bg-hk-deep-blue pb-6 pt-8 text-white"
      role="contentinfo"
      id="footer"
    >
      <Container>
        <div className="mb-10 grid gap-12 lg:grid-cols-3 lg:gap-4 lg:gap-y-12">
          {footer.footer_links &&
            footer?.footer_links?.map((l: any) => {
              if (l._type === 'custom.footerLinkGroup')
                return (
                  <div key={l._key} className="lg:col-span-1">
                    <h3 className="mb-8 font-bold uppercase tracking-wide">
                      {l.title}
                    </h3>
                    <ul className="grid gap-4">
                      {l.links &&
                        l.links?.map((link: any) => {
                          if (link._type === 'custom.linkWithIcon') {
                            return (
                              <li key={link._key} className="font-normal">
                                <a
                                  href={link.url}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="flex items-center space-x-2"
                                >
                                  <div className="w-[22px]">
                                    <SanityImage
                                      layout="responsive"
                                      sizes={['50vw, 100vw']}
                                      src={link.icon?.asset._ref}
                                    />
                                  </div>
                                  <span className="">{link?.icon_title}</span>
                                </a>
                              </li>
                            );
                          }
                          if (link._type === 'block') {
                            return (
                              <li key={link._key} className="font-normal">
                                <PortableText blocks={link} />
                              </li>
                            );
                          }
                          if (link._type === 'linkInternal') {
                            // my account
                            if (
                              link._key === 'ceb49658be2e' ||
                              link.title === 'My Account' ||
                              link.title === '我的帳戶'
                            ) {
                              return (
                                <li key={link._key} className="font-normal">
                                  <Link to={`/account`} prefetch="intent">
                                    {link.title}
                                  </Link>
                                </li>
                              );
                            }
                            const slug = generateSanityInternalPath({
                              type: link?.reference?._type,
                              slug: link?.reference?.slug?.current,
                            });
                            const internal_link_slug =
                              slug === '/blogs/all' ? '/blogs' : slug;
                            return (
                              <li key={link._key} className="font-normal">
                                <Link
                                  to={`${internal_link_slug}`}
                                  prefetch="intent"
                                >
                                  {link.title}
                                </Link>
                              </li>
                            );
                          }
                          return (
                            <li key={link._key} className="font-normal">
                              <a
                                href={link.url}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {link.title}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                );
              return;
            })}
        </div>
        <div className="mb-12 grid gap-12 lg:grid-cols-3 lg:gap-4 lg:gap-y-12">
          <div className="flex space-x-2 lg:col-span-1 lg:col-start-3">
            {footer.social_links &&
              footer.social_links.map((l: any) => {
                if (l.type === 'fb-like') {
                  return (
                    <div key={l._key} className="h-[36px]">
                      <iframe src="https://www.facebook.com/v2.5/plugins/like.php?action=like&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3b1446bedf85ac%26domain%3Dhelmetking.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fhelmetking.com%252Ff2943e5a9ec7c8%26relation%3Dparent.parent&container_width=0&href=https%3A%2F%2Fwww.facebook.com%2Fjpmotobike%2F&layout=button_count&locale=en_US&sdk=joey&show_faces=true"></iframe>
                    </div>
                  );
                }
                return (
                  <a
                    key={l._key}
                    title={l.title}
                    href={l.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="inline-block w-[36px] flex-shrink-0"
                  >
                    <div className="">
                      <SanityImage
                        layout="responsive"
                        sizes={['50vw, 100vw']}
                        src={l.icon?.asset._ref}
                      />
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
        <div className="grid gap-4 lg:grid-cols-3">
          <div className="lg:col-span-1">
            <div className="font-normal">{footer.copyright_text}</div>
          </div>
          <div className="lg:col-span-1">
            <SanityImage
              layout="responsive"
              sizes={['50vw, 100vw']}
              src={footer.payment_icon?.asset._ref}
            />
          </div>
        </div>
      </Container>
    </footer>
  );
}
export const generateSanityInternalPath = ({
  type,
  slug,
}: {
  type: string;
  slug: string;
}) => {
  if (type === 'page') {
    return `/pages/${slug}`;
  }
  if (type === 'category') {
    return `/blogs/${slug}`;
  }
  if (type === 'collection') {
    return `/collections/${slug}`;
  }
  if (type === 'product') {
    return `/products/${slug}`;
  }
  if (type === 'home') {
    return `/`;
  }
  return '/';
};

import React from 'react';
import {useFetcher, useRevalidator} from '@remix-run/react';
import {LoadingSpinner} from '../global/LoadingSpinner';
import type {Metafield} from '@shopify/hydrogen/storefront-api-types';
export default function ProductWishlistButton({
  productId,
  wishlist,
}: {
  productId: string;
  wishlist: Metafield | undefined;
}) {
  const fetcher = useFetcher();
  const revalidator = useRevalidator();
  const productIdsInWishList =
    wishlist && JSON.parse(wishlist.value)?.productIds;
  const isInWishList =
    productIdsInWishList && productIdsInWishList.includes(productId);

  const response = fetcher?.data && JSON.parse(fetcher?.data);

  return (
    <div
      className="flex-shrink-0"
      title={isInWishList ? 'Remove from wishlist' : 'Add to wishlist'}
    >
      <fetcher.Form method="post" action="/api/wishlist">
        <input type="hidden" value={productId} name="product_id" />
        <button className="p-1" type="submit">
          {fetcher.state === 'submitting' || fetcher.state === 'loading' ? (
            <div className="w-5">
              <LoadingSpinner className="text-hk-orange"/>
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 md:w-6"
              viewBox="0 0 24 24"
            >
              {response?.sucess || isInWishList ? (
                <path
                  d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"
                  className="fill-hk-orange"
                />
              ) : (
                <path
                  d="M12 9.229c.234-1.12 1.547-6.229 5.382-6.229 2.22 0 4.618 1.551 4.618 5.003 0 3.907-3.627 8.47-10 12.629-6.373-4.159-10-8.722-10-12.629 0-3.484 2.369-5.005 4.577-5.005 3.923 0 5.145 5.126 5.423 6.231zm-12-1.226c0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-7.962-9.648-9.028-12-3.737-2.338-5.262-12-4.27-12 3.737z"
                  className="fill-hk-orange"
                />
              )}
            </svg>
          )}
        </button>
      </fetcher.Form>
    </div>
  );
}

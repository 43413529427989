import React from 'react';
import type {MoneyV2} from '@shopify/hydrogen/storefront-api-types';
import {isDiscounted} from '~/lib/utils';
import clsx from 'clsx';
import {useMatches} from '@remix-run/react';

//domains
import {CurrencySettingsGlobal, formatCurrency} from 'domains/currency';
import {isTWSite} from '~/domains/sanity';

export default function ProductPrices({
  price,
  compareAtPrice,
  type = 'shopify',
}: {
  price: MoneyV2;
  compareAtPrice: MoneyV2;
  type: 'shopify' | 'bfs';
}) {
  const [root] = useMatches();
  const req_url_host = root.data.req_url_host as string;
  const currency = root.data.currency as CurrencySettingsGlobal;
  const selectedCurrency = currency.selectedCurrency;

  const selectedCurrencyDetails = currency.all_currencies.find(
    (c) => c.currency === selectedCurrency,
  );

  if (type === 'shopify') {
    console.log(price);
    console.log(selectedCurrency);
    console.log(currency.baseCurrency);
    console.log(currency.exchangeRates);
    const priceFormatted = formatCurrency({
      price,
      selectedCurrency: currency.selectedCurrency,
      exchangeRates: currency.exchangeRates,
      baseCurrency: currency.baseCurrency,
    });
    const compareAtPriceFormatted =
      isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2) &&
      formatCurrency({
        price: compareAtPrice,
        selectedCurrency: currency.selectedCurrency,
        exchangeRates: currency.exchangeRates,
        baseCurrency: currency.baseCurrency,
      });
    return (
      <div
        className={clsx(
          'flex justify-center font-bold text-xs md:text-md',
          req_url_host === 'tw.helmetking.com' && 'flex-col md:flex-row',
        )}
      >
        {compareAtPriceFormatted ? (
          <span className="mr-1 font-normal text-darkGray line-through decoration-red md:mr-3">
            {selectedCurrencyDetails?.details?.currency?.code}
            {typeof window !== 'undefined' &&
            isTWSite(window.location.href) &&
            price.currencyCode === 'HKD' &&
            selectedCurrency === 'HKD'
              ? compareAtPrice.amount
              : compareAtPriceFormatted?.toFixed(2)}
          </span>
        ) : null}
        <span className={clsx('font-normal')}>
          {selectedCurrencyDetails?.details?.currency?.code}
          {typeof window !== 'undefined' &&
          isTWSite(window.location.href) &&
          price.currencyCode === 'HKD' &&
          selectedCurrency === 'HKD'
            ? price.amount
            : priceFormatted && priceFormatted?.toFixed(2)}
        </span>
      </div>
    );
  }

  return <></>;
}

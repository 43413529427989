import React from 'react';
import clsx from 'clsx';
import {useMatches} from '@remix-run/react';

//components
import SanityRichText from '~/components/modules/SanityRichText';

//domains
import {colourToClassName} from 'domains/sanity';
import {loader} from '~/root';
import type {Announcement} from '~/domains/modules';

export default function AnnouncementBar() {
  const [root] = useMatches();
  const announcement = root?.data?.announcement as Announcement;
  const IPAddressLocation = root?.data.ipAddressLocation as string;

  if (!announcement) {
    return <></>;
  }

  if (announcement?.enabled) {
    const localMessage = announcement?.messages.find(
      (a) => a.country === IPAddressLocation,
    );
    const globalMessage = announcement?.messages.find(
      (a) => a.country === 'GLOBAL',
    );
    return (
      <div
        className={clsx(
          'relative py-1 md:py-3 px-2 text-center announcement-bar min-h-[26px] flex flex-col items-center justify-center',
          // Bg Colour
          localMessage
            ? colourToClassName(localMessage.bg_colour.title, 'bg')
            : globalMessage
            ? colourToClassName(globalMessage.bg_colour.title, 'bg')
            : colourToClassName('Orange', 'bg'),
          // Text Colour
          localMessage
            ? colourToClassName(localMessage.text_colour.title, 'text')
            : globalMessage
            ? colourToClassName(globalMessage.text_colour.title, 'text')
            : colourToClassName('Orange', 'text'),
        )}
      >
        <div className="text-xs md:text-lg">
          {localMessage ? (
            <SanityRichText
              richtext={localMessage.message}
              className="min-h-0"
            />
          ) : globalMessage ? (
            <SanityRichText
              richtext={globalMessage.message}
              className="min-h-0"
            />
          ) : (
            'OVER HKD$500 FREE SHIPPING TO HONG KONG & MACAU'
          )}
        </div>
      </div>
    );
  }

  return null;
}

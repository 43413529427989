import clsx from 'clsx';
import {useRef} from 'react';
import {useScroll} from 'react-use';
import {flattenConnection, Image, Money} from '@shopify/hydrogen';
import {
  Button,
  Heading,
  IconRemove,
  Text,
  Link,
  FeaturedProducts,
} from '~/components';
import {getInputStyleClasses} from '~/lib/utils';
import type {
  Cart as CartType,
  CartCost,
  CartLine,
  CartLineUpdateInput,
} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher} from '@remix-run/react';
import {CartAction} from '~/lib/type';
import {useMatches} from '@remix-run/react';
//domains
import {CurrencySettingsGlobal, formatCurrency} from '~/domains/currency';

//components
import {LoadingSpinner} from 'components/global/LoadingSpinner';

export default function CartLines({
  lines: cartLines,
}: {
  lines: CartType['lines'] | undefined;
}) {
  const currentLines = cartLines ? flattenConnection(cartLines) : [];
  const scrollRef = useRef(null);

  return (
    <section
      ref={scrollRef}
      aria-labelledby="cart-contents"
      className="transition last:border-b-0 lg:border-b lg:border-lightGray"
    >
      <ul className="grid gap-6 lg:gap-10">
        {currentLines.map((line) => (
          <CartLineItem key={line.id} line={line as CartLine} />
        ))}
      </ul>
    </section>
  );
}

function CartLineItem({line}: {line: CartLine}) {
  if (!line?.id) return null;

  const {id, quantity, merchandise} = line;

  if (
    typeof quantity === 'undefined' ||
    !merchandise?.product ||
    quantity === 0
  )
    return null;

  return (
    <>
      <li
        key={id}
        className="flex items-center space-x-2 md:space-x-0 py-3 last:border-b-0 lg:border-b lg:border-lightGray justify-between"
      >
        <div className="flex w-12 md:w-7/12">
          <div className="mr-3 w-[50px] h-[50px] flex-shrink-0 lg:w-[100px] lg:h-[100px]">
            {merchandise.image && (
              <Link to={`/products/${merchandise.product.handle}`}>
                <Image
                  width={100}
                  height={100}
                  data={merchandise.image}
                  className="object-contain object-center"
                  alt={merchandise.title}
                />
              </Link>
            )}
          </div>

          <div className="flex-grow-1 mr-4 hidden w-full flex-col items-start lg:flex">
            <h3 className="text-[20px] font-normal">
              {merchandise?.product?.handle ? (
                <Link to={`/products/${merchandise.product.handle}`}>
                  {merchandise?.product?.title || ''}
                </Link>
              ) : (
                <Text>{merchandise?.product?.title || ''}</Text>
              )}
            </h3>

            <div className="mt-1 space-y-1 text-xs text-darkGray">
              {(merchandise?.selectedOptions || []).map((option) => (
                <div color="subtle" key={option.name}>
                  {option.name}: {option.value}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full md:w-5/12 flex items-center">
          <div className="flex flex-shrink-0">
            <CartLineQuantityAdjust line={line} />
          </div>
          <div className="flex-1 w-full">
            <CartLinePrice line={line} as="span" />
          </div>
          <div className="flex flex-col items-end justify-between">
            <ItemRemoveButton lineIds={[id]} />
          </div>
        </div>
      </li>
      {/* Mobile */}
      <div className="flex-grow-1 mr-4 flex w-full flex-col items-start lg:hidden">
        {/* Title */}
        <h3 className="text-sm font-normal">
          {merchandise?.product?.handle ? (
            <Link to={`/products/${merchandise.product.handle}`}>
              {merchandise?.product?.title || ''}
            </Link>
          ) : (
            <span className="text-sm font-normal">
              {merchandise?.product?.title || ''}
            </span>
          )}
        </h3>

        {/* Options */}
        <div className="mt-1 space-y-1 text-xs text-darkGray">
          {(merchandise?.selectedOptions || []).map((option) => (
            <div color="subtle" key={option.name}>
              {option.name}: {option.value}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

function ItemRemoveButton({lineIds}: {lineIds: CartLine['id'][]}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.REMOVE_FROM_CART}
      />
      <input type="hidden" name="linesIds" value={JSON.stringify(lineIds)} />
      {/* <button
        className="flex items-center justify-center w-5 md:w-10 h-5 md:h-10 border rounded"
        type="submit"
      > */}
      <span className="sr-only">Remove</span>
      {fetcher.state === 'loading' ? (
        <div className="w-5">
          <LoadingSpinner />
        </div>
      ) : (
        <button type="submit">
          <IconRemove aria-hidden="true" />
        </button>
      )}
      {/* </button> */}
    </fetcher.Form>
  );
}

function CartLineQuantityAdjust({line}: {line: CartLine}) {
  if (!line || typeof line?.quantity === 'undefined') return null;
  const {id: lineId, quantity} = line;
  const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0));
  const nextQuantity = Number((quantity + 1).toFixed(0));

  return (
    <>
      <label htmlFor={`quantity-${lineId}`} className="sr-only">
        Quantity, {quantity}
      </label>
      <div className="flex items-center gap-2 rounded-sm border-[1px] py-2 px-1">
        <UpdateCartButton lines={[{id: lineId, quantity: prevQuantity}]}>
          <button
            name="decrease-quantity"
            aria-label="Decrease quantity"
            className="p-1.5 transition text-primary/50 hover:text-primary disabled:opacity-10 flex items-center justify-center"
            value={prevQuantity}
            disabled={quantity <= 1}
          >
            <svg
              className="w-4 lg:w-6"
              viewBox="0 0 24 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1348 0.946777H23.6754V3.28912H13.1348H12.6329H10.2906H10.7925H0.251953V0.946777H10.7925L11.9637 0.94743L13.1348 0.946777Z"
                fill="#585757"
              />
            </svg>
          </button>
        </UpdateCartButton>

        <div
          className="min-w-[1rem] lg:min-w-[2rem] text-center text-md font-bold leading-none text-black lg:text-[24px]"
          data-test="item-quantity"
        >
          {quantity}
        </div>

        <UpdateCartButton lines={[{id: lineId, quantity: nextQuantity}]}>
          <button
            className="p-1.5 transition text-primary/50 hover:text-primary disabled:opacity-10 flex items-center justify-center"
            name="increase-quantity"
            value={nextQuantity}
            aria-label="Increase quantity"
          >
            <svg
              className="w-4 lg:w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2344 11.1082H23.775V13.4505H13.2344V23.9911H10.8921V13.4505H0.351562V11.1082H10.8921V0.567627H13.2344V11.1082Z"
                fill="#231F20"
              />
            </svg>
          </button>
        </UpdateCartButton>
      </div>
    </>
  );
}

function UpdateCartButton({
  children,
  lines,
}: {
  children: React.ReactNode;
  lines: CartLineUpdateInput[];
}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input type="hidden" name="cartAction" value={CartAction.UPDATE_CART} />
      <input type="hidden" name="lines" value={JSON.stringify(lines)} />
      {fetcher.state === 'loading' ? (
        <div className="w-6">
          <LoadingSpinner />
        </div>
      ) : (
        children
      )}
    </fetcher.Form>
  );
}

function CartLinePrice({
  line,
  priceType = 'regular',
  ...passthroughProps
}: {
  line: CartLine;
  priceType?: 'regular' | 'compareAt';
  [key: string]: any;
}) {
  if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null;

  const moneyV2 =
    priceType === 'regular'
      ? line.cost.totalAmount
      : line.cost.compareAtAmountPerQuantity;

  if (moneyV2 == null) {
    return null;
  }

  const [root] = useMatches();
  const currency = root?.data?.currency as CurrencySettingsGlobal;
  const selectedCurrency = currency.selectedCurrency;

  const selectedCurrencyDetails = currency.all_currencies.find(
    (c) => c.currency === selectedCurrency,
  );

  const priceFormatted = formatCurrency({
    price: moneyV2,
    selectedCurrency: currency.selectedCurrency,
    exchangeRates: currency.exchangeRates,
    baseCurrency: currency.baseCurrency,
  });

  return (
    <div className="mx-2 flex-shrink-0 text-right text-md font-medium leading-none lg:ml-4 lg:mr-4 lg:min-w-[4rem] lg:text-[20px]">
      <div className="flex justify-center lg:justify-left">
        <span className={clsx('font-normal')}>
          {selectedCurrencyDetails?.details?.currency?.code}
          {priceFormatted && priceFormatted?.toFixed(2)}
        </span>
      </div>
    </div>
  );
}

import React from 'react';
import {Text} from '~/components';
import clsx from 'clsx';
import {useMatches} from '@remix-run/react';
import {I18nLocale} from '~/lib/type';

export const specialProductTags = [
  'staffpick',
  'specialoffer',
  'newarrval',
  'sale',
  'pre-order',
];

export default function ProductLabel({
  tag,
  label,
}: {
  tag: string;
  label?: string;
}) {
  const [root] = useMatches();
  const selectedLocale = root?.data?.selectedLocale as I18nLocale;
  function getLabel(tag: string) {
    switch (tag) {
      case 'staffpick':
        return selectedLocale.language === 'ZH_TW'
          ? '編輯推介'
          : 'Editor’s Pick';
      case 'specialoffer':
        return selectedLocale.language === 'ZH_TW'
          ? '特價推廣'
          : 'Special Offer';
      case 'newarrival':
        return selectedLocale.language === 'ZH_TW' ? '新到貨品' : 'New Arrival';
      case 'sale':
        return selectedLocale.language === 'ZH_TW' ? '減價產品' : 'Sale';
      case 'pre-order':
        return selectedLocale.language === 'ZH_TW' ? '預訂產品' : 'Pre-Order';
      case 'outofstock':
        return selectedLocale.language === 'ZH_TW' ? '缺貨' : 'Out of Stock';
      case 'membersoffer':
        return selectedLocale.language === 'ZH_TW'
          ? '會員優惠'
          : 'Members Offer';
    }
  }
  function getColour(tag: string) {
    switch (tag) {
      case 'staffpick':
        return 'bg-[#218E1A]';
      case 'specialoffer':
        return 'bg-[#007DFF]';
      case 'newarrival':
        return 'bg-[#273E9D]';
      case 'sale':
        return 'bg-[#E6292A]';
      case 'outofstock':
        return 'bg-[#C1C1C1]';
      case 'pre-order':
        return 'bg-[#fa4c06]';
      case 'membersoffer':
        return 'bg-[#f66357]';
      default:
        return 'bg-hk-orange';
    }
  }

  if (tag === 'instock') {
    return <></>;
  }

  return (
    <Text
      as="label"
      size="fine"
      className={clsx(
        'p-[1px] w-full text-center z-10 relative text-white block tracking-wide',
        getColour(tag),
      )}
    >
      {label ?? getLabel(tag)}
    </Text>
  );
}

import React from 'react';
import clsx from 'clsx';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMatches, Await, useFetcher} from '@remix-run/react';
import { Link } from '@remix-run/react';

//domains
import {colourToClassName} from 'domains/sanity';

//components
import SanityImage from '../SanityImage';

import SanityRichText from 'components/modules/SanityRichText';

//data
import type {EmailSignUp} from 'domains/root';

type FormValues = {
  email: string;
};

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
  })
  .required();

export default function EmailSignUp(props: any) {
  const [root] = useMatches();
  const emailSignUp = root?.data?.emailSignUp as EmailSignUp;
  const bgColour = colourToClassName(emailSignUp.bg_colour?.title, 'bg');
  const textColour = colourToClassName(emailSignUp.text_colour?.title, 'text');
  const buttonBgColour = colourToClassName(
    emailSignUp.button_bg_colour?.title,
    'bg',
  );
  const buttonTextColour = colourToClassName(
    emailSignUp.button_text_colour?.title,
    'text',
  );

  const {
    formState: {errors, isDirty, isSubmitting, isSubmitSuccessful, isSubmitted},
    register,
  } = useForm<
    FormValues & {
      serverError?: string;
    }
  >({
    defaultValues: {
      email: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const formRef = React.useRef<HTMLFormElement>(null);
  const fetcher = useFetcher();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formRef?.current) {
      const formData = new FormData(formRef.current);
      fetcher.submit(formData, {method: 'post', action: '/api/subscribe'});
    }
  };

  const response = fetcher?.data && JSON.parse(fetcher?.data);

  return (
    <section
      id="email-signup"
      className={clsx([
        'module--email-signup min-h-[350px] relative',
        'bg-white',
        textColour,
      ])}
    >
      <React.Suspense fallback="loading">
        <Await resolve={emailSignUp}>
          {(emailSignUp) => {
            return (
              <div
                className={clsx('grid grid-cols-1 lg:grid-cols-2', bgColour)}
              >
                <div className="p-4 lg:pr-0 xl:pr-0 lg:p-12 xl:p-20">
                  <SanityImage
                    // crop={image?.crop}
                    // hotspot={image?.hotspot}
                    layout="responsive"
                    sizes={['50vw, 100vw']}
                    src={emailSignUp.image?.asset._ref}
                    className="h-full w-full object-contain"
                  />
                </div>
                <div className="flex items-center p-4 lg:p-12 xl:p-20">
                  <div className="w-full">
                    <h3 className="mb-9 text-md font-medium lg:text-[20px] lg:leading-[30px]">
                      {emailSignUp.section_title}
                    </h3>
                    <Link
                    to="/account/register"
                        type="submit"
                        className={clsx([
                          buttonTextColour,
                          buttonBgColour,
                          'inline-block text-center w-full rounded-md py-5 text-md lg:text-[20px] transition-opacity duration-200 ease-out hover:opacity-75 disabled:opacity-50',
                        ])}
                      >
                        {emailSignUp.button_text}
                      </Link>
                  </div>
                </div>
                {/* <fetcher.Form
                  method="post"
                  action="/api/subscribe"
                  onSubmit={handleSubmit}
                  ref={formRef}
                >
                  <div className="flex items-center p-4 lg:p-12 xl:p-20">
                    <div className="w-full">
                      {response && response.errorMessage && (
                        <div className="mb-6 flex items-center justify-center rounded-sm border border-red p-4 text-sm text-red">
                          <p>{response.errorMessage}</p>
                        </div>
                      )}
                      {response && response.success && (
                        <div className="mb-6 text-center leading-snug rounded-sm bg-hk-orange p-4 text-sm text-white">
                          <SanityRichText
                            richtext={emailSignUp.thank_you_message}
                          />
                        </div>
                      )}
                      <h3 className="mb-9 text-md font-medium lg:text-[20px] lg:leading-[30px]">
                        {emailSignUp.section_title}
                      </h3>
                      <label
                        htmlFor="email"
                        className="mb-4 block text-md lg:text-[20px] font-semibold"
                      >
                        {emailSignUp.label}
                      </label>
                      <input
                        type="text"
                        {...register('email')}
                        placeholder="Enter Your Email"
                        disabled={response && response?.success}
                        className="mb-4 block w-full rounded-md border-2 border-current bg-transparent p-5 text-md placeholder:text-opacity-30 placeholder:opacity-30"
                      />
                      {errors.email?.message && (
                        <div className="mb-4 text-sm text-red">
                          {errors.email?.message}
                        </div>
                      )}
                      <button
                        type="submit"
                        disabled={
                          !isDirty ||
                          isSubmitting ||
                          isSubmitSuccessful ||
                          (response && response.success)
                        }
                        className={clsx([
                          buttonTextColour,
                          buttonBgColour,
                          'w-full rounded-md py-5 text-md lg:text-[20px] transition-opacity duration-200 ease-out hover:opacity-75 disabled:opacity-50',
                        ])}
                      >
                        {fetcher.state === 'submitting'
                          ? 'Subitting...'
                          : response && response?.success
                          ? 'Subscribed'
                          : emailSignUp.button_text}
                      </button>
                    </div>
                  </div>
                </fetcher.Form> */}
              </div>
            );
          }}
        </Await>
      </React.Suspense>
    </section>
  );
}

import React from 'react';
import {Link} from '../Link';
import {Popover} from '@headlessui/react';
import {Portal} from '@radix-ui/react-portal';
import SanityImage from 'components/SanityImage';
import {useMatches} from '@remix-run/react';
import {loader} from '~/root';
import clsx from 'clsx';

export default function MenuBarMobile() {
  const [root] = useMatches();
  const menu = root?.data?.menu as any;
  return (
    <div
      style={{scrollbarWidth: 'none'}}
      className="relative top-0 z-10 flex w-full space-x-4 overflow-x-scroll bg-hk-deep-blue px-2 py-2 text-sm font-normal text-white md:hidden"
    >
      {menu?.map((item: any) => {
        const firstColLink =
          item?.col_1_links?.links.find((l: any) => l.is_group) ??
          item?.col_1_links?.links[0];
        const secondColLink =
          item?.col_2_links?.links.find((l: any) => l.is_group) ??
          item?.col_2_links?.links[0];
        const thirdColLink =
          item?.col_3_links?.links.find((l: any) => l.is_group) ??
          item?.col_3_links?.links[0];
        const fourthColLink =
          item?.col_4_links?.links.find((l: any) => l.is_group) ??
          item?.col_4_links?.links[0];
        const fifthColLink =
          item?.col_5_links?.links.find((l: any) => l.is_group) ??
          item?.col_5_links?.links[0];
        return (
          <div
            key={`${item._key}-bar`}
            className={clsx(
              'whitespace-nowrap text-xs md:text-sm',
              item.title === 'Sale' && 'text-hk-red',
              item.title === '特價貨品' && 'text-hk-red',
            )}
          >
            {item._type === 'linkInternal' && (
              <Link to={item.slug} className="text-sm whitespace-nowrap">
                {item.title}
              </Link>
            )}
            {item._type === 'linkMegaMenu' && (
              <Popover>
                {({open}) => (
                  <>
                    <Popover.Button>
                      <div className="flex items-center space-x-1">
                        {item.link ? (
                          <Link
                            to={item.link[0].slug}
                            className="whitespace-nowrap text-sm"
                          >
                            {item.link[0].title}
                          </Link>
                        ) : (
                          <span className="whitespace-nowrap text-sm">
                            {item.title}
                          </span>
                        )}
                        <div className="">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${open && 'rotate-180'}`}
                          >
                            <path
                              d="M9.75 4.5L6 8.25L2.25 4.5"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </Popover.Button>
                    <Portal>
                      <Popover.Panel>
                        <div
                          style={{scrollbarWidth: 'none'}}
                          className="fixed top-0 left-0 z-[1000] flex w-full translate-y-[79px] overflow-x-scroll bg-white px-2 min-h-[52px] py-3 text-black mobile-hidden-menu-bar"
                        >
                          {firstColLink &&
                            firstColLink._type === 'linkInternal' && (
                              <Link
                                to={firstColLink?.slug}
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {firstColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={firstColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {firstColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </Link>
                            )}
                          {firstColLink &&
                            firstColLink._type === 'linkExternal' && (
                              <a
                                href={firstColLink?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {firstColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={firstColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {firstColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </a>
                            )}
                          {secondColLink &&
                            secondColLink._type === 'linkInternal' && (
                              <Link
                                to={secondColLink?.slug}
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {secondColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={secondColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {secondColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </Link>
                            )}
                          {secondColLink &&
                            secondColLink._type === 'linkExternal' && (
                              <a
                                href={secondColLink?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {secondColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={secondColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {secondColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </a>
                            )}
                          {thirdColLink &&
                            thirdColLink._type === 'linkInternal' && (
                              <Link
                                to={thirdColLink?.slug}
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {thirdColLink?.icon && (
                                      <div className="w-8  min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={thirdColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {thirdColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </Link>
                            )}
                          {thirdColLink &&
                            thirdColLink._type === 'linkExternal' && (
                              <a
                                href={thirdColLink?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {thirdColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={thirdColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {thirdColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </a>
                            )}
                          {fourthColLink &&
                            fourthColLink._type === 'linkInternal' && (
                              <Link
                                to={fourthColLink?.slug}
                                className="flex flex-col"
                              >
                                <div className="relative flex flex-col items-center text-center text-xs">
                                  {fourthColLink?.icon && (
                                    <div className="w-8 min-h-[32px] mb-2">
                                      <SanityImage
                                        layout="responsive"
                                        sizes={['50vw, 100vw']}
                                        src={fourthColLink?.icon?.asset._ref}
                                      />
                                    </div>
                                  )}
                                  <span className="w-[75px]">
                                    {fourthColLink?.title}
                                  </span>
                                </div>
                              </Link>
                            )}
                          {fourthColLink &&
                            fourthColLink._type === 'linkExternal' && (
                              <a
                                href={fourthColLink?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {fourthColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={fourthColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {fourthColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </a>
                            )}
                          {fifthColLink &&
                            fifthColLink._type === 'linkInternal' && (
                              <Link
                                to={fifthColLink?.slug}
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {fifthColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={fifthColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {fifthColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </Link>
                            )}
                          {fifthColLink &&
                            fifthColLink._type === 'linkExternal' && (
                              <a
                                href={fifthColLink?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col"
                              >
                                <Popover.Button>
                                  <div className="relative flex flex-col items-center text-center text-xs">
                                    {fifthColLink?.icon && (
                                      <div className="w-8 min-h-[32px] mb-2">
                                        <SanityImage
                                          layout="responsive"
                                          sizes={['50vw, 100vw']}
                                          src={fifthColLink?.icon?.asset._ref}
                                        />
                                      </div>
                                    )}
                                    <span className="w-[75px]">
                                      {fifthColLink?.title}
                                    </span>
                                  </div>
                                </Popover.Button>
                              </a>
                            )}
                        </div>
                        <Popover.Button>
                          <div className="fixed top-0 left-0 h-screen w-full bg-black bg-opacity-20"></div>
                        </Popover.Button>
                      </Popover.Panel>
                    </Portal>
                  </>
                )}
              </Popover>
            )}
            {item._type === 'linkMenuMultiCols' && (
              <Popover>
                {({open}) => (
                  <>
                    <Popover.Button>
                      <div className="flex items-center space-x-1">
                        {item.link ? (
                          <Link
                            to={item.link[0].slug}
                            className="whitespace-nowrap text-sm"
                          >
                            {item.link[0].title}
                          </Link>
                        ) : (
                          <span className="whitespace-nowrap text-sm">
                            {item.title}
                          </span>
                        )}
                        <div className="">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${open && 'rotate-180'}`}
                          >
                            <path
                              d="M9.75 4.5L6 8.25L2.25 4.5"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </Popover.Button>
                    <Portal>
                      <Popover.Panel>
                        <div
                          style={{scrollbarWidth: 'none'}}
                          className="fixed top-0 left-0 z-[1000] flex w-full translate-y-[79px] overflow-x-scroll bg-white px-2 min-h-[52px] py-3 text-black mobile-hidden-menu-bar"
                        >
                          {item?.columns?.map((col: any) => {
                            if (col.column) {
                              const itemToDisplay =
                                col.column.link.find((l: any) => l.is_group) ??
                                col.column.link[0];
                              if (itemToDisplay) {
                                if (itemToDisplay._type === 'linkInternal') {
                                  return (
                                    <Link
                                      key={col._key}
                                      to={itemToDisplay?.slug}
                                    >
                                      <Popover.Button>
                                        <div className="relative flex flex-col items-center space-y-2 text-center text-xs">
                                          {itemToDisplay?.icon && (
                                            <div className="w-8 min-h-[32px]">
                                              <SanityImage
                                                layout="responsive"
                                                sizes={['50vw, 100vw']}
                                                src={
                                                  itemToDisplay?.icon?.asset
                                                    ._ref
                                                }
                                              />
                                            </div>
                                          )}
                                          <span className="w-[75px]">
                                            {itemToDisplay?.title}
                                          </span>
                                        </div>
                                      </Popover.Button>
                                    </Link>
                                  );
                                }
                                return (
                                  <a
                                    key={col._key}
                                    href={itemToDisplay.url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="flex flex-col"
                                  >
                                    <Popover.Button>
                                      <div className="relative flex flex-col items-center text-center text-xs">
                                        {itemToDisplay?.icon && (
                                          <div className="w-8 min-h-[32px] mb-2">
                                            <SanityImage
                                              layout="responsive"
                                              sizes={['50vw, 100vw']}
                                              src={
                                                itemToDisplay?.icon?.asset._ref
                                              }
                                            />
                                          </div>
                                        )}
                                        <span className="w-[75px]">
                                          {itemToDisplay?.title}
                                        </span>
                                      </div>
                                    </Popover.Button>
                                  </a>
                                );
                              }

                              return null;
                            }
                            return null;
                          })}
                        </div>
                        <Popover.Button>
                          <div className="fixed top-0 left-0 h-screen w-full bg-black bg-opacity-20"></div>
                        </Popover.Button>
                      </Popover.Panel>
                    </Portal>
                  </>
                )}
              </Popover>
            )}
            {item._type === 'linkExternal' && (
              <a
                href={`${item.url}`}
                target="_blank"
                rel="noreferrer noopener"
                className="text-sm whitespace-nowrap"
              >
                {item.title}
              </a>
            )}
          </div>
        );
      })}
    </div>
  );
}

import clsx from 'clsx';
import {useRef} from 'react';
import {useScroll} from 'react-use';
import {flattenConnection, Image, Money} from '@shopify/hydrogen';
import {
  Button,
  Heading,
  IconRemove,
  Text,
  Link,
  FeaturedProducts,
} from '~/components';
import {getInputStyleClasses} from '~/lib/utils';
import type {
  Cart as CartType,
  CartCost,
  CartLine,
  CartLineUpdateInput,
  Product,
} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher, useMatches} from '@remix-run/react';
import {CartAction} from '~/lib/type';
import React from 'react';
import * as R from 'ramda';

//components
import CartEmpty from './CartEmpty';
import CartLines from './CartLines';
import CartRecommendations from './CartRecommendations';
import CartFBT from './CartFBT';
import CartSummary from './CartSummary';
import CartDiscounts from './CartDiscounts';

import {useCartFetchers} from '~/hooks/useCartFetchers';
import {LoadingSpinner} from '../LoadingSpinner';

type Layouts = 'page' | 'drawer';

export default function Cart({
  layout,
  onClose,
  cart,
}: {
  layout: Layouts;
  onClose?: () => void;
  cart: CartType | null;
}) {
  const linesCount = Boolean(cart?.lines?.edges?.length || 0);
  const cartHasItems = !!cart && cart.totalQuantity > 0;
  const {load, data} = useFetcher();
  const cartLines = cart?.lines ? flattenConnection(cart?.lines) : [];
  const firstLineId =
    cartLines && !R.isEmpty(cartLines) && cartLines[0].merchandise.product.id;

  React.useEffect(() => {
    if (firstLineId) {
      load(`/api/product-recommendations?id=${firstLineId}`);
    }
  }, [load, firstLineId, cart?.lines]);

  const recommendedProducts = data?.products.filter(
    (p: Product, i: number) => i !== 0,
  );
  const fbtProduct = data?.products[0];

  const [root] = useMatches();
  const translation = root?.data?.translation;

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  if (!R.isEmpty(addToCartFetchers)) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-20">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  //TODO: handle delete discount code

  return (
    <>
      {firstLineId && (
        <header className="mb-6 lg:mb-[50px]">
          <div className="w-10/12 text-md font-normal lg:w-full lg:text-[20px]">
            {translation?.cart?.cart_description ??
              'The following items has been added to your cart'}
            :
          </div>
        </header>
      )}
      {!firstLineId && (
        <CartEmpty hidden={linesCount} onClose={onClose} layout={layout} />
      )}
      <div className="grid gap-10 sm:gap-20 md:grid-cols-3">
        <div className="md:col-span-2">
          <CartLines lines={cart?.lines} />
          <div className="mt-4 md:hidden">
            {cartHasItems && (
              <>
                <CartSummary cost={cart.cost} cart={cart}>
                  {/* <CartDiscounts discountCodes={cart.discountCodes} /> */}
                </CartSummary>
                <CartCheckoutActions
                  checkoutUrl={cart.checkoutUrl}
                  onClose={onClose}
                />
              </>
            )}
          </div>
          {fbtProduct && <CartFBT product={fbtProduct} />}
          <div className="md:hidden">
            {recommendedProducts && (
              <CartRecommendations products={recommendedProducts} />
            )}
          </div>
        </div>
        <div className="hidden md:col-span-1 md:block">
          {cartHasItems && (
            <>
              <CartSummary cost={cart.cost} cart={cart}>
                {/* <CartDiscounts discountCodes={cart.discountCodes} /> */}
              </CartSummary>
              <CartCheckoutActions
                checkoutUrl={cart.checkoutUrl}
                onClose={onClose}
              />
            </>
          )}
        </div>
      </div>
      {/* Recommendations */}
      <div className="hidden md:block">
        {recommendedProducts && (
          <CartRecommendations products={recommendedProducts} />
        )}
      </div>
    </>
  );
}

function CartCheckoutActions({
  checkoutUrl,
  onClose,
}: {
  checkoutUrl: string;
  onClose?: () => void;
}) {
  if (!checkoutUrl) return null;
  const [root] = useMatches();
  const translation = root?.data?.translation;

  return (
    <div className="grid w-full gap-2">
      <button
        className="disabled:opacty-20 w-full rounded-sm bg-black py-2 text-md text-white transition-all duration-200 ease-out hover:bg-hk-orange hover:text-black"
        onClick={onClose}
      >
        {translation?.cart?.continue_shopping_label ?? 'Continue Shopping'}
      </button>
      <a href={checkoutUrl} target="_self">
        <span
          className={clsx([
            'disabled:opacty-20 text-center w-full block rounded-sm bg-hk-orange py-2 text-md text-black transition-all duration-200 ease-out hover:bg-black hover:text-white',
          ])}
        >
          {translation?.cart?.proceed_to_checkout_label ??
            'Proceed To Checkout'}
        </span>
      </a>
      {/* @todo: <CartShopPayButton cart={cart} /> */}
    </div>
  );
}

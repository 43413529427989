import {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {Heading, IconClose} from '~/components';
import clsx from 'clsx';
import Container from 'components/Container';
/**
 * Drawer component that opens on user click.
 * @param heading - string. Shown at the top of the drawer.
 * @param open - boolean state. if true opens the drawer.
 * @param onClose - function should set the open state.
 * @param openFrom - right, left
 * @param children - react children node.
 */
export default function CartContainer({
  heading,
  open,
  onClose,
  openFrom = 'right',
  children,
}: {
  heading?: string;
  open: boolean;
  onClose: () => void;
  openFrom: 'right' | 'left';
  children: React.ReactNode;
}) {
  const offScreen = {
    right: 'translate-x-full',
    left: '-translate-x-full',
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 left-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          className={`fixed flex max-w-full w-screen left-1/2 top-1/2 -translate-y-1/2 lg:w-screen h-screen z-[100] -translate-x-1/2 max-h-[90vh] overflow-hidden p-4 lg:p-0 lg:max-h-[70vh] text-black`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0 left-0"
            enterTo="opacity-100"
            leave="ease-in duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // enter="transform transition ease-out duration-500"
            // enterFrom={'translate-x-full'}
            // enterTo="translate-x-0"
            // leave="transform transition ease-out duration-500"
            // leaveFrom="translate-x-0"
            // leaveTo={'translate-x-full'}
          >
            <Dialog.Panel
              className={clsx(
                'h-full w-full flex-col items-center justify-center lg:left-auto lg:bottom-auto',
                'lg:rounded-l-xl',
                'bg-white lg:bg-transparent'
              )}
            >
              <Container className="relative h-full flex items-center justify-center !px-0 !md:px-10 !xl:px-[5%] !2xl:px-[7.5%] max-w-7xl">
                <div className="absolute top-0 right-0 translate-y-2 -translate-x-2">
                  <button type="button" onClick={onClose}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="py-4 px-5 lg:p-8 overflow-x-hidden bg-white w-full h-full overflow-y-scroll flex items-center justify-center">
                  <div className="h-full w-full">{children}</div>
                </div>
              </Container>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

/* Use for associating arialabelledby with the title*/
CartContainer.Title = Dialog.Title;

export function useDrawer(openDefault = false) {
  const [isOpen, setIsOpen] = useState(openDefault);

  function openDrawer() {
    setIsOpen(true);
  }

  function closeDrawer() {
    setIsOpen(false);
  }

  return {
    isOpen,
    openDrawer,
    closeDrawer,
  };
}

function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2803 4.71967C19.5732 5.01256 19.5732 5.48744 19.2803 5.78033L5.78033 19.2803C5.48744 19.5732 5.01256 19.5732 4.71967 19.2803C4.42678 18.9874 4.42678 18.5126 4.71967 18.2197L18.2197 4.71967C18.5126 4.42678 18.9874 4.42678 19.2803 4.71967Z"
        fill="#2B2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.71967 4.71967C5.01256 4.42678 5.48744 4.42678 5.78033 4.71967L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967Z"
        fill="#2B2E2E"
      />
    </svg>
  );
}

import type {ProductVariant} from '@shopify/hydrogen/storefront-api-types';
import React from 'react';
import * as R from 'ramda';
import {Image} from '@shopify/hydrogen';
import clsx from 'clsx';
// import {getImageLoadingPriority} from '~/lib/const';

interface Props {
  options: {colour: string; thumbnail?: ProductVariant['image']}[] | undefined;
  selectedVariant: ProductVariant;
  allVariants: ProductVariant[];
  productGid: string;
  setSelectedVariant: React.Dispatch<React.SetStateAction<ProductVariant>>;
}

export default function ProductColourOptions({
  options,
  selectedVariant,
  allVariants,
  productGid,
  setSelectedVariant,
}: Props) {
  if (options) {
    const availableVariants = allVariants.filter((v) => v.availableForSale);
    const availableColours = R.uniq(
      availableVariants?.map(
        (v) => v.selectedOptions?.find((o) => o.name === 'Colour' || o.name === 'Color')?.value,
      ),
    );
    return (
      <fieldset name="product-colour-variant">
        <ul className="grid grid-cols-5 gap-2">
          {options.map((option, i) => {
            const colourIsAvailable = availableColours?.includes(option.colour);
            const selectedVariantColour = selectedVariant.selectedOptions.find(
              (o) => o?.name === 'Colour' || o?.name === 'Color',
            )?.value;

            const availableVariantOfThisColour = availableVariants?.find(
              (v) =>
                v.selectedOptions.find((o) => o?.name === 'Colour' || o?.name === 'Color')?.value ===
                option.colour,
            );

            const checked = selectedVariantColour === option.colour;
            return (
              <li
                key={`${productGid}-${option.colour}`}
                className={clsx(
                  'product-variant group list-none',
                  !colourIsAvailable && 'pointer-events-none opacity-50',
                )}
              >
                <div
                  className="product-variant__image aspect-w-16 aspect-h-16 overflow-hidden"
                  title={option.colour}
                >
                  <div className="transition-transform duration-200 ease-out">
                    <button
                      onClick={() =>
                        availableVariantOfThisColour &&
                        setSelectedVariant(availableVariantOfThisColour)
                      }
                    >
                      {option.thumbnail && (
                        <Image
                          className={clsx(
                            'rounded-full border-2 object-contain',
                            checked
                              ? 'border-black'
                              : 'border-slate-300 border-opacity-50',
                          )}
                          sizes="100px"
                          width={100}
                          height={100}
                          data={option.thumbnail}
                          alt={
                            option.thumbnail.altText ||
                            `Picture of option ${option.colour}`
                          }
                          loading="lazy"
                          //   loading={getImageLoadingPriority(i)}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </fieldset>
    );
  }

  return <></>;
}

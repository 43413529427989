import React from 'react';
import clsx from 'clsx';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Portal from '@radix-ui/react-portal';
import {useRevalidator, useMatches, useFetcher} from '@remix-run/react';

//data
import {currencyDetails} from '~/data/currencies';
import {loader} from '~/root';
import type {CurrencySettingsGlobal} from '~/domains/currency';

export default function CurrencySelector() {
  const [root] = useMatches();
  const currencySettings = root?.data?.currency as CurrencySettingsGlobal;
  const revalidator = useRevalidator();
  const refetchRootData = () => revalidator.revalidate();
  const selectedCurrency = currencySettings.selectedCurrency;
  const selectedCurrencyDetails = currencyDetails.find(
    (d) => d.currency_code === selectedCurrency,
  );
  const [isOpened, setIsOpened] = React.useState(false);
  const closeDropdown = () => setIsOpened(() => false);
  return (
    <DropdownMenu.Root
      open={isOpened}
      onOpenChange={(isOpened) => setIsOpened(isOpened)}
    >
      <DropdownMenu.Trigger asChild>
        <button
          className={clsx(
            'flex h-[2.4rem] items-center rounded-sm bg-hk-orange px-3 py-2 text-sm duration-150 md:bg-darkGray md:bg-opacity-0',
            'hover:bg-opacity-10',
          )}
        >
          <span className="mr-2">{selectedCurrencyDetails?.currency_code}</span>
          <ChevronDownIcon className={clsx(isOpened && 'rotate-180')} />
        </button>
      </DropdownMenu.Trigger>
      <Portal.Root>
        <DropdownMenu.Content className="z-[100] relative bg-white text-black rounded shadow overflow-hidden will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade">
          <div className="flex flex-col">
            {currencySettings.all_currencies.map((c) => {
              if (c && c.details) {
                return (
                  <Currency
                    key={c._key}
                    label={c.details.currency_code}
                    isSelected={c.details.currency_code === selectedCurrency}
                    closeDropdown={closeDropdown}
                    refetchRootData={refetchRootData}
                  />
                );
              }
              return <></>;
            })}
          </div>
        </DropdownMenu.Content>
      </Portal.Root>
    </DropdownMenu.Root>
  );
}

function Currency({
  isSelected,
  label,
  closeDropdown,
  refetchRootData,
}: {
  isSelected: boolean;
  label: string;
  closeDropdown: () => void;
  refetchRootData: () => void;
}) {
  const fetcher = useFetcher();

  React.useEffect(() => {
    if (!fetcher.data) return;
    if (fetcher.data.setCurrencySuccess) {
      refetchRootData();
      closeDropdown();
    }
  }, [fetcher.data]);
  return (
    <fetcher.Form method="post" action="/api/set-currency">
      <input hidden name="requestedCurrency" value={label} readOnly />
      <button className="w-full" type="submit">
        <div
          className={clsx([
            'p-3 flex justify-between items-center text-left font-bold text-sm cursor-pointer whitespace-nowrap',
            'hover:bg-slate-500 hover:bg-opacity-20',
            isSelected ? 'bg-darkGray bg-opacity-5' : null,
          ])}
        >
          <span className="mr-8 font-normal">{label}</span>
          <div className="text-hk-orange">
            <RadioIcon
              checked={isSelected}
              //   hovered={active}
            />
          </div>
        </div>
      </button>
    </fetcher.Form>
  );
}

export function ChevronDownIcon(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.5L6 8.25L2.25 4.5"
        stroke="#3A3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RadioIcon({
  checked,
  hovered,
}: {
  checked?: boolean;
  hovered?: boolean;
}) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {checked ? (
        <>
          <path
            d="M0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5Z"
            fill="currentColor"
          />
          <path
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            fill="white"
          />
          <path
            className={hovered ? 'opacity-100' : 'opacity-0'}
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="#E7E7E7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            stroke="#E7E7E7"
          />
        </>
      )}
    </svg>
  );
}

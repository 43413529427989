import React from 'react';
import SanityImage from 'components/SanityImage';
import type {SidebarItem} from 'domains/global';
import {useMatches} from '@remix-run/react';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {QueryClient, QueryClientProvider, useQuery} from 'react-query';

//components
import MarselloModal from './MarselloModal';

const queryClient = new QueryClient();

export default function Sidebar() {
  const [root] = useMatches();
  const sidebarItems = root.data.sidebar as SidebarItem[];
  const isHyrated = useIsHydrated();

  return (
    <div className="fixed right-0 top-auto bottom-0 md:bottom-auto md:top-1/2 z-10 -translate-y-3/4 lg:-translate-y-1/2 -translate-x-4 md:-translate-x-6">
      <div className="grid w-8 grid-cols-1 gap-3 md:w-[50px] md:gap-5">
        {sidebarItems &&
          sidebarItems.map((item) => {
            if (item.type === 'back-to-top') {
              return (
                <button
                  key={item._key}
                  title={item.title}
                  className=""
                  onClick={() => {
                    typeof window !== 'undefined' &&
                      window?.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                  }}
                >
                  <SanityImage
                    layout="responsive"
                    sizes={['50vw, 100vw']}
                    src={item.icon?.asset._ref}
                    className="h-full object-cover"
                  />
                </button>
              );
            }
            const whatsappMessage =
              item.type === 'whatsapp' &&
              `?text=我正瀏覽這裏: ${
                typeof window !== 'undefined' && window.location.href
              }`;
            return (
              <a
                key={item._key}
                href={`${item.url}${
                  item.type === 'whatsapp' ? whatsappMessage : ''
                }`}
                target="_blank"
                title={item.title}
                rel="noreferrer noopener"
                className=""
              >
                <SanityImage
                  layout="responsive"
                  sizes={['50vw, 100vw']}
                  src={item.icon?.asset._ref}
                  className="h-full object-cover"
                />
              </a>
            );
          })}
        {isHyrated && (
          <QueryClientProvider client={queryClient}>
            <MarselloModal />
          </QueryClientProvider>
        )}
      </div>
    </div>
  );
}

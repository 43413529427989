import {useFetcher, useLocation, useMatches} from '@remix-run/react';
import {useCallback, useRef} from 'react';
import {Locale, CartAction} from '~/lib/type';
import {DEFAULT_LOCALE} from '~/lib/utils';
import clsx from 'clsx';
import {CartBuyerIdentityInput} from '@shopify/hydrogen/storefront-api-types';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Portal from '@radix-ui/react-portal';

export default function LanguageSelector() {
  const [root] = useMatches();
  const closeRef = useRef<HTMLDivElement>(null);
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const {pathname, search} = useLocation();
  const pathWithoutLocale = `${pathname.replace(
    selectedLocale.pathPrefix,
    '',
  )}${search}`;

  const defaultLocalePrefix = 'ZH_TW';

  const closeDropdown = useCallback(() => {
    closeRef.current?.removeAttribute('open');
  }, []);

  const selectedLanguageLabel =
    selectedLocale.language === 'ZH_TW' ? '中文' : 'English';

  return (
    <DropdownMenu.Root>
      <div onMouseLeave={closeDropdown} ref={closeRef}>
        <DropdownMenu.Trigger asChild>
          <button
            className={clsx(
              'flex h-[2.4rem] items-center rounded-sm bg-hk-orange px-3 py-2 text-sm font-bold duration-150 md:bg-darkGray md:bg-opacity-0',
              'hover:bg-opacity-10',
            )}
          >
            <span className="mr-2">{selectedLanguageLabel}</span>
            <ChevronDownIcon
              className={clsx(
                closeRef.current?.getAttribute('open') && 'rotate-180',
              )}
            />
          </button>
        </DropdownMenu.Trigger>
        <Portal.Root>
          <DropdownMenu.Content className="z-[100] relative bg-white text-black rounded shadow overflow-hidden will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade">
            <div className="flex flex-col">
              <Country
                closeDropdown={closeDropdown}
                countryUrlPath={getCountryUrlPath({
                  countryLocale: {
                    country: 'HK',
                    currency: 'HKD',
                    label: 'Hong Kong (HKD $)',
                    language: 'ZH_TW',
                  },
                  defaultLocalePrefix,
                  pathWithoutLocale,
                })}
                isSelected={selectedLocale.language === 'ZH_TW'}
                countryLocale={{
                  country: 'HK',
                  currency: 'HKD',
                  label: 'Hong Kong (HKD $)',
                  language: 'ZH_TW',
                }}
                languageLabel={'中文'}
              />
              <Country
                closeDropdown={closeDropdown}
                countryUrlPath={getCountryUrlPath({
                  countryLocale: {
                    country: 'HK',
                    currency: 'HKD',
                    label: 'Hong Kong (HKD $)',
                    language: 'EN',
                  },
                  defaultLocalePrefix,
                  pathWithoutLocale,
                })}
                isSelected={selectedLocale.language === 'EN'}
                countryLocale={{
                  country: 'HK',
                  currency: 'HKD',
                  label: 'Hong Kong (HKD $)',
                  language: 'EN',
                }}
                languageLabel={'English'}
              />
            </div>
          </DropdownMenu.Content>
        </Portal.Root>
      </div>
    </DropdownMenu.Root>
  );
}

function Country({
  closeDropdown,
  countryLocale,
  countryUrlPath,
  isSelected,
  languageLabel,
}: {
  closeDropdown: () => void;
  countryLocale: Locale;
  countryUrlPath: string;
  isSelected: boolean;
  languageLabel: string;
}) {
  return (
    <ChangeLocaleForm
      key={countryLocale.country}
      redirectTo={countryUrlPath}
      buyerIdentity={{
        countryCode: countryLocale.country,
      }}
    >
      <button className="w-full" type="submit" onClick={closeDropdown}>
        <div
          className={clsx([
            'p-3 flex justify-between items-center text-left font-bold text-sm cursor-pointer whitespace-nowrap',
            'hover:bg-slate-500 hover:bg-opacity-20',
            isSelected ? 'bg-darkGray bg-opacity-5' : null,
          ])}
        >
          <span className="mr-8 font-normal">{languageLabel}</span>
          <div className="text-hk-orange">
            <RadioIcon
              checked={isSelected}
              //   hovered={active}
            />
          </div>
        </div>
      </button>
    </ChangeLocaleForm>
  );
}

function ChangeLocaleForm({
  children,
  buyerIdentity,
  redirectTo,
}: {
  children: React.ReactNode;
  buyerIdentity: CartBuyerIdentityInput;
  redirectTo: string;
}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.UPDATE_BUYER_IDENTITY}
      />
      <input
        type="hidden"
        name="buyerIdentity"
        value={JSON.stringify(buyerIdentity)}
      />
      <input type="hidden" name="redirectTo" value={redirectTo} />
      {children}
    </fetcher.Form>
  );
}

function getCountryUrlPath({
  countryLocale,
  defaultLocalePrefix,
  pathWithoutLocale,
}: {
  countryLocale: Locale;
  pathWithoutLocale: string;
  defaultLocalePrefix: string;
}) {
  let countryPrefixPath = '';
  const countryLocalePrefix = `${countryLocale.language}`;

  if (countryLocalePrefix !== defaultLocalePrefix) {
    countryPrefixPath = `/${countryLocalePrefix.toLowerCase()}`;
  }
  return `${countryPrefixPath}${pathWithoutLocale}`;
}

export function ChevronDownIcon(props: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.5L6 8.25L2.25 4.5"
        stroke="#3A3E3E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RadioIcon({
  checked,
  hovered,
}: {
  checked?: boolean;
  hovered?: boolean;
}) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {checked ? (
        <>
          <path
            d="M0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5Z"
            fill="currentColor"
          />
          <path
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            fill="white"
          />
          <path
            className={hovered ? 'opacity-100' : 'opacity-0'}
            d="M15.6562 7.21875L9.09375 13.7812L5.8125 10.5"
            stroke="#E7E7E7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5C16.0228 0.5 20.5 4.97715 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5Z"
            stroke="#E7E7E7"
          />
        </>
      )}
    </svg>
  );
}
